import moment from 'moment';

export default (filters = {}) =>
  Object.keys(filters).reduce((acc, filterId) => {
    switch (filters[filterId].type) {
      case 'mask': {
        const values = [];

        if (filters[filterId].values.filterConfig === 'empty') {
          values.push('empty');
        }
        if (filters[filterId].values.filterConfig === 'notempty') {
          values.push('notempty');
        }

        // если (фильтр без поля empty или маска включена) и есть значение
        if (
          (filters[filterId].isWithoutEmpty
            || filters[filterId].values.isMask)
          && filters[filterId].values.value
        ) {
          if (filters[filterId].values.value.search(/[*_%]/g) < 0) {
            values.push(`*${filters[filterId].values.value}*`);
          } else {
            values.push(filters[filterId].values.value);
          }
        }

        if (values.length) {
          acc[`${filterId}[of]`] = values.toString();
        }
        break;
      }
      case 'list': {
        if (filters[filterId].values.filterConfig === 'empty') {
          acc[`${filters[filterId]?.field || filterId}[of]`] = 'empty';
        } else if (filters[filterId].values.filterConfig === 'notempty') {
          acc[`${filters[filterId]?.field || filterId}[of]`] = 'notempty';
        } else {
          const values = Object.keys(filters[filterId].values).reduce(
            (accJ, key) => {
              if (key !== 'filterConfig') {
                if (filters[filterId].values[key]) {
                  accJ.push(key);
                }
              }
              return accJ;
            },
            [],
          );

          if (values.length) {
            // acc[`${filterId}[${filters[filterId].equal ? 'eq' : 'of'}]`] = values.toString();
            // NOTE: костыль для кейса, когда в одной колонке нужно делать фильтарцию по одному полю, а сортировку - другому
            // в конфиг колонки передается доп поле filterKey, которое позже прокидывается в компоненте AgColumnHeader,
            // а затем попадает сюда
            acc[`${filters[filterId]?.field || filterId}[${filters[filterId].equal ? 'eq' : 'of'}]`] = values.toString();
          }
        }
        break;
      }
      case 'boolean-list': {
        if (filters[filterId].values.filterConfig === 'empty') {
          acc[`${filterId}[of]`] = 'empty';
        } else if (filters[filterId].values.filterConfig === 'notempty') {
          acc[`${filterId}[of]`] = 'notempty';
        } else {
          const values = Object.keys(filters[filterId].values).reduce(
            (accJ, key) => {
              if (filters[filterId].values[key]) {
                accJ.push(!Number(key));
              }

              return accJ;
            },
            [],
          );

          acc[`${filterId}[eq]`] = values.pop();
        }
        break;
      }
      case 'empty-list': {
        if (filters[filterId].values.filterConfig === 'empty') {
          acc[`${filterId}[of]`] = 'empty';
        } else if (filters[filterId].values.filterConfig === 'notempty') {
          acc[`${filterId}[of]`] = 'notempty';
        } else {
          const values = Object.keys(filters[filterId].values).reduce(
            (accJ, key) => {
              if (filters[filterId].values[key]) {
                accJ.push(key === 0 ? 'empty' : 'notempty');
              }

              return accJ;
            },
            [],
          );

          acc[`${filterId}[of]`] = values.pop();
        }
        break;
      }
      case 'number': {
        const values = [];

        if (filters[filterId].values.filterConfig === 'empty') {
          values.push('empty');
        }
        if (filters[filterId].values.filterConfig === 'notempty') {
          values.push('notempty');
        }

        if (filters[filterId].values.range) {
          if (filters[filterId].values.exclude) {
            if (filters[filterId].values.toValue && filters[filterId].values.fromValue) {
              acc[`${filterId}[exrange]`] = `${filters[filterId].values.fromValue},${filters[filterId].values.toValue}`;
            } else if (filters[filterId].values.fromValue) {
              acc[`${filterId}[neq]`] = filters[filterId].values.fromValue;
            } else if (filters[filterId].values.toValue) {
              acc[`${filterId}[neq]`] = filters[filterId].values.toValue;
            }
          } else {
            if (filters[filterId].values.fromValue) {
              acc[`${filterId}[gte]`] = filters[filterId].values.fromValue;
            }
            if (filters[filterId].values.toValue) {
              acc[`${filterId}[lte]`] = filters[filterId].values.toValue;
            }
          }
        } else if (filters[filterId].values.fromValue) {
          if (filters[filterId].values.exclude) {
            acc[`${filterId}[neq]`] = filters[filterId].values.fromValue;
          } else {
            values.push(filters[filterId].values.fromValue);
          }
        }

        if (values.length) {
          acc[`${filterId}[of]`] = values.toString();
        }

        break;
      }
      case 'date': {
        const values = [];

        if (filters[filterId].values.filterConfig === 'empty') {
          values.push('empty');
        }
        if (filters[filterId].values.filterConfig === 'notempty') {
          values.push('notempty');
        }
        // кейс, когда выбрана фильтарция по бесконечности (null)
        if (filters[filterId].values.fromValue === null) {
          values.push('empty');
          acc[`${filterId}[of]`] = values.toString();
          break;
        }

        if (filters[filterId].values.range) {
          if (filters[filterId].values.exclude) {
            if (filters[filterId].values.toValue && filters[filterId].values.fromValue) {
              acc[`${filterId}[exrange]`] = `${moment(
                filters[filterId].values.fromValue,
              ).format('YYYY-MM-DDTHH:mm:ssZ')},${moment(
                filters[filterId].values.toValue,
              ).format('YYYY-MM-DDTHH:mm:ssZ')}`;
            } else if (filters[filterId].values.fromValue) {
              acc[`${filterId}[exrange]`] = `${moment(
                filters[filterId].values.fromValue,
              ).format('YYYY-MM-DD')}T00:00:00Z,${moment(
                filters[filterId].values.fromValue,
              ).format('YYYY-MM-DD')}T23:59:59Z`;
            } else if (filters[filterId].values.toValue) {
              acc[`${filterId}[exrange]`] = `${moment(
                filters[filterId].values.toValue,
              ).format('YYYY-MM-DD')}T00:00:00Z,${moment(
                filters[filterId].values.toValue,
              ).format('YYYY-MM-DD')}T23:59:59Z`;
            }
          } else {
            if (filters[filterId].values.fromValue) {
              acc[`${filterId}[gte]`] = moment(
                filters[filterId].values.fromValue,
              ).format('YYYY-MM-DDTHH:mm:ssZ');
            }
            if (filters[filterId].values.toValue) {
              acc[`${filterId}[lt]`] = moment(
                filters[filterId].values.toValue,
              ).format('YYYY-MM-DDTHH:mm:ssZ');
            }
          }
        } else if (
          !moment
            .duration(
              moment(filters[filterId].values.fromValue).format('HH:mm:ss'),
            )
            .asSeconds()
        ) {
          if (filters[filterId].values.exclude) {
            acc[`${filterId}[exrange]`] = `${moment(
              filters[filterId].values.fromValue,
            ).format('YYYY-MM-DD')}T00:00:00Z,${moment(
              filters[filterId].values.fromValue,
            ).format('YYYY-MM-DD')}T23:59:59Z`;
          } else {
            // все записи за день
            acc[`${filterId}[gte]`] = `${moment(
              filters[filterId].values.fromValue,
            ).format('YYYY-MM-DD')}T00:00:00Z`;
            acc[`${filterId}[lte]`] = `${moment(
              filters[filterId].values.fromValue,
            ).format('YYYY-MM-DD')}T23:59:59Z`;
          }
        } else if (filters[filterId].values.fromValue) {
          if (filters[filterId].values.exclude) {
            acc[`${filterId}[exrange]`] = `${moment(
              filters[filterId].values.fromValue,
            ).format('YYYY-MM-DD')}T00:00:00Z,${moment(
              filters[filterId].values.fromValue,
            ).format('YYYY-MM-DD')}T23:59:59Z`;
          } else {
            values.push(
              moment(filters[filterId].values.fromValue).format('YYYY-MM-DDTHH:mm:ssZ'),
            );
          }
        }

        if (values.length) {
          acc[`${filterId}[of]`] = values.toString();
        }
        break;
      }
      default:
        break;
    }
    return acc;
  }, {});
