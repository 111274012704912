import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-use';
import {
  Route, Switch, BrowserRouter as Router, Redirect,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { ModuleRegistry } from '@ag-grid-community/core';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';

import toastRef from 'helpers/toast';
import Toaster from '@setproduct-ui/core/Toast/Toaster';
import { useToken } from 'hooks';
import { useDictionariesApi, useCompaniesApi, useUsersApi } from 'hooks/api';
import SignInScreen from 'screens/SignInScreen';
import endpoints from 'consts/endpoints';
import SideMenu from 'components/SideMenu';

import './scss/style.scss';

import switchRoutes from './routes/switchRoutes';
import { Header } from './components';

LicenseManager.setLicenseKey('CompanyName=Alarislabs,LicensedApplication=InVoice,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-026846,ExpiryDate=30_March_2023_[v2]_MTY4MDEzMDgwMDAwMA==b027f497102f6736b1d7fe91287990b5');
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  MasterDetailModule,
]);

let tokenTimeoutId;
let activityTimeoutId;

const App = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    getCountries,
    getTimezones,
    getCurrencies,
  } = useDictionariesApi();
  const { getOwnCompany } = useCompaniesApi();
  const screenRef = useRef(null);
  const { pathname } = useLocation();
  const [isPendingGetPortal, setIsPendingGetPortal] = useState(true);
  const [isPendingGetEnvJson, setIsPendingGetEnvJson] = useState(true);
  const [isLanck, setIsLanck] = useState(false);

  const screenContainerClasses = classNames('screen__container', {
    screen__container_padding0: pathname === '/mcc-mnc' || pathname === '/charges',
  });
  const {
    token,
    tokenExpiresIn,
    setToken,
    setTokenExpiresIn,
    refreshTokenExpiresIn,
    setRefreshTokenExpiresIn,
    setIdToken,
    resetAuth,
  } = useToken();
  const { getCurrentUser, currentUser } = useUsersApi();
  const isAuthInitialized = useRef(false);
  const isDictionariesRequested = useRef(false);

  const refreshToken = () => {
    fetch(endpoints.getRefreshTokenUrl(), {
      method: 'POST',
      credentials: process.env.NODE_ENV === 'production' ? undefined : 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setToken(data.token);
        setTokenExpiresIn(moment().add(data.expiresIn, 'seconds'));
        setRefreshTokenExpiresIn(moment().add(data.refreshExpiresIn, 'seconds'));
        setIdToken(data.idToken);
      })
      .catch((error) => {
        console.error('ERROR:', error);
        resetAuth();
      });
  };
  const toastRefUpdater = (ref) => {
    if (ref) {
      toastRef.current = ref;
      if (!toastRef.current?.showMessage) {
        toastRef.current.showMessage = ({ message, intent = 'danger', ...rest }) =>
          toastRef.current.show({
            message: typeof message === 'object' ? t(message.key, { ...message.values }) : t(message),
            intent,
            ...rest,
          });
      }
    }
  };
  const init = async () => {
    if (token) {
      // если в хранилище есть информация о предыдущей сессии
      if (moment(tokenExpiresIn).diff(moment(), 'seconds') > 0) {
        // если токен жив - пускаем App дальше для запуска приложения
      } else if (moment(refreshTokenExpiresIn).diff(moment(), 'seconds') > 0) {
        // если рефреш токен жив - запрашиваем рефреш
        await refreshToken();
      } else {
        // иначе скидываем информацию о предыдущей сессии
        resetAuth();
      }
    }
    isAuthInitialized.current = true;
  };
  const restartAutoReset = () => {
    if (activityTimeoutId) {
      clearTimeout(activityTimeoutId);
    }
    activityTimeoutId = setTimeout(() => {
      fetch(endpoints.getLogoutUrl(), {
        method: 'POST',
        credentials: process.env.NODE_ENV === 'production' ? undefined : 'include',
      })
        .then(() => {
          resetAuth();
        });
    }, 1000 * 60 * 60); // 60 min
  };

  if (!isAuthInitialized.current) {
    init();
  }
  const renderRoutes = useCallback(
    () => switchRoutes.map(({
      exact = true,
      href,
      path,
      Component,
    }) => (
      <Route exact={exact} key={href} path={path || href}>
        <Component screenRef={screenRef} />
      </Route>
    )),
    [switchRoutes, screenRef],
  );

  useEffect(() => {
    if (tokenTimeoutId) {
      clearTimeout(tokenTimeoutId);
    }
    const tokenExpiresInDiff = moment(tokenExpiresIn).diff(moment(), 'seconds');

    if (tokenExpiresInDiff > 0) {
      const timeout = (tokenExpiresInDiff - (tokenExpiresInDiff * 0.1)) * 1000;

      getCurrentUser();
      getOwnCompany();
      if (!isDictionariesRequested.current) {
        getCountries();
        getTimezones();
        getCurrencies();
        isDictionariesRequested.current = true;
      }
      tokenTimeoutId = setTimeout(refreshToken, (timeout < 0 ? 0 : timeout));
    }
  }, [tokenExpiresIn]);
  useEffect(() => {
    fetch(`${window.location.origin}/bo/env.json`)
      .then(res => res.json())
      .then((json) => {
        setIsLanck(!!json?.isLanck);
        setIsPendingGetEnvJson(false);
      })
      .catch(() => setIsPendingGetEnvJson(false));
    fetch(endpoints.getPortalAssetsUrl('portal.json'))
      .then(res => res.json())
      .then(async (res) => {
        if (res?.pageTitle) {
          document.title = res.pageTitle;
        }
        if (res?.favicon) {
          const link = document.createElement('link');
          link.type = 'image/x-icon';
          link.rel = 'icon';
          link.href = endpoints.getPortalAssetsUrl(res.favicon);

          const prevLink = document.querySelector("link[rel*='icon']");
          if (prevLink) {
            document.head.removeChild(prevLink);
          }
          document.head.appendChild(link);
        }
        if (res?.languages) {
          dispatch({ type: 'setAvailableLanguages', languages: res.languages });
        }

        await localStorage.setItem('boPortal', JSON.stringify(res));
        setIsPendingGetPortal(false);
      })
      .catch(() => setIsPendingGetPortal(false));
  }, []);
  useEffect(() => {
    window.addEventListener('touchmove', restartAutoReset);
    window.addEventListener('mousemove', restartAutoReset);
  }, []);

  if (!isAuthInitialized.current || (token && isEmpty(currentUser)) || isPendingGetPortal || isPendingGetEnvJson) {
    return null;
  }

  return (
    <Router basename="/bo">
      {token ? (
        <>
          <SideMenu isLanck={isLanck} />
          <div className="screen">
            <Header screenRef={screenRef} />
            <div className={screenContainerClasses}>
              <Switch>
                {renderRoutes()}
                <Route path="*">
                  <Redirect to="/companies" />
                </Route>
              </Switch>
            </div>
          </div>
        </>
      ) : (
        <Switch>
          <Route path="/sign-in">
            <SignInScreen />
          </Route>
          <Route path="*">
            <Redirect to={`/sign-in?redirectTo=${window.location.pathname}`} />
          </Route>
        </Switch>
      )}
      <Toaster
        ref={toastRefUpdater}
        view="filled"
        withoutClose={false}
        position="top"
        usePortal
        className="toast"
        timeout={5000}
      />
    </Router>
  );
};

export default App;
