import React, { useMemo, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { isEqual, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  TextField,
  TextAreaField,
  DropdownField,
} from 'components/fields';
import { ConfirmModal } from 'components/modals';
import { BANK_SCHEMA } from 'consts/validationSchemas';
import IsFormChanged from 'components/forms/IsFormChanged';
import { FormButtonsGroup } from 'components/blocks';
import { useDictionariesApi } from 'hooks/api';
import { useContentShadow, useModalLogic } from 'hooks';

import styles from './BankForm.module.scss';

const BankForm = ({
  initialValues = {
    branchCode: 'XXX',
  },
  onSubmit,
  onCancel,
  onDelete,
  mode,
  changeMode,
  isPending,
  isFormPristine,
  formValuesRef,
}) => {
  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);
  const [isConfirmOpen, { closeModal, openModal }] = useModalLogic();
  const contentRef = useRef(null);
  const {
    showTopShadow,
    showBottomShadow,
  } = useContentShadow(contentRef);
  const { countriesOptions } = useDictionariesApi();

  const isView = useMemo(() => mode === 'view', [mode]);

  const changeToEdit = () => changeMode('edit');
  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }

    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };
  const onSubmitModify = (values, _, force = false) => {
    console.log('FORCE ', force);
    if ((!values.swiftCode || !values.branchCode) && !force) {
      openModal();
    } else {
      onSubmit(values);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmitModify}
      validationSchema={BANK_SCHEMA}
      validateOnMount
    >
      {({
        handleSubmit, errors, setFieldTouched, values,
      }) => (
        <Form className={styles.container}>
          {showTopShadow && <div className={styles.shadow} />}
          <div className={styles.content} ref={contentRef}>
            <Field
              name="name"
              label="INSTANCES.NAME"
              component={TextField}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              disabled={isView}
              isRequired={!isView}
              fill
            />
            <Field
              name="info"
              label="INSTANCES.DESCRIPTION"
              component={TextAreaField}
              minRows={2}
              placeholder="PLACEHOLDERS.ENTER_DESCRIPTION"
              disabled={isView}
            />
            <Field
              name="legalCountryId"
              label="INSTANCES.COUNTRY"
              component={DropdownField}
              options={countriesOptions}
              placeholder="PLACEHOLDERS.SELECT"
              disabled={isView}
              isRequired={!isView}
              fill
            />
            <Field
              name="address"
              label="INSTANCES.ADDRESS"
              component={TextField}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              disabled={isView}
              isRequired={!isView}
              fill
            />
            <Field
              name="swiftCode"
              label="INSTANCES.BANKS.SWIFT_CODE"
              component={TextField}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              disabled={isView}
              maxLength={8}
              className={styles.uppercaseField}
              fill
            />
            <Field
              name="branchCode"
              label="INSTANCES.BANKS.BRANCH_CODE"
              component={TextField}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              disabled={isView}
              maxLength={3}
              className={styles.uppercaseField}
              fill
            />
            <Field
              name="bankCode"
              label="INSTANCES.BANKS.BANK_CODE"
              component={TextField}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              disabled={isView}
              fill
            />
          </div>
          <FormButtonsGroup
            onDelete={onDelete}
            onCancel={onCancel}
            setFieldTouched={setFieldTouched}
            errors={errors}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            isPending={isPending}
            showShadow={showBottomShadow}
          />
          {isConfirmOpen && (
            <ConfirmModal
              title="SCREENS.BANKS.WARNING_TITLE"
              text="SCREENS.BANKS.WARNING_TEXT"
              color="warning"
              closeModal={closeModal}
              onConfirm={() => onSubmitModify(values, null, true)}
              iconColor="var(--orange40)"
              leftText="CONTROLS.CANCEL"
              rightText="CONTROLS.SAVE"
            />
          )}
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
        </Form>
      )}
    </Formik>
  );
};

export default BankForm;
