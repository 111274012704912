import React, { useMemo } from 'react';
import { Icon } from '@blueprintjs/core';
import cx from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Chips from '@setproduct-ui/core/Chips';
import kFormatted from 'helpers/kFormatter';

import styles from './Card.module.scss';

const Card = ({
  id,
  agreementCode,
  isActive,
  onClick,
  actualEffectiveFromAbs,
  actualEffectiveTillAbs,
  currency,
  creditLimit,
  balancesDictionary,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    progressPercents,
    progressStatus,
  } = useMemo(() => {
    if (moment().isBefore(moment(actualEffectiveFromAbs))) {
      return {
        progressPercents: 0,
        progressStatus: 'upcoming',
      };
    }

    if (!actualEffectiveTillAbs) {
      return {
        progressPercents: 100,
        progressStatus: 'currentInfinity',
      };
    }

    if (moment().isAfter(actualEffectiveTillAbs)) {
      return {
        progressPercents: 100,
        progressStatus: 'expired',
      };
    }

    if (moment().isBefore(actualEffectiveTillAbs)) {
      const diffBetweenPeriods = moment(actualEffectiveTillAbs).diff(moment(actualEffectiveFromAbs));
      const diffBetweenNowAndEffectiveTill = moment().diff(moment(actualEffectiveFromAbs));
      const percents = diffBetweenNowAndEffectiveTill / diffBetweenPeriods * 100;

      return {
        progressPercents: percents,
        progressStatus: percents >= 70 ? 'expireSoon' : 'current',
      };
    }

    return {
      progressPercents: 0,
      progressStatus: 'Unknown',
    };
  }, [actualEffectiveFromAbs, actualEffectiveTillAbs]);

  const goToAgreementsDetails = () => {
    history.push({
      pathname: '/agreements',
      state: {
        tableFilters: {
          id: {
            values: {
              range: false,
              exclude: false,
              toValue: '',
              fromValue: id,
            },
            type: 'number',
            isWithoutEmpty: false,
          },
        },
      },
    });
  };

  return (
    <div
      className={cx(styles.container, { [styles.container_active]: isActive })}
      role="presentation"
      onClick={onClick}
    >
      <div className={styles.header}>
        <Chips tag={agreementCode} type="dense" round={false} className={styles.chips} />
        <div className={styles.link} role="presentation" onClick={goToAgreementsDetails}>
          {t('SCREENS.COMPANIES.DETAILS')}
          <Icon icon="arrow-top-right" size={14} />
        </div>
      </div>
      <div className={cx(styles.financeBlock, { [styles.financeBlock_expired]: progressStatus === 'expired' })}>
        <div className={styles.balance}>
          <span>{`${kFormatted(balancesDictionary[id], 10000000)} ${currency}`}</span>
          <span>{t('SCREENS.COMPANIES.BALANCE')}</span>
        </div>
        <div className={styles.creditLimit}>
          <span style={{ color: creditLimit ? undefined : 'var(--grey50)' }}>
            {creditLimit
              ? `${kFormatted(creditLimit, 100000)} ${currency}`
              : t('PLACEHOLDERS.AGREEMENTS.UNLIMITED')}
          </span>
          <span>{t('SCREENS.COMPANIES.CREDIT_LIMIT')}</span>
        </div>
      </div>
      <div className={styles.expirationDate}>
        <div>
          <span>{`${t('SCREENS.COMPANIES.STARTS')} ${moment(actualEffectiveFromAbs).format('DD MMM YYYY')}`}</span>
          <div>
            {progressStatus === 'expireSoon' && (
              <Icon icon="error" size={12} color="var(--orange60)" />
            )}
            {actualEffectiveTillAbs
              ? `${t('SCREENS.COMPANIES.ENDS')} ${moment(actualEffectiveTillAbs).format('DD MMM YYYY')}`
              : `∞ ${t('CONTROLS.INDEFINITELY')}`}
          </div>
        </div>
        <div className={cx(styles.progressBar, [styles[`progressBar_${progressStatus}`]])}>
          <div style={{ width: `${progressPercents}%` }} />
        </div>
      </div>
    </div>
  );
};

export default Card;
