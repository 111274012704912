import { getParams } from 'helpers';

const rootHost = process.env.NODE_ENV === 'production' ? window.location.origin : 'https://mkitdev2.alarislabs.com';
const backOfficeHost = `${rootHost}/backoffice-service`;
const backOfficeWsHost = backOfficeHost.replace('https', 'wss');
const billingInfoHost = `${rootHost}/billing-info-service`;
const sendersHost = `${rootHost}/sender-id-service`;
const mkitRootHost = rootHost.replace('-admin', '');
const reportingHost = `${mkitRootHost}/reporting-service/v1`;
const reportsHost = `${mkitRootHost}/report-service`;
const financeService = `${rootHost}/finance-service/v1`;
const campaignsHost = `${rootHost}/campaign-service`;
const balancesHost = `${rootHost}/balance-updater-service`;
const pipelineHost = `${rootHost}/pipeline-service`;
const assetsHost = `${mkitRootHost}/content-service`;
const smppHost = `${rootHost}/smpp-service`;
const notificationService = `${rootHost}/notification-service`;
const accountHost = `${rootHost}/account-service`;
const tarifficationManagerHost = `${rootHost}/tariffication-manager-service/v1`;
const documentService = `${rootHost}/document-service`;
const httpVendorService = `${rootHost}/http-vendor-service/v1`;
const dictionaryService = `${rootHost}/dictionary-service/v1`;
const moderationService = `${rootHost}/moderation-service`;
const tarifficationWsHost = `${rootHost.replace('https', 'wss')}/tariffication-manager-service/ws`;
const smppApiHost = `${rootHost}/smppapi-crud-service/v1`;
const integrationService = `${rootHost}/integration-service`;
const dashboardHost = `${rootHost}/finance-service/v1/dashboards`;
const edrHost = `${rootHost}/edrs-restapi-service`;
const rerateHost = `${rootHost}/rerate-service/v1`;
const contentTemplateHost = `${rootHost}/content-template-service`;
const flowHost = `${rootHost}/flow-service`;

export default {
  rootHost,
  getSocketUrl: ({ token }) => `${backOfficeWsHost}/ws?token=${token}`,
  getMccMncUrl: (id = '') => `${backOfficeHost}/networks${id ? `/${id}` : ''}`,
  getDialCodesUrl: (id = '') => `${backOfficeHost}/dial-codes${id ? `/${id}` : ''}`,
  getCountriesUrl: () => `${backOfficeHost}/countries`,
  getUsersOldUrl: (id = '') => `${backOfficeHost}/users${id ? `/${id}` : ''}`,
  getAuthoritiesUrl: (id = '') => `${backOfficeHost}/users/${id}/authorities`,
  getIndustriesUrl: () => `${backOfficeHost}/industries`,
  getRolesUrl: () => `${accountHost}/users/roles`,
  getPermissionsUrl: (id = '') => `${backOfficeHost}/permissions${id ? `/${id}` : ''}`,
  getTimezonesUrl: () => `${backOfficeHost}/timezones`,
  getAccountsUrl: (id = '') => `${backOfficeHost}/accounts${id ? `/${id}` : ''}`,
  getCurrenciesUrl: () => `${backOfficeHost}/currencies`,
  getAgreementUrl: (id = '') => `${backOfficeHost}/agreement${id ? `/${id}` : ''}`,
  getAgreementsAndVersionsUrl: () => `${backOfficeHost}/agreement-and-agreement-versions`,
  getAgreementsAndVersionUrl: (id = '') => `${backOfficeHost}/agreement-and-agreement-version${id ? `/${id}` : ''}`,
  getAgreementVersionUrl: (id = '') => `${backOfficeHost}/agreement-version${id ? `/${id}` : ''}`,
  getAgreementsVersionsUrl: () => `${backOfficeHost}/agreement-versions`,
  getOwnAgreementsUrl: () => `${backOfficeHost}/agreements/own-company`,
  getAgreementUpdateLimitUrl: () => `${backOfficeHost}/agreement/update-limit`,
  getPaymentsListUrl: () => `${backOfficeHost}/payments-bo`,
  getPaymentsUrl: (id = '') => `${backOfficeHost}/payments${id ? `/${id}` : ''}`,
  getRatePlansUrl: (id = '') => `${backOfficeHost}/rate-plans${id ? `/${id}` : ''}`,
  getRatesUrl: (id = '') => `${backOfficeHost}/rates${id ? `/${id}` : ''}`,
  getSenderUrl: id => `${backOfficeHost}/senders-bo${id ? `/${id}` : ''}`,
  getSenderDeleteCheck: id => `${backOfficeHost}/whatsapp-dialog360-templates/check-available-by-sender-id-id${id ? `/${id}` : ''}`,
  getSendersUrl: () => `${backOfficeHost}/senders-bo`,
  getApplicationsUrl: () => `${backOfficeHost}/senderapplications-bo`,
  getApplicationUrl: id => `${backOfficeHost}/senderapplications-bo${id ? `/${id}` : ''}`,
  getApplicationFileUrl: (id, fileId) => `${backOfficeHost}/senderapplications-mkit/files${id && fileId ? `/${id}/${fileId}` : ''}`,
  getLegalEntitiesUrl: id => `${backOfficeHost}/legal-entities${id ? `/${id}` : ''}`,
  getLegalEntitiesOwnUrl: () => `${backOfficeHost}/legal-entities/own-company`,

  getSelfRegisterUrl: (id = '') => `${backOfficeHost}/self-register-crud${id ? `/${id}` : ''}`,
  getSelfRegisterFileUrl: (entityId, fileId) => `${backOfficeHost}/self-register-crud/files/${entityId}/${fileId}`,

  getEventTypesUrl: (id = '') => `${billingInfoHost}/event-types${id ? `/${id}` : ''}`,
  getEventAttributesUrl: id => `${billingInfoHost}/event-types/${id}/attributes`,
  getAttributesOldUrl: id => `${billingInfoHost}/event-types/attributes/${id}`,
  getAdaptersUrl: id => `${billingInfoHost}/adapters${id ? `/${id}` : ''}`,
  getAttributeRulesUrl: () => `${billingInfoHost}/attribute-rules`,
  getRulesUrl: id => `${billingInfoHost}${id ? `/${id}` : ''}`,

  getSenderGroupsUrl: () => `${sendersHost}/groups`,

  getGroupsUrl: () => `${reportsHost}/groups`,
  getCreateReportUrl: params => `${reportsHost}/report${getParams(params)}`,
  getReportFileUrl: () => `${reportsHost}/report/file`,
  getReportParametersUrl: () => `${reportsHost}/parameters`,
  getNewReportUrl: (id = '') => `${reportingHost}/reports${id ? `/${id}` : ''}`,

  getSettingsUrl: (id = '') => `${pipelineHost}/settings${id ? `/${id}` : ''}`,

  getAccountsIdUrl: () => `${campaignsHost}/campaigns/dashboard/accounts`,
  getAccountStatisticsUrl: params => `${campaignsHost}/campaigns/dashboard${getParams(params)}`,

  getSubscribersStatisticsUrl: () => `${campaignsHost}/campaigns/dashboard/subscribers`,
  getThreadStatisticsUrl: () => `${campaignsHost}/campaigns/dashboard/threads`,

  getAddAssetUrl: () => `${assetsHost}/files/upload-bo/drop`,

  getSmppConnectionsUrl: id => `${smppHost}/v1/connections${id ? `/${id}` : ''}`,

  getFeeRulesUrl: id => `${balancesHost}/rules${id ? `/${id}` : ''}`,
  getFeePriceUrl: (params = {}, feeType) => `${balancesHost}/${feeType}/price${getParams(params)}`,
  getSetupFeeUrl: () => `${balancesHost}/setup-fee`,
  getMnosUrl: () => `${balancesHost}/mno`,

  getServicesEnabledUrl: id => `${backOfficeHost}/services-enabled${id ? `/${id}` : ''}`,
  getPacksUrl: id => `${balancesHost}/packs${id ? `/${id}` : ''}`,
  getBuyPacksUrl: () => `${balancesHost}/packs/buy-pack`,
  getPackPriceUrl: (params = {}) => `${balancesHost}/packs/price${getParams(params)}`,

  getAdditionalFeesUrl: () => `${balancesHost}/supply-fee`,
  getAdditionalFeePriceUrl: () => `${balancesHost}/supply-fee/price`,

  getServicesUrl: () => `${backOfficeHost}/services`,

  getInvoicesUrl: () => `${financeService}/invoices`,
  getInvoiceNotesUrl: (invoiceId, noteId) => `${financeService}/invoices/${invoiceId}/notes${noteId ? `/${noteId}` : ''}`,
  getInvoiceUrl: (id, postfix) => `${financeService}/invoices/${id}/${postfix}`,
  getChargesUrl: () => `${financeService}/charges`,
  getChargeDetailsUrl: (id = '') => `${financeService}/charges/${id}/detail`,
  getPatchChargeUrl: (id, postfix) => `${financeService}/charges/${id}/${postfix}`,
  getFileTaskUrl: (id, params) => `${financeService}/files/tasks/${id}${getParams(params)}`,

  getCompaniesUrl: id => `${backOfficeHost}/companies${id ? `/${id}` : ''}`,
  getOwnCompanyUrl: () => `${backOfficeHost}/companies/own-company`,

  getNotificationsChannels: (id = '') => `${notificationService}/channels${id ? `/${id}` : ''}`,
  getNotificationsScenarios: (id = '') => `${notificationService}/scenario${id ? `/${id}` : ''}`,

  getUserUrl: (group, id) => `${accountHost}/backoffice/users-${group === 'backoffice' ? 'bo' : 'mkit'}${id ? `/${id}` : ''}`,
  getCurrentUserUrl: () => `${accountHost}/users/me`,
  getUpdateUserPasswordUrl: (group, id) => `${accountHost}/backoffice/users-${group === 'backoffice' ? 'bo' : 'mkit'}/password/${id}`,

  getBuildInfoUrl: () => `${rootHost}/bo/version.json`,

  getTServicesUrl: id => `${tarifficationManagerHost}/services${id ? `/${id}` : ''}`,
  getArchiveServiceUrl: id => `${tarifficationManagerHost}/services/${id}/archive`,
  getUnarchiveServiceUrl: id => `${tarifficationManagerHost}/services/${id}/unarchive`,
  getServiceScopesUrl: id => `${tarifficationManagerHost}/service-scopes${id ? `/${id}` : ''}`,
  getScopeItemsUrl: ({ id, serviceScopeId } = {}) =>
    `${tarifficationManagerHost}/service-scope-items${id ? `/${id}` : ''}${getParams({ serviceScopeId })}`,
  getServicesWithScopesUrl: serviceType => `${tarifficationManagerHost}/services/all-with-scopes?type=${serviceType}`,
  getMassChildrenEditUrl: scopeItemId => `${tarifficationManagerHost}/service-scope-items/${scopeItemId}/children`,
  getBreakdownUrl: scopeItemId => `${tarifficationManagerHost}/service-scope-items/${scopeItemId}/change-children-match-attribute-id`,

  getServicesDictionaryUrl: () => `${tarifficationManagerHost}/services/all`,
  getResourcesDictionaryUrl: () => `${tarifficationManagerHost}/resources/all`,
  getResourceAttributesUrl: () => `${tarifficationManagerHost}/resource-attributes/all`,
  getServiceDomainsUrl: () => `${tarifficationManagerHost}/service-domains/all`,
  getBillingRulesUrl: () => `${tarifficationManagerHost}/resource-billing-rules/all`,
  getQuantityRulesUrl: () => `${tarifficationManagerHost}/resource-quantity-rules/all`,
  getCloneServiceUrl: () => `${tarifficationManagerHost}/services/clone`,

  getServicePropertiesUrl: () => `${tarifficationManagerHost}/properties`,

  getRateImportUrl: () => `${tarifficationManagerHost}/rate-import`,
  getRateImportFilePreviewUrl: taskId => `${tarifficationManagerHost}/rate-import/${taskId}/file-preview`,
  getRateImportPreviewUrl: taskId => `${tarifficationManagerHost}/rate-import/${taskId}/preview`,
  getRateImportSettingsUrl: taskId => `${tarifficationManagerHost}/rate-import/${taskId}/settings`,
  getRateImportSocketUrl: (taskId, token) => `${tarifficationWsHost}/rate-import/${taskId}/parse-updates/subscribe?token=${token}`,
  getColumnSettingsUrl: (taskId, colId) => `${tarifficationManagerHost}/rate-import/${taskId}/settings/columns${colId ? `/${colId}` : ''}`,
  getColumnMappingsUrl: (taskId, colId) => `${tarifficationManagerHost}/rate-import/${taskId}/settings/columns/${colId}/mappings`,
  getColumnPropertiesUrl: (taskId, colId) => `${tarifficationManagerHost}/rate-import/${taskId}/settings/columns/${colId}/properties`,
  getTreeStructureOrderUrl: taskId => `${tarifficationManagerHost}/rate-import/${taskId}/settings/tree-structure/order`,
  getTreeStructureUrl: (taskId, elemId) => `${tarifficationManagerHost}/rate-import/${taskId}/settings/tree-structure${elemId ? `/${elemId}` : ''}`,
  getApplyRateImportUrl: taskId => `${tarifficationManagerHost}/rate-import/${taskId}/apply`,

  getPrintableFormsUrl: id => `${documentService}/template${id ? `/${id}` : ''}`,
  getPrintableFormsTypesUrl: () => `${documentService}/template/types`,
  getDownloadPrintableFormUrl: id => `${documentService}/template/file${id ? `/${id}` : ''}`,

  getApiCallsUrl: () => `${accountHost}/audit/bo/api-calls`,
  getOperationsUrl: () => `${accountHost}/audit/bo/operations`,
  getAuditDetailsUrl: execId => `${accountHost}/audit/bo/details/${execId}`,
  getLoadAuditDetailsUrl: execId => `${accountHost}/audit/bo/load-details/${execId}`,

  getWabaUrl: id => `${backOfficeHost}/waba-bo${id ? `/${id}` : ''}`,

  getHttpConnectionsUrl: id => `${httpVendorService}/connections${id ? `/${id}` : ''}`,

  getItemLinksUrl: () => `${dictionaryService}/item-links`,
  getItemsWithDefinitionsUrl: () => `${dictionaryService}/items/list-with-definitions?source=bo`,
  getFilteredItems: dictionaryId => `${dictionaryService}/items/filtered?dictionaryId=${dictionaryId}`,
  getDictionariesWithLinksUrl: () => `${dictionaryService}/dictionaries/with-linked-dictionaries`,
  getAttributesUrl: () => `${dictionaryService}/attributes`,

  getModerationUrl: id => `${moderationService}/requests${id ? `/${id}` : ''}`,
  getResolutionApplyUrl: () => `${moderationService}/resolution/apply`,

  getCampaignByIdUrl: id => `${campaignsHost}/campaigns${id ? `/${id}` : ''}`,
  getCampaignWithRunsByIdUrl: id => `${campaignsHost}/campaigns/${id}/one-with-processes`,
  getCampaignProcessesUrl: id => `${campaignsHost}/campaigns/${id}/processes`,
  getCampaignsUrl: () => `${campaignsHost}/campaigns-ref`,
  createCampaignsUrl: () => `${campaignsHost}/campaigns`,
  updateCampaignUrl: id => `${campaignsHost}/campaigns${id ? `/${id}` : ''}`,
  getRunInfoUrl: (campaignId, runId) => `${campaignsHost}/campaigns/${campaignId}/processes/${runId}`,
  getRerunInfoUrl: (campaignId, runId) => `${campaignsHost}/campaigns/${campaignId}/processes/${runId}/rerun`,

  getSignInUrl: () => `${accountHost}/auth/bo/sign-in${process.env.NODE_ENV === 'production' ? '' : '-lh'}`,
  getRefreshTokenUrl: () => `${accountHost}/auth/bo/refresh-token${process.env.NODE_ENV === 'production' ? '' : '-lh'}`,
  getLogoutUrl: () => `${accountHost}/auth/bo/logout${process.env.NODE_ENV === 'production' ? '' : '-lh'}`,
  getResetPasswordUrl: () => `${accountHost}/auth/bo/reset-password`,
  getConfirmCodeUrl: () => `${accountHost}/auth/bo/validate/sign-in`,
  getActivatePasswordUrl: () => `${accountHost}/auth/bo/activate-password`,
  getResetUserPasswordUrl: () => `${accountHost}/auth/users-pwd/reset`,

  getResellingAgreementListUrl: legalEntityId => `${backOfficeHost}/reselling-agreement/legal-entity/${legalEntityId}`,
  getResellingAgreementUrl: id => `${backOfficeHost}/reselling-agreement${id ? `/${id}` : ''}`,

  getPortalsUrl: id => `${backOfficeHost}/portals${id ? `/${id}` : ''}`,
  getPortalFileUrl: (portalId, filename) => `${backOfficeHost}/portals/files/${portalId}/${filename}`,
  getCheckDomainUrl: fqdn => `${backOfficeHost}/portals/domain-check/${fqdn}`,
  getCertCheckUrl: fqdn => `${backOfficeHost}/portals/cert-check/${fqdn}`,

  getPortalAssetsUrl: filename => `${rootHost}/assets/${filename}`,

  getWhatsAppCategoriesUrl: () => `${backOfficeHost}/whatsapp-dialog360-templates/categories`,
  getWhatsAppLanguagesUrl: () => `${backOfficeHost}/whatsapp-dialog360-templates/languages`,
  getWhatsAppTemplatesUrl: () => `${backOfficeHost}/whatsapp-dialog360-templates`,

  getSmppApiConnectionsUrl: id => `${smppApiHost}/connections${id ? `/${id}` : ''}`,

  getIntegrationsUrl: id => `${integrationService}/bo/integrations${id ? `/${id}` : ''}`,
  getCreateIntegrationUrl: method => `${integrationService}/bo/integrations/${method}`,
  getIntegrationsHandlersUrls: () => `${integrationService}/integrations/handlers/urls`,
  getGenerateIntegrationApiKeyUrl: () => `${integrationService}/integrations/api-key/generate`,

  getIntegrationStatisticsUrl: type => `${dashboardHost}/${type}`,

  getEdrDownloadUrl: (filter, format, companyId) => `${edrHost}/bo/edrs/download?filter=${filter}&format=${format}&companyId=${companyId}`,

  getEdrRerateTasksUrl: id => `${rerateHost}/tasks${id ? `/${id}` : ''}`,
  getEstimateEdrRerateTaskUrl: () => `${rerateHost}/tasks/estimate`,

  getDidSenders: id => `${backOfficeHost}/did-senders-bo${id ? `/${id}` : ''}`,
  assignDidSender: id => `${backOfficeHost}/did-senders-bo/assign${id ? `/${id}` : ''}`,
  reassignDidSender: id => `${backOfficeHost}/did-senders-bo/reassign/${id}`,
  getDidSenderHistory: id => `${backOfficeHost}/did-senders-bo/show-history/${id}`,
  unassignDidSender: id => `${backOfficeHost}/did-senders-bo/unassign/${id}`,

  getConversationTemplatesUrl: () => `${backOfficeHost}/conversation-template/bo`,
  getContentTemplatesUrl: () => `${contentTemplateHost}/templates/bo`,
  getBalancesByCompanyIdUrl: id => `${balancesHost}/balance/${id}`,
  getTopSendersByCompanyIdUrl: params => `${financeService}/client-profile-statistics/top-senders${getParams(params)}`,
  getTopChatbotsByCompanyIdUrl: params => `${financeService}/client-profile-statistics/top-chatbots${getParams(params)}`,
  getTopChannelsByCompanyIdUrl: params => `${financeService}/bo/dashboards/traffic-by-channel${getParams(params)}`,

  getBundleInitiatorsUrl: id => `${balancesHost}/bundles/initiators${id ? `/${id}` : ''}`,
  getEnableBundleInitiatorUrl: () => `${balancesHost}/bundles/initiators/enable`,
  getBundlePacksUrl: id => `${balancesHost}/bundles/packs${id ? `/${id}` : ''}`,
  getEnableBundlePackUrl: () => `${balancesHost}/bundles/packs/enable`,

  getFlowsAllUrl: () => `${flowHost}/flows-new/bo/all`,

  getBanksUrl: id => `${backOfficeHost}/bank${id ? `/${id}` : ''}`,
  getBankAccountsUrl: ({ id, params } = {}) => `${backOfficeHost}/bank_account${id ? `/${id}` : ''}${getParams(params)}`,
  getOwnBankAccountsUrl: () => `${backOfficeHost}/bank_account/own-company`,
  getBanksDictionaryUrl: () => `${backOfficeHost}/bank/parent-banks`,
};
