import { Icon } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import React, {
  useMemo, useState, useRef, useEffect,
} from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import Button from '@setproduct-ui/core/Button';
import TextField from 'components/fields/TextField';
import { Typography } from 'components/Typography';
import Switch from '@setproduct-ui/core/Switch/Switch';
import DatePicker from '@setproduct-ui/core/DateTime/DatePicker';
import Tooltip from '@setproduct-ui/core/Tooltip/Tooltip';

import './style.scss';

const DatePickerField = ({
  name: externalName,
  value: externalValue,
  onChange: externalOnChange,
  setFieldValue: externalSetFieldValue,
  field: {
    onChange = externalOnChange,
    value = externalValue,
    name = externalName,
  } = {},
  form: {
    setFieldValue = externalSetFieldValue,
    errors = {},
    touched = {},
  } = {},
  placeholder = 'DD.MM.YYYY',
  label,
  disabled,
  styles = {},
  isRequired,
  format = 'DD.MM.YYYY, HH:mm:ss',
  withIndefinitely,
  timePrecision,
  pickerDense,
  applyButton,
  minimal = true,
  clearButton = true,
  closeOnChange = !timePrecision,
  placement = 'bottom-start',
  minDate,
  maxDate,
  datePickerProps,
  children,
  tooltipMessage,
  isNow,
  usePortal,
  readOnlyText = false,
  initialMonth,
  ...props
}) => {
  const { t } = useTranslation();
  const switchRef = useRef();
  const inputRef = useRef(null);

  const [textValue, setTextValue] = useState('');
  const [popoverShow, setPopoverShow] = useState(false);
  const [isIndefinitely, setIsIndefinitely] = useState(value === null);

  const fieldClass = classNames('date-picker-field', {
    'date-picker-field_disabled': disabled,
  });
  const labelClass = classNames('date-picker-field__label', {
    'date-picker-field__label_required': isRequired,
  });
  const contentClass = classNames('date-picker-field__content__overlay', {
    'date-picker-field__content__overlay_dense': pickerDense,
  });

  const getDiffFromNow = (date) => {
    const currentDate = dayjs(date);
    const now = dayjs().toDate();
    return currentDate.diff(now);
  };

  const minDateInternal = useMemo(() => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - String(date.getFullYear()).slice(2) - 100);
    date.setMonth(0, 1);
    return date;
  }, []);
  const maxDateInternal = useMemo(() => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - String(date.getFullYear()).slice(2) + 100);
    date.setMonth(11, 31);
    return date;
  }, []);
  const regexpDate = useMemo(() => {
    switch (format) {
      case 'MM.DD.YYYY':
      case 'DD.MM.YYYY':
        return /[0-9]{1,2}[\\.\\,\\/\-\s][0-9]{1,2}[\\.\\,\\/\-\s][1-2][0-9]{3}/g;
      case 'MM.DD.YYYY, HH:mm:ss':
      case 'DD.MM.YYYY, HH:mm:ss':
        return /[0-9]{1,2}[\\.\\,\\/\-\s][0-9]{1,2}[\\.\\,\\/\-\s][1-2][0-9]{3}.{1,2}[0-9]{2}[\\:\\.\\,][0-9]{2}[\\:\\.\\,][0-9]{2}/g;
      default:
        return null;
    }
  }, [format]);

  const handleClose = () => {
    setPopoverShow(!popoverShow);
  };
  const handleChange = (newValue, isClickOnDay) => {
    let resultValue = newValue;

    if (minDate) {
      // todo: убрать использование dayjs + пересмотреть логику с resultValue, кажется нести это в компонент не стоит
      const diff = dayjs(newValue).diff(dayjs(minDate));
      if (diff < 0) {
        resultValue = minDate;
      }
    }

    if (withIndefinitely && isIndefinitely) {
      switchRef.current.click();
    }

    if (setFieldValue) {
      setFieldValue(name, resultValue);
    } else {
      onChange(resultValue);
    }

    if (closeOnChange && isClickOnDay) {
      handleClose();
    }
  };
  const handleSwitch = () => {
    const newValue = isIndefinitely ? undefined : null;
    setIsIndefinitely(!isIndefinitely);

    if (setFieldValue) {
      setFieldValue(name, newValue);
    } else {
      onChange(newValue);
    }
  };
  const handleInteraction = (nextOpenState) => {
    setPopoverShow(nextOpenState);
  };
  const onClosed = () => {
    inputRef.current.blur();
  };
  const handleClear = () => {
    handleChange(undefined);
    setTextValue({
      value: '',
      highlight: false,
    });
  };
  const handleTextFrield = (valueInput) => {
    if (valueInput?.match(regexpDate)) {
      const date = moment(valueInput, format).toDate();
      if (date.toString() !== 'Invalid Date') {
        if (minDateInternal > date) {
          handleChange(minDateInternal);
        } else if (maxDateInternal < date) {
          handleChange(maxDateInternal);
        } else {
          handleChange(date);
        }
      } else {
        handleChange(undefined);
      }
    }
    if (valueInput?.length <= format.length || !valueInput) {
      setTextValue({
        value: valueInput,
        highlight: false,
      });
    }
  };
  const convertToDate = () => {
    if (textValue.value && textValue.highlight !== true) {
      const date = moment(textValue.value, format).toDate();
      let valueText = '';
      if (date.toString() === 'Invalid Date') {
        handleChange(value);
        valueText = value;
      } else if (minDateInternal > date) {
        handleChange(minDateInternal);
        valueText = minDateInternal;
      } else if (maxDateInternal < date) {
        handleChange(maxDateInternal);
        valueText = maxDateInternal;
      } else {
        handleChange(date);
        valueText = date;
      }
      setTextValue({
        value: moment(valueText).format(format),
        highlight: false,
      });
    }
  };
  const calculateValueData = (valueDate) => {
    if (valueDate) {
      const formattedValue = moment(valueDate).format(format);
      if (isNow) {
        const diff = getDiffFromNow(valueDate);
        if (diff < 0) {
          return {
            value: `${t('CONTROLS.NOW')}`,
            highlight: true,
          };
        }
      }
      return {
        value: formattedValue,
        highlight: false,
      };
    }
    if (valueDate === null && withIndefinitely) {
      return {
        value: `∞ ${t('CONTROLS.INDEFINITELY')}`,
        highlight: true,
      };
    }

    return {
      value: '',
      highlight: false,
    };
  };

  useEffect(() => {
    const date = calculateValueData(value);
    setTextValue(date);
  }, [value]);

  return (
    <Popover2
      autoFocus={false}
      isOpen={popoverShow}
      onInteraction={handleInteraction}
      styles={styles.popover}
      interactionKind="click"
      placement={placement}
      minimal={minimal}
      usePortal={usePortal}
      disabled={disabled}
      transitionDuration={0}
      portalClassName="date-picker-field__portal"
      onClosed={onClosed}
      content={(
        <div className={contentClass} style={styles.overlay}>
          {
            withIndefinitely && (
              <div style={{ marginBottom: 10 }}>
                <Switch
                  type="dense"
                  color="primary"
                  checked={isIndefinitely}
                  name="datepicker-field-switch/indefinitely"
                  label={(
                    <div style={{ display: 'flex' }}>
                      ∞
                      <Typography
                        style={{ marginLeft: 5 }}
                        colorStep={40}
                      >
                        CONTROLS.INDEFINITELY
                      </Typography>
                    </div>
                  )}
                  onChange={handleSwitch}
                  inputRef={switchRef}
                />
              </div>
            )
          }
          <DatePicker
            value={value}
            onChange={handleChange}
            color="primary"
            timePrecision={timePrecision}
            maxDate={maxDate || maxDateInternal}
            minDate={minDate || minDateInternal}
            initialMonth={value || initialMonth || maxDate || minDate}
            highlightCurrentDay
            {...datePickerProps}
          />
          {applyButton && (
            <div className="date-picker-field__content__overlay__button">
              <Button
                dense
                view="filled"
                color="primary"
                onClick={handleClose}
                data-testid={name
                  ? `${window.location.pathname}/datepicker-field-button/${name}/close`
                  : undefined}
              >
                <Typography
                  tagName="span"
                  className="date-picker-field__content__overlay__button__text"
                >
                  {t('CONTROLS.APPLY')}
                </Typography>
              </Button>
            </div>
          )}
        </div>
      )}
      renderTarget={({ ref, isOpen, ...targetProps }) => (
        <div
          style={styles.container}
          ref={ref}
          {...targetProps}
          className={fieldClass}
        >
          {
            label && (
              <div className="date-picker-field__label-container">
                <Typography className={labelClass} style={styles.label}>
                  {label}
                </Typography>
                {tooltipMessage && (
                  <Tooltip
                    color="default"
                    content={tooltipMessage}
                    position="top"
                    disabled={false}
                    className="date-picker-field__tooltip"
                  >
                    <div className="date-picker-field__label-tooltip-icon">
                      <Icon icon="error" size={12} color="var(--grey30)" />
                    </div>
                  </Tooltip>
                )}
              </div>
            )
          }
          <div style={styles.content} className="date-picker-field__content">
            <TextField
              classNames={{ container: 'date-picker-field__content__input' }}
              value={textValue?.value}
              readOnly={readOnlyText}
              onChange={handleTextFrield}
              placeholder={placeholder}
              disabled={disabled}
              styles={{
                container: {
                  ...styles.input,
                  color: textValue?.highlight
                    ? 'var(--blue70)'
                    : 'var(--grey100)',
                },
              }}
              clearButton={false}
              fieldError={errors[name]}
              fieldTouched={touched[name]}
              showErrorIcon={false}
              name={`${name}/datepicker-field/input`}
              onBlur={convertToDate}
              inputRef={inputRef}
              {...props}
            />
            <Icon
              icon={isOpen && textValue?.value && clearButton ? 'cross' : 'calendar'}
              size={18}
              className="date-picker-field__content__icon"
              color={isOpen && textValue?.value && clearButton ? 'var(--red50)' : 'var(--blue70)'}
              onClick={isOpen && clearButton ? handleClear : Function.prototype}
            />
          </div>
          {children}
        </div>
      )}
    />
  );
};

export default DatePickerField;
