import React, { useState, useEffect, useMemo } from 'react';

import Table from 'components/Table';
import Modal from 'components/Modal';
import ScrollableTabs from 'components/ScrollableTabs';
import { ConfirmModal } from 'components/modals';
import { useScreen, useUserPermissions } from 'hooks';
import { useCompaniesApi, useDictionariesApi } from 'hooks/api';
import { COMPANIES } from 'consts/columns';
import { CustomColumnsView } from 'components/blocks';
import Button from '@setproduct-ui/core/Button';

import CompanyForm from './CompanyForm';
import CompanyViewTab from './CompanyViewTab';
import styles from './CompaniesScreen.module.scss';

const CompaniesScreen = ({ screenRef }) => {
  // hooks
  const [openTabs, setOpenTabs] = useState([
    {
      id: 'table',
      title: 'TABS.COMPANIES',
      withoutCross: true,
    },
  ]);
  const [activeTab, setActiveTab] = useState(0);
  const { createAllowed } = useUserPermissions();
  const {
    companies,
    companiesMeta,
    getCompanies,
    postCompany,
    putCompany,
    deleteCompany,
    isPendingPostCompany,
    isPendingPutCompany,
    isPendingDeleteCompany,
    isPendingGetCompanies,
    lastUpdatedGetCompanies,
  } = useCompaniesApi();
  const {
    getOwnLegalEntitiesDictionary,
    getLegalEntitiesDictionary,
    getAgreementsDictionary,
    getServicesDictionary,
    getFlowsDictionary,
    isPendingGetFlowsDictionary,
    isPendingGetOwnLegalEntitiesDictionary,
    isPendingGetPortalsDictionary,
    getPortalsDictionary,
    portalsDictionary,
    ownLegalEntitiesDictionary,
  } = useDictionariesApi();
  const {
    openModal,
    editableRecord,
    sendQuery,
    onCloseModal,
    limit,
    offset,
    setOffset,
    setLimit,
    onSortChanged,
    onFilterChanged,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    onDeleteClick,
    isFormPristine,
    formValuesRef,
    isOpenConfirm,
    closeConfirm,
    onDeleteConfirm,
    onConfirmClose,
    isOpenModal,
    setEditableRecord,
  } = useScreen({
    screenRef,
    getFunc: getCompanies,
    deleteFunc: deleteCompany,
  });

  const onRowClicked = ({ data }) => {
    onConfirmClose();
    setOpenTabs((prev) => {
      const indexOfTab = prev.findIndex(tab => tab.id === data.id);

      if (indexOfTab !== -1) {
        setActiveTab(indexOfTab);
        return prev;
      }

      setActiveTab(prev.length);

      return ([
        ...prev,
        {
          id: data.id,
          title: data.name,
          data: {
            ...data,
          },
        },
      ]);
    });
  };
  const openCompanyOnEdit = (data) => {
    setEditableRecord({ ...data });
    openModal('view');
  };

  const companiesModified = useMemo(() => companies.map(company => ({
    ...company,
    portalId: ownLegalEntitiesDictionary[company.parentCompanyLEId]?.portalId,
  })), [companies, portalsDictionary, ownLegalEntitiesDictionary]);
  const columns = useMemo(() => [
    ...COMPANIES,
    {
      headerName: 'INSTANCES.ACTIONS',
      cellClass: 'appearing-cell',
      cellRenderer: 'cellRendererActions',
      width: 80,
      minWidth: 80,
      resizable: false,
      sortable: false,
      valueFormatter: () => [
        {
          id: 'view',
          icon: 'eye-open',
          onClick: data => onRowClicked({ data }),
          htmlTitle: 'View company info',
        },
        {
          id: 'edit',
          icon: 'edit',
          color: '#2264D1',
          onClick: openCompanyOnEdit,
        },
      ],
    },
  ], []);

  // functions
  const onSubmit = (body) => {
    (body?.id ? putCompany : postCompany)({
      body,
      successCallback: (data) => {
        sendQuery();
        onCloseModal({ isSave: true });
        setOpenTabs(prev => prev?.map(tab => (tab.id === data.id
          ? {
            id: data.id,
            title: data.name,
            data: { ...data },
          }
          : { ...tab })));
      },
    });
  };
  const onTabClose = (e, index) => {
    e?.stopPropagation();

    setOpenTabs((prevTabs) => {
      const updatedTabs = [...prevTabs];

      updatedTabs.splice(index, 1);

      setActiveTab((prevActiveTab) => {
        if (prevActiveTab === index) {
          return updatedTabs.length ? Math.min(index, updatedTabs.length - 1) : 0;
        }

        return prevActiveTab > index ? prevActiveTab - 1 : prevActiveTab;
      });

      return updatedTabs;
    });
  };
  const renderPanel = (tab) => {
    if (tab.id === 'table') {
      return (
        <Table
          limit={limit}
          total={companiesMeta.size}
          page={offset}
          onChangePage={setOffset}
          onChangeLimit={setLimit}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
          filteredColumns={filteredColumns}
          columnDefs={columns}
          rowData={companiesModified}
          onRowClicked={onRowClicked}
          sendQuery={sendQuery}
          isPending={isPendingGetCompanies
            || isPendingGetFlowsDictionary
            || isPendingGetPortalsDictionary
            || isPendingGetOwnLegalEntitiesDictionary
            || !lastUpdatedGetCompanies}
          tableRef={tableRef}
        />
      );
    }

    return (
      <CompanyViewTab
        openCompanyOnEdit={() => openCompanyOnEdit(tab.data)}
        updateCompanyData={onSubmit}
        isPending={isPendingPutCompany}
        {...tab.data}
      />
    );
  };

  useEffect(() => {
    getOwnLegalEntitiesDictionary();
    getLegalEntitiesDictionary();
    getAgreementsDictionary();
    getServicesDictionary();
    getFlowsDictionary();
    getPortalsDictionary();
  }, []);

  return (
    <div>
      <ScrollableTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabsList={openTabs}
        onTabClose={onTabClose}
        styles={{ wrapper: { flex: 1, overflow: 'hidden' } }}
        renderPanel={renderPanel}
        elementInTabsPanel={(
          <div className={styles.headerButtons}>
            <Button
              type="default"
              view="outlined"
              color="primary"
              icon="add-column-right"
              onClick={openCustomViewDrawer}
              text="CONTROLS.COLUMNS"
              dense
            />
            <Button
              type="default"
              color="primary"
              icon="plus"
              onClick={() => openModal('add')}
              text="CONTROLS.COMPANIES.ADD"
              disabled={!createAllowed}
              title={!createAllowed && 'TOOLTIPS.NOT_ENOUGH_PERMISSIONS'}
              dense
            />
          </div>
        )}
      />
      <CustomColumnsView
        initialValues={filteredColumns}
        columnsNames={COMPANIES}
        onSubmit={onSaveCustomView}
        onClose={closeCustomViewDrawer}
        isOpen={isOpenCustomViewDrawer}
      />
      {isOpenModal && (
        <Modal
          mode={isOpenModal}
          closeModal={onCloseModal}
          title="INSTANCES.COMPANY"
          editableRecord={editableRecord}
        >
          <CompanyForm
            mode={isOpenModal}
            onCancel={onCloseModal}
            initialValues={editableRecord}
            changeMode={openModal}
            onDelete={onDeleteClick}
            onSubmit={onSubmit}
            isFormPristine={isFormPristine}
            isPending={
              isPendingPostCompany
              || isPendingPutCompany
              || isPendingDeleteCompany
            }
            formValuesRef={formValuesRef}
          />
        </Modal>
      )}
      {isOpenConfirm && (
        <ConfirmModal
          isDelete={isOpenConfirm === 'delete'}
          closeModal={closeConfirm}
          onConfirm={
            isOpenConfirm === 'delete' ? onDeleteConfirm : onConfirmClose
          }
        />
      )}
    </div>
  );
};

export default CompaniesScreen;
