import endpoints from 'consts/endpoints';

export default ({
  limit = 100,
  offset = 0,
  saveToRedux = true,
  successCallback,
  ...queries
} = {}) => ({
  url: endpoints.getBundlePacksUrl(),
  body: {
    limit,
    offset: offset ? ((offset - 1) * limit) : offset,
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
  },
  transform: (response) => {
    const {
      data,
      meta,
    } = response;

    return {
      bundlePacksEnabled: data,
      bundlePacksEnabledMeta: meta,
    };
  },
  update: {
    bundlePacksEnabled: (oldValue, newValue) => (saveToRedux ? newValue : oldValue),
    bundlePacksEnabledMeta: (oldValue, newValue) => (saveToRedux ? newValue : oldValue),
  },
});
