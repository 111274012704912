import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getBundleInitiatorsEnabled as getBundleInitiatorsEnabledApi,
  getBundlePacksEnabled as getBundlePacksEnabledApi,
  postBundleInitiatorEnabled as postBundleInitiatorEnabledApi,
  postBundlePackEnabled as postBundlePackEnabledApi,
  patchBundleInitiatorEnabled as patchBundleInitiatorEnabledApi,
  patchBundlePackEnabled as patchBundlePackEnabledApi,
} from 'queries/bundlesEnabled';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetBundlePacksEnabled,
      lastUpdated: lastUpdatedGetBundlePacksEnabled,
    },
    getBundlePacksEnabled,
  ] = useMutation(getBundlePacksEnabledApi);

  const [
    {
      isPending: isPendingGetBundleInitiatorsEnabled,
      lastUpdated: lastUpdatedGetBundleInitiatorsEnabled,
    },
    getBundleInitiatorsEnabled,
  ] = useMutation(getBundleInitiatorsEnabledApi);

  const [
    {
      isPending: isPendingPostBundleInitiatorEnabled,
    },
    postBundleInitiatorEnabled,
  ] = useMutation(postBundleInitiatorEnabledApi);

  const [
    {
      isPending: isPendingPostBundlePackEnabled,
    },
    postBundlePackEnabled,
  ] = useMutation(postBundlePackEnabledApi);

  const [
    {
      isPending: isPendingPatchBundleInitiatorEnabled,
    },
    patchBundleInitiatorEnabled,
  ] = useMutation(patchBundleInitiatorEnabledApi);

  const [
    {
      isPending: isPendingPatchBundlePackEnabled,
    },
    patchBundlePackEnabled,
  ] = useMutation(patchBundlePackEnabledApi);

  const selector = useCallback(state => ({
    bundleInitiatorsEnabled: getArray(state, 'bundleInitiatorsEnabled'),
    bundlePacksEnabled: getArray(state, 'bundlePacksEnabled'),
    bundleInitiatorsEnabledMeta: getObject(state, 'bundleInitiatorsEnabledMeta'),
    bundlePacksEnabledMeta: getObject(state, 'bundlePacksEnabledMeta'),
  }), []);
  const data = useISESelector(selector);

  return {
    getBundleInitiatorsEnabled,
    getBundlePacksEnabled,
    postBundleInitiatorEnabled,
    postBundlePackEnabled,
    patchBundleInitiatorEnabled,
    patchBundlePackEnabled,
    isPendingGetBundleInitiatorsEnabled,
    isPendingGetBundlePacksEnabled,
    isPendingPostBundleInitiatorEnabled,
    isPendingPostBundlePackEnabled,
    isPendingPatchBundleInitiatorEnabled,
    isPendingPatchBundlePackEnabled,
    lastUpdatedGetBundleInitiatorsEnabled,
    lastUpdatedGetBundlePacksEnabled,
    ...data,
  };
};
