import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import moment from 'moment';
import { Tab } from '@blueprintjs/core';
import { groupBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Spinner from '@setproduct-ui/core/Spinner';
import Table from 'components/Table';
import TabsSegmented from '@setproduct-ui/core/Tabs/Segmented';
import {
  usePaymentsApi,
  useServicesEnabledApi,
  useInvoicesApi,
  useAgreementsApi,
  useCompaniesApi,
  usePacksApi,
} from 'hooks/api';
import { useTable } from 'hooks';
import { INVOICES, PAYMENTS, SERVICES_ENABLED } from 'consts/columns';

import AgreementsCardsList from './AgreementsCardsList';
import styles from './FinanceTab.module.scss';

const FinanceTab = ({
  companyId,
  legalEntityId,
  isActiveTab,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(null);
  const [agreementsList, setAgreementsList] = useState([]);
  const [invoicesList, setInvoicesList] = useState([]);
  const [paymentsList, setPaymentsList] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [selectedAgreement, setSelectedAgreement] = useState(null);
  const selectedAgreementRef = useRef(null);
  const [balancesDictionary, setBalancesDictionary] = useState({});
  const {
    getPayments,
    cancelGetPaymentsQuery,
    isPendingGetPayments,
    lastUpdatedGetPayments,
  } = usePaymentsApi();
  const {
    getServicesEnabled,
    cancelGetServicesEnabledQuery,
    isPendingGetServicesEnabled,
    lastUpdatedGetServicesEnabled,
  } = useServicesEnabledApi();
  const {
    getPacksEnabled,
    cancelGetPacksEnabledQuery,
    isPendingGetPacksEnabled,
    lastUpdatedGetPacksEnabled,
  } = usePacksApi();
  const {
    getInvoices,
    cancelGetInvoicesQuery,
    isPendingGetInvoices,
    lastUpdatedGetInvoices,
  } = useInvoicesApi();
  const {
    getAgreements,
    isPendingGetAgreements,
    lastUpdatedGetAgreements,
  } = useAgreementsApi();
  const {
    getBalancesByCompany,
  } = useCompaniesApi();
  const {
    onFilterChanged: onInvoicesFilterChanged,
    onSortChanged: onInvoicesSortChanged,
    sendQuery: getInvoicesList,
  } = useTable({
    sendQuery: ({ filter, sort }) => {
      const filterModify = {
        condition: 'AND',
        expressions: [
          {
            field: 'companyId',
            operator: 'eq',
            value: companyId,
          },
        ],
      };
      if (selectedAgreementRef.current) {
        filterModify.expressions.push({
          field: 'agreementId',
          operator: 'eq',
          value: selectedAgreementRef?.current,
        });
      }

      if (filter) {
        const parsedFilter = JSON.parse(filter);
        filterModify.expressions = [
          ...filterModify.expressions,
          ...(parsedFilter?.expressions || []),
        ];
      }
      getInvoices({
        offset: 0,
        limit: 1000,
        saveToRedux: false,
        sort: sort || '-periodStart',
        filter: JSON.stringify(filterModify),
        successCallback: ({ data }) => setInvoicesList(data),
      });
    },
    useNewFilters: true,
  });
  const {
    onFilterChanged: onPaymentsFilterChanged,
    onSortChanged: onPaymentsSortChanged,
    sendQuery: getPaymentsList,
  } = useTable({
    sendQuery: (params) => {
      getPayments({
        ...params,
        limit: 1000,
        saveToRedux: false,
        'companyId[of]': companyId,
        'agreementId[of]': selectedAgreementRef.current || undefined,
        successCallback: ({ resourceList }) => setPaymentsList(resourceList),
      });
    },
  });
  const {
    onFilterChanged: onServicesFilterChanged,
    onSortChanged: onServicesSortChanged,
    sendQuery: getServicesList,
  } = useTable({
    sendQuery: (params) => {
      getServicesEnabled({
        ...params,
        limit: 1000,
        saveToRedux: false,
        'agreementCompanyId[of]': companyId,
        'agreementId[of]': selectedAgreementRef.current || undefined,
        successCallback: ({ resourceList: services }) => {
          getPacksEnabled({
            ...params,
            limit: 1000,
            saveToRedux: false,
            'companyId[of]': companyId,
            'agreementId[of]': selectedAgreementRef.current || undefined,
            successCallback: ({ resourceList: packs }) => {
              setServicesList([
                ...(services || []),
                ...(packs || []),
              ]);
            },
          });
        },
      });
    },
  });

  const INVOICES_COLS = useMemo(
    () => INVOICES(Function.prototype)
      ?.filter(I => ['direction', 'referenceCode', 'status', 'dueAmount', 'currency', 'issueDate']?.includes(I.field)),
    [],
  );
  const PAYMENTS_COLS = useMemo(
    () => PAYMENTS
      ?.filter(I => ['direction', 'paymentCurrencyAmount', 'paymentCurrencyCode', 'isConfirmed', 'createdAt', 'issueDate']
        ?.includes(I.field)),
    [],
  );
  const SERVICES_COLS = useMemo(
    () => SERVICES_ENABLED
      ?.filter(I => ['serviceId', 'serviceType', 'effectiveFrom', 'effectiveTill']?.includes(I.field)),
    [],
  );

  const goToDetails = ({ id }, pathname) => {
    history.push({
      pathname,
      state: {
        tableFilters: {
          id: {
            values: {
              [id]: true,
            },
            type: 'list',
          },
        },
      },
    });
  };
  const goToPaymentDetails = ({ data: { id } }) => {
    history.push({
      pathname: '/payments',
      state: {
        tableFilters: {
          id: {
            values: {
              range: false,
              exclude: false,
              toValue: '',
              fromValue: id,
            },
            type: 'number',
            isWithoutEmpty: false,
          },
        },
      },
    });
  };
  const updatedSelectedAgreement = (newVal) => {
    setSelectedAgreement(newVal);
    selectedAgreementRef.current = newVal;
  };

  useEffect(() => {
    cancelGetPaymentsQuery();
    cancelGetServicesEnabledQuery();
    cancelGetPacksEnabledQuery();
    cancelGetInvoicesQuery();
    getInvoicesList();
    getPaymentsList();
    getServicesList();
  }, [companyId, selectedAgreement]);
  useEffect(() => {
    getAgreements({
      limit: 1000,
      saveToRedux: false,
      'companyId[of]': companyId,
      'legalEntityId[of]': legalEntityId,
      successCallback: ({ resourceList }) => {
        const grouped = groupBy(resourceList, (entity) => {
          const now = moment();
          const effectiveFrom = moment(entity.actualEffectiveFromAbs);
          const effectiveTill = entity.actualEffectiveTillAbs
            ? moment(entity.actualEffectiveTillAbs)
            : moment().set({ day: 31, month: 11, year: 2999 });

          if (now.isBetween(effectiveFrom, effectiveTill, null, '[)')) {
            return 'current';
          } if (now.isBefore(effectiveFrom)) {
            return 'upcoming';
          }
          return 'expired';
        });
        const sortedList = [
          ...(grouped.current || []),
          ...(grouped.upcoming || []),
          ...(grouped.expired || []),
        ];

        updatedSelectedAgreement(sortedList[0]?.id);
        setAgreementsList(sortedList);
      },
    });
  }, [companyId, legalEntityId]);
  useEffect(() => {
    getBalancesByCompany({
      id: companyId,
      successCallback: ({ data }) => setBalancesDictionary(data.reduce((acc, item) => {
        if (!item.isPocket) {
          acc[item.agreementId] = item.balance - item.totalFreeze;
        }

        return acc;
      }, {})),
    });
  }, [companyId]);
  useEffect(() => {
    if (isActiveTab && !activeTab) {
      setActiveTab('invoices');
    }
  }, [isActiveTab]);

  return (
    <div className={styles.container}>
      {(isPendingGetAgreements || !lastUpdatedGetAgreements) ? (
        <Spinner className={styles.cardsSpinner} />
      ) : (
        <AgreementsCardsList
          agreementsList={agreementsList}
          isVisible={isActiveTab}
          selectedCard={selectedAgreement}
          setSelectedCard={updatedSelectedAgreement}
          balancesDictionary={balancesDictionary}
        />
      )}
      <div className={styles.recordsBlock}>
        <div className={styles.financeOperationsWrapper}>
          <div className={styles.tableHeader}>
            <div className={styles.tableTitle}>
              <span>{t('SCREENS.COMPANIES.FINANCE_OPERATIONS')}</span>
              <span>{t('SCREENS.COMPANIES.RECENT_FINANCE_OPERATIONS')}</span>
            </div>
            <TabsSegmented
              type="dense"
              color="default"
              id="launchTabs"
              selectedTabId={activeTab}
              onChange={setActiveTab}
            >
              <Tab id="invoices" title={t('SCREENS.COMPANIES.INVOICES')} />
              <Tab id="payments" title={t('SCREENS.COMPANIES.PAYMENTS')} />
            </TabsSegmented>
          </div>
          {activeTab === 'invoices' ? (
            <Table
              columnDefs={INVOICES_COLS}
              rowData={invoicesList}
              onRowClicked={({ data }) => goToDetails(data, '/invoices')}
              isPending={isPendingGetInvoices || !lastUpdatedGetInvoices}
              withPagination={false}
              rowHeight={60}
              denseRowHeight={60}
              headerHeight={28}
              onFilterChanged={onInvoicesFilterChanged}
              onSortChanged={onInvoicesSortChanged}
              sendQuery={getInvoicesList}
            />
          ) : (
            <Table
              columnDefs={PAYMENTS_COLS}
              rowData={paymentsList}
              onRowClicked={goToPaymentDetails}
              isPending={isPendingGetPayments || !lastUpdatedGetPayments}
              withPagination={false}
              rowHeight={60}
              denseRowHeight={60}
              headerHeight={28}
              onFilterChanged={onPaymentsFilterChanged}
              onSortChanged={onPaymentsSortChanged}
              sendQuery={getPaymentsList}
            />
          )}
        </div>
        <div className={styles.servicesWrapper}>
          <div className={styles.tableHeader}>
            <div className={styles.tableTitle}>
              <span>{t('SCREENS.COMPANIES.SERVICES')}</span>
              <span>{t('SCREENS.COMPANIES.RECENT_SERVICES')}</span>
            </div>
          </div>
          <Table
            columnDefs={SERVICES_COLS}
            rowData={servicesList}
            onRowClicked={({ data }) =>
              goToDetails(data, `/services-enabled/${data?.serviceType === 1 ? 'services' : 'packs'}`)}
            isPending={isPendingGetServicesEnabled
              || !lastUpdatedGetServicesEnabled
              || isPendingGetPacksEnabled
              || !lastUpdatedGetPacksEnabled}
            withPagination={false}
            rowHeight={60}
            denseRowHeight={60}
            headerHeight={28}
            onFilterChanged={onServicesFilterChanged}
            onSortChanged={onServicesSortChanged}
            sendQuery={getServicesList}
          />
        </div>
      </div>
    </div>
  );
};

export default FinanceTab;
