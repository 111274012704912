import React, { useMemo, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { isEqual, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  TextField,
  DropdownField,
} from 'components/fields';
import { OWN_BANK_ACCOUNT_SCHEMA } from 'consts/validationSchemas';
import IsFormChanged from 'components/forms/IsFormChanged';
import { FormButtonsGroup } from 'components/blocks';
import { useDictionariesApi } from 'hooks/api';
import { useContentShadow } from 'hooks';

import styles from './OwnBankAccountForm.module.scss';

const OwnBankAccountForm = ({
  initialValues = {},
  onSubmit,
  onCancel,
  onDelete,
  mode,
  changeMode,
  isPending,
  isFormPristine,
  formValuesRef,
}) => {
  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);
  const contentRef = useRef(null);
  const {
    showTopShadow,
    showBottomShadow,
  } = useContentShadow(contentRef);
  const {
    ownLegalEntitiesOptions,
    parentBanksOptions,
  } = useDictionariesApi();

  const isView = useMemo(() => mode === 'view', [mode]);

  const changeToEdit = () => changeMode('edit');
  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }

    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={OWN_BANK_ACCOUNT_SCHEMA}
      validateOnMount
    >
      {({
        handleSubmit, errors, setFieldTouched,
      }) => (
        <Form className={styles.container}>
          {showTopShadow && <div className={styles.shadow} />}
          <div className={styles.content} ref={contentRef}>
            <Field
              name="name"
              label="INSTANCES.NAME"
              component={TextField}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              disabled={isView}
              isRequired={!isView}
              fill
            />
            <Field
              name="legalEntityId"
              label="INSTANCES.LEGAL_ENTITY"
              component={DropdownField}
              options={ownLegalEntitiesOptions}
              placeholder="PLACEHOLDERS.SELECT"
              disabled={isView}
              isRequired={!isView}
              fill
            />
            <Field
              name="bankId"
              label="INSTANCES.OWN_BANK_ACCOUNTS.PARENT_BANK"
              component={DropdownField}
              options={parentBanksOptions}
              placeholder="PLACEHOLDERS.SELECT"
              disabled={isView}
              isRequired={!isView}
              fill
            />
            <Field
              name="accountHolder"
              label="INSTANCES.ACCOUNT_HOLDER"
              component={TextField}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              disabled={isView}
              isRequired={!isView}
              fill
            />
            <Field
              name="accountNumber"
              label="INSTANCES.ACCOUNT_NUMBER"
              component={TextField}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              disabled={isView}
              isRequired={!isView}
              fill
            />
          </div>
          <FormButtonsGroup
            onDelete={onDelete}
            onCancel={onCancel}
            setFieldTouched={setFieldTouched}
            errors={errors}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            isPending={isPending}
            showShadow={showBottomShadow}
          />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
        </Form>
      )}
    </Formik>
  );
};

export default OwnBankAccountForm;
