import {
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useFilteredColumns, useModalLogic, useTable } from './index';

export default ({
  screenRef,
  deleteFunc,
  getFunc = () => {},
  onRowClickCallback,
  initialFilteredColumns,
  useNewFilters,
  additionalKeysToSave = {},
}) => {
  const tableRef = useRef(null);
  const formValuesRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { activeTab } = useSelector(state => state.states.routes);
  const [isOpenModal, { openModal, closeModal }] = useModalLogic('');
  const [
    isOpenConfirm,
    { openModal: openConfirm, closeModal: closeConfirm },
  ] = useModalLogic('');
  const isFormPristine = useRef(true);
  const [editableRecord, setEditableRecord] = useState();

  const {
    isOpenCustomViewDrawer,
    closeCustomViewDrawer,
    openCustomViewDrawer,
    onSaveCustomView,
    filteredColumns,
  } = useFilteredColumns({ initialValues: initialFilteredColumns });
  const {
    sendQuery,
    setAndSendLimit,
    setAndSendOffset,
    limit,
    offset,
    onSortChanged,
    onFilterChanged,
    setFilters,
    filters,
    setLimit,
    setOffset,
    sort,
    sortRef,
    resetPagination,
  } = useTable({
    sendQuery: getFunc,
    useNewFilters,
    tableRef,
    withInitialQuery: false,
    // В случае проблем с инициализирующим запросов в сохраненных экранах
    // обратить внимание на этот параметр
    // withInitialQuery: !history.location.state,
  });

  const onCloseModal = ({ isSave }) => {
    if (isFormPristine.current || isSave) {
      setEditableRecord();
      closeModal();
    } else {
      openConfirm('confirm');
    }
  };
  const onConfirmClose = () => {
    setEditableRecord();
    closeConfirm();
    closeModal();
    if (formValuesRef.current) {
      formValuesRef.current = null;
    }
    if (activeTab) {
      dispatch({ type: 'saveRoute', formValues: undefined });
    }
  };
  const onDeleteClick = () => {
    openConfirm('delete');
  };
  const onDeleteConfirm = () => {
    closeConfirm();
    deleteFunc({
      id: editableRecord.id,
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
      errorCallback: () => onCloseModal({ isSave: true }),
    });
  };
  useImperativeHandle(screenRef, () => ({
    saveState() {
      const { id, name } = (formValuesRef.current || {});

      dispatch({
        type: 'saveRoute',
        tableFilters: filters,
        modalMode: isOpenModal,
        formValues: formValuesRef.current || editableRecord,
        tabKey: name || (id ? `ID: ${id}` : undefined),
        limitValue: limit,
        offsetValue: offset,
        tableSort: sort,
        ...additionalKeysToSave,
      });
    },
  }));
  const onRowClicked = ({ data }) => {
    setEditableRecord(onRowClickCallback ? { ...onRowClickCallback(data) } : { ...data });
    openModal('view');
  };

  useEffect(() => {
    if (activeTab) {
      dispatch({
        type: 'saveRoute',
        modalMode: isOpenModal,
      });
    }
  }, [isOpenModal]);
  useEffect(() => {
    if (activeTab) {
      dispatch({
        type: 'saveRoute',
        tableFilters: filters,
      });
    }
  }, [filters]);
  useEffect(() => {
    if (activeTab) {
      dispatch({
        type: 'saveRoute',
        limitValue: limit,
      });
    }
  }, [limit]);
  useEffect(() => {
    if (activeTab) {
      dispatch({
        type: 'saveRoute',
        offsetValue: offset,
      });
    }
  }, [offset]);
  useEffect(() => {
    if (activeTab) {
      dispatch({
        type: 'saveRoute',
        tableSort: sort,
      });
    }
  }, [sort]);
  useEffect(() => {
    if (history.location.state) {
      const {
        tableFilters,
        formValues,
        modalMode,
        limitValue = 100,
        offsetValue = 1,
        tableSort,
      } = history.location.state;

      if (tableSort) {
        sortRef.current = tableSort;
        tableRef.current.setSort(tableSort.name, tableSort.type);
      } else if (sort) {
        sortRef.current = null;
        tableRef.current.setSort();
      }
      if (tableFilters && tableRef) {
        setFilters(tableFilters);
        tableRef.current?.setFilters(tableFilters);
      } else if (filters) {
        setFilters(null);
        tableRef.current.setFilters({});
      }
      if (formValues) {
        onRowClicked({ data: formValues });
      } else if (editableRecord) {
        setEditableRecord();
      }
      setLimit(limitValue);
      setOffset(offsetValue);
      setTimeout(() => sendQuery({ stateOffset: offsetValue, stateLimit: limitValue }));
      openModal(modalMode);
    } else {
      if (filters) {
        tableRef.current?.setFilters(null);
      }
      if (sort) {
        sortRef.current = null;
        tableRef.current.setSort();
      }
      setOffset(1);
      setLimit(100);
      formValuesRef.current = null;
      setEditableRecord();
      closeConfirm();
      closeModal();
      setTimeout(() => sendQuery({ stateLimit: 100, stateOffset: 1 }));
    }
  }, [history.length, history.location.state]);

  return {
    setEditableRecord,
    openModal,
    editableRecord,
    sendQuery,
    onCloseModal,
    closeModal,
    limit,
    offset,
    setOffset: setAndSendOffset,
    setLimit: setAndSendLimit,
    onSortChanged,
    onFilterChanged,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    onDeleteClick,
    isFormPristine,
    formValuesRef,
    isOpenConfirm,
    closeConfirm,
    onDeleteConfirm,
    onConfirmClose,
    isOpenModal,
    onRowClicked,
    openConfirm,
    resetPagination,
  };
};
