import React, { useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Icon } from '@blueprintjs/core';

import Button from '@setproduct-ui/core/Button';
import Callout from '@setproduct-ui/core/Callout';
import {
  CheckboxField,
  DropdownField,
  TextAreaField,
  TextField,
} from 'components/fields';
import { TWO_FACTOR_TYPES_OPTIONS } from 'consts';
import { numbers } from 'consts/inputPatterns';
import { INTEGRATION_SCHEMA } from 'consts/validationSchemas';
import toastRef from 'helpers/toast';
import getParams from 'helpers/getParams';
import OnChangeComponent from 'components/forms/OnChangeComponent';
import { useUserPermissions } from 'hooks';
import {
  useDictionariesApi, useIntegrationsApi, useUsersApi,
} from 'hooks/api';

import styles from './IntegrationForm.module.scss';

const CODE_LENGTH_OPTIONS = [
  {
    value: 6,
    label: '6',
  },
];

const IntegrationForm = ({
  initialValues = {
    integrationType: '2fa-sms-send',
  },
  onCancel,
  isEdit,
  onDelete,
  onSubmit,
  mode,
  changeMode,
  sendersOptions,
  isPending,
}) => {
  const dropdownStyles = {
    container: {
      marginTop: 16,
      width: 403,
    },
  };
  const fieldStyles = (marginTop, width) => ({
    label: {
      marginTop,
    },
    container: {
      width,
    },
  });

  const { t } = useTranslation();
  const formRef = useRef(null);
  const [sendersOptions2, setSendersOptions] = useState(sendersOptions);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [testingCode, setTestingCode] = useState(null);
  const { editAllowed, deleteAllowed, enableAllowed } = useUserPermissions();
  const [testingCallbackUrl, setTestingCallbackUrl] = useState(null);
  const {
    integrationsUrls,
    postGenerateApiKey,
    isPendingPostGenerateApiKey,
  } = useIntegrationsApi();
  const { companiesOptionsWithOwn } = useDictionariesApi();
  const { currentUser } = useUsersApi();
  const checkFormValidation = (callback) => {
    if (formRef.current) {
      const { errors, setFieldTouched } = formRef.current;

      if (!errors || isEmpty(errors)) {
        callback();
      }

      Object.keys(errors)?.map(i => setFieldTouched(i, true));
    }
  };

  const onChangeCompanyId = ({ form, value }) => {
    setSendersOptions([...sendersOptions.filter(sender =>
      sender.companyId === value)]);

    if (form.dirty) {
      form.setFieldValue('senderId', undefined);
    }
  };
  const getBody = () => {
    const { values } = formRef.current;
    const { integrationType, ...restValues } = values;
    let body = restValues;

    if (integrationType === '2fa-sms-verify') {
      const { codeSize, codeTimeout, ...rest } = restValues;

      body = {
        ...rest,
        senderConfig: {
          codeSize,
          codeTimeout: +codeTimeout,
        },
      };
    }

    return body || {};
  };
  const onEdit = () => changeMode('edit');
  const onGenerateAndUpdateApiKey = () => {
    if (mode === 'view') {
      return;
    }
    postGenerateApiKey({
      successCallback: (res) => {
        formRef.current?.setFieldValue('apiKey', res.apiKey);
      },
    });
  };
  const sendTestMessage = () => {
    const params = {
      number: phoneNumber,
      language: 'ru',
      callbackUrl: testingCallbackUrl,
    };

    const { integrationType, apiKey } = formRef.current?.values || {};
    if (integrationType === '2fa-sms-send') {
      params.code = testingCode;
    }

    const integrationUrl = integrationsUrls[integrationType];

    fetch(`${integrationUrl}${getParams(params)}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${btoa(apiKey)}`,
      },
    })
      .then(response => response.json())
      .then((data) => {
        if (data.errorCode) {
          toastRef.current.showMessage({ message: data?.key || data.errorReason });
        } else {
          toastRef.current.showMessage({ message: 'Success!', intent: 'success' });
        }
      })
      .catch(({ message, key }) => {
        toastRef.current.showMessage({ message: t(key) || message });
      });
  };
  const onSubmitHandler = (status) => {
    checkFormValidation(() => {
      const body = {
        ...getBody(),
        ...(status && { status }),
        id: initialValues?.id,
      };

      onSubmit({
        body,
        integrationType: formRef?.current?.values?.integrationType,
      });
    });
  };
  const getMaskedUrl = (url) => {
    const match = url.match(/^(https?:\/\/)[^/]+(\/.*)?$/);
    return match ? `${match[1]}<host>${match[2] || ''}` : url;
  };

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formRef}
      validationSchema={INTEGRATION_SCHEMA}
      validateOnMount
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
      }) => (
        <Form className={styles.form}>
          <div className={styles.content}>
            <span className={styles.titleBold}>
              {t('SCREENS.TWO_FACTOR.CODE_GENERATION_AND_CHECK')}
            </span>
            <Field
              name="integrationType"
              component={DropdownField}
              options={TWO_FACTOR_TYPES_OPTIONS}
              styles={dropdownStyles}
              disabled={initialValues?.id}
            />
            <span className={styles.title}>
              {t('SCREENS.TWO_FACTOR.GENERAL_INFO')}
            </span>
            <Field
              name="name"
              component={TextField}
              label="INSTANCES.NAME"
              maxLength={values?.integrationType === '2fa-sms-send' ? 30 : 110}
              disabled={mode === 'view'}
              isRequired
              full
            />
            <span className={styles.hint} style={{ opacity: errors?.name && touched.name ? 0 : 1 }}>
              {t('SCREENS.TWO_FACTOR.NAME_HINT')}
            </span>
            <div className={styles.row}>
              <Field
                name="companyId"
                label="INSTANCES.COMPANY"
                component={DropdownField}
                options={companiesOptionsWithOwn}
                styles={fieldStyles(0, '100%')}
                placeholder="PLACEHOLDERS.SELECT"
                disabled={initialValues?.id}
                isRequired
              />
              <Field
                name="senderId"
                component={DropdownField}
                label="INSTANCES.TWO_FACTOR.SMS_SENDER_ID"
                options={sendersOptions2}
                styles={fieldStyles(0, '100%')}
                disabled={!values.companyId || mode === 'view'}
                isRequired
              />
            </div>
            {values?.integrationType === '2fa-sms-verify' && (
            <>
              <div className={styles.row}>
                <Field
                  name="codeSize"
                  component={DropdownField}
                  label="INSTANCES.TWO_FACTOR.CODE_LENGTH"
                  options={CODE_LENGTH_OPTIONS}
                  styles={fieldStyles(0, '100%')}
                  isRequired
                  disabled={mode === 'view'}
                />
                <Field
                  name="codeTimeout"
                  component={TextField}
                  label="INSTANCES.TWO_FACTOR.VALID_TIME"
                  regex={numbers({ maxLength: 3 })}
                  full
                  isRequired
                  disabled={mode === 'view'}
                />
              </div>
              <Field
                name="webhookUrl"
                component={TextField}
                label="INSTANCES.TWO_FACTOR.VERIFY_URL"
                styles={fieldStyles(16)}
                full
                disabled={mode === 'view'}
              />
            </>
            )}
            <Field
              name="text"
              component={TextAreaField}
              label="INSTANCES.TWO_FACTOR.MESSAGE_TEXT"
              styles={fieldStyles(16)}
              maxLength={170}
              minRows={2}
              isRequired
              disabled={mode === 'view'}
            />
            <span className={styles.hint} style={{ opacity: errors?.text && touched.text ? 0 : 1, maxWidth: 783 }}>
              {t('SCREENS.TWO_FACTOR.TEXT_HINT')}
            </span>
            <span className={styles.title}>
              {t('SCREENS.TWO_FACTOR.INTEGRATION_DATA')}
            </span>
            <Field
              name="apiKey"
              component={TextField}
              label="INSTANCES.TWO_FACTOR.API_KEY"
              rightElement={!isPendingPostGenerateApiKey && (
                <Icon
                  icon="new-link"
                  size={20}
                  color="var(--blue70)"
                  className={styles.apiKey}
                  htmlTitle={t(`TOOLTIPS.TWO_FACTOR.${values?.apiKey ? 'UPDATE_API_KEY' : 'GENERATE_API_KEY'}`)}
                  onClick={onGenerateAndUpdateApiKey}
                />
              )}
              showErrorIcon={false}
              disabled={mode === 'view'}
              isRequired
              readOnly
              full
            />
            <div className={styles.row}>
              <TextField
                value={getMaskedUrl(integrationsUrls[values?.integrationType])}
                onChange={Function.prototype}
                label="INSTANCES.TWO_FACTOR.INTEGRATION_URL"
                styles={fieldStyles(16)}
                disabled={mode === 'view'}
                clearButton={false}
                readOnly
                full
              />
              {currentUser.companyId === values.companyId && (
                <Field
                  name="isDefault2FA"
                  component={CheckboxField}
                  text="INSTANCES.TWO_FACTOR.IS_DEFAULT"
                  styles={{ container: { marginTop: 35 } }}
                  disabled={mode === 'view'}
                />
              )}
            </div>
            {!isEdit ? (
              <Callout
                view="outlined"
                title={t('SCREENS.TWO_FACTOR.TESTING')}
                icon={<Icon icon="info-sign" size={20} />}
                color="success"
                className={styles.callout}
                dense
              >
                {t('SCREENS.TWO_FACTOR.TESTING_POSSIBILITY')}
              </Callout>
            ) : (
              <>
                <span className={styles.title}>
                  {t('SCREENS.TWO_FACTOR.TESTING')}
                </span>
                <div className={styles.row}>
                  <TextField
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    label="INSTANCES.PHONE_NUMBER"
                    full
                    isRequired
                  />
                  <div style={{ width: '100%' }}>
                    {values?.integrationType === '2fa-sms-send' && (
                      <TextField
                        value={testingCode}
                        onChange={setTestingCode}
                        label="INSTANCES.TWO_FACTOR.CODE"
                        isRequired
                        full
                        styles={{ container: { marginBottom: 10 } }}
                      />
                    )}
                    <TextField
                      value={testingCallbackUrl}
                      onChange={setTestingCallbackUrl}
                      label="INSTANCES.TWO_FACTOR.CALLBACK_URL"
                      full
                    />
                  </div>
                </div>
                <Button
                  text="CONTROLS.TWO_FACTOR.SEND_CODE"
                  onClick={sendTestMessage}
                  color="success"
                  view="smooth"
                  icon="send-message"
                  iconSize={16}
                  className={styles.button}
                  disabled={!phoneNumber || (values?.integrationType === '2fa-sms-send' && !testingCode)}
                />
              </>
            )}
          </div>
          <div className={styles.footer}>
            <Button
              text="CONTROLS.CANCEL"
              view="outlined"
              onClick={onCancel}
              disabled={isPending}
            />
            {mode === 'view' && (
              <Button
                text="CONTROLS.EDIT"
                view="outlined"
                className="hueta"
                onClick={onEdit}
              />
            )}
            {(mode === 'edit' || mode === 'add')
              && (isEdit ? (
                <>
                  <Button
                    text="CONTROLS.DELETE"
                    view="filled"
                    color="danger"
                    onClick={onDelete}
                    icon="trash"
                    disabled={!deleteAllowed}
                    title={!deleteAllowed && 'TOOLTIPS.NOT_ENOUGH_PERMISSIONS'}
                    loading={isPending}
                  />
                  <Button
                    text={
                      initialValues.status === 3
                        ? 'CONTROLS.TWO_FACTOR.DISABLE'
                        : 'CONTROLS.TWO_FACTOR.ENABLE'
                    }
                    view="filled"
                    color={initialValues.status === 3 ? 'default' : 'success'}
                    onClick={() => onSubmitHandler(initialValues.status === 3 ? 1 : 3)}
                    icon={initialValues.status === 3 ? 'stop' : 'power'}
                    disabled={!enableAllowed}
                    title={!enableAllowed && 'TOOLTIPS.NOT_ENOUGH_PERMISSIONS'}
                    loading={isPending}
                  />
                  <Button
                    text="CONTROLS.SAVE"
                    view="filled"
                    color="primary"
                    onClick={() => onSubmitHandler()}
                    icon="saved"
                    disabled={!editAllowed}
                    title={!editAllowed && 'TOOLTIPS.NOT_ENOUGH_PERMISSIONS'}
                    loading={isPending}
                  />
                </>
              ) : (
                <>
                  <Button
                    text="CONTROLS.TWO_FACTOR.SAVE_DRAFT"
                    view="filled"
                    color="success"
                    onClick={() => onSubmitHandler(2)}
                    icon="document-open"
                    disabled={isPending}
                    loading={isPending}
                  />
                  <Button
                    text="CONTROLS.TWO_FACTOR.SAVE"
                    view="filled"
                    color="primary"
                    onClick={() => onSubmitHandler(3)}
                    icon="document-share"
                    disabled={isPending}
                    loading={isPending}
                  />
                </>
              ))}
          </div>
          <OnChangeComponent field="companyId" onChange={onChangeCompanyId} />
        </Form>
      )}
    </Formik>
  );
};

export default IntegrationForm;
