import endpoints from 'consts/endpoints';

export default ({
  limit = 100,
  offset = 0,
  saveToRedux = true,
  successCallback,
  ...queries
} = {}) => ({
  url: endpoints.getBundleInitiatorsUrl(),
  body: {
    limit,
    offset: offset ? ((offset - 1) * limit) : offset,
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
  },
  transform: (response) => {
    const {
      data,
      meta,
    } = response;

    return {
      bundleInitiatorsEnabled: data,
      bundleInitiatorsEnabledMeta: meta,
    };
  },
  update: {
    bundleInitiatorsEnabled: (oldValue, newValue) => (saveToRedux ? newValue : oldValue),
    bundleInitiatorsEnabledMeta: (oldValue, newValue) => (saveToRedux ? newValue : oldValue),
  },
});
