import React, { useMemo } from 'react';
import { Field } from 'formik';
import { useDispatch } from 'react-redux';

import { PAYG_PRICE_TYPES_OPTIONS, UNIT_MONETARY_PRICE_TYPES_OPTIONS } from 'consts';

import Dropdown from '../Dropdown';

const PriceTypeCell = ({
  dependsOptions,
  disabled,
  isEdit,
  dependsOnDisabled,
  importFromFileDisabled,
  title,
  serviceType,
  serviceCurrency,
  data,
  parentInfoRules,
  values,
  level,
}) => {
  const fieldStyle = {
    width: 200,
  };
  const wrapperStyle = {
    cursor: isEdit ? undefined : 'initial',
  };

  const dispatch = useDispatch();

  const typesOptions = useMemo(() => {
    if (serviceType === 1) {
      return PAYG_PRICE_TYPES_OPTIONS;
    }
    if (serviceType === 2 || serviceType === 3 || serviceType === 6 || serviceType === 7) {
      return UNIT_MONETARY_PRICE_TYPES_OPTIONS;
    }

    return [];
  }, [serviceType]);

  const startImportFromFile = () => {
    dispatch({
      type: 'startRateImport',
      sourceNode: data,
      dependsOptions,
      serviceType,
      serviceCurrency,
      parentInfoRules: level === 2 ? {
        resourceId: values.matchAttributeValues,
        billingRule: values.billingRule,
        quantityRule: values.quantityRule,
      } : parentInfoRules,
    });
  };

  return (
    <div title={title}>
      <Field
        name="priceTypeCell"
        component={Dropdown}
        textInputStyles={fieldStyle}
        options={dependsOptions}
        placeholder="PLACEHOLDERS.SERVICES.SELECT_PRICE_TYPE"
        disabled={disabled}
        dependsOnDisabled={dependsOnDisabled}
        importFromFileDisabled={importFromFileDisabled}
        wrapperStyle={wrapperStyle}
        typeOptions={typesOptions}
        onImportFromFileClick={startImportFromFile}
        isPriceType
      />
    </div>
  );
};

export default PriceTypeCell;
