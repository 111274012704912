import React, { useState } from 'react';
import cx from 'classnames';
import { Icon } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { useTranslation } from 'react-i18next';

import Button from '@setproduct-ui/core/Button';

import styles from './MenuDropdown.module.scss';

const MenuDropdown = ({
  options,
  buttonTitle,
  loading,
}) => {
  const { t } = useTranslation();
  const [isPopoverShow, setIsPopoverShow] = useState(false);

  return (
    <Popover2
      placement="bottom-start"
      interactionKind="click"
      isOpen={isPopoverShow}
      onInteraction={setIsPopoverShow}
      transitionDuration={0}
      minimal
      content={(
        <div className={styles.overlay}>
          {options.map((I, index) => (
            <div
              role="presentation"
              className={cx(styles.menuItem, {
                [styles.menuItem_disabled]: I.disabled,
              })}
              onClick={(e) => {
                if (!I.disabled) {
                  I.onClick();
                  setIsPopoverShow(false);
                } else {
                  e.stopPropagation();
                }
              }}
              title={t(I.title)}
              key={I.label?.key || I.label}
              data-testid={`${window.location.pathname}/table/menu-item/${index}`}
            >
              <Icon
                icon={I.icon}
                color={I.iconColor}
                size={I.iconSize || 20}
                className={styles.itemIcon}
              />
              <span>
                {typeof I.label === 'object' ? t(I.label.key, { ...I.label.values }) : t(I.label)}
              </span>
            </div>
          ))}
        </div>
      )}
    >
      <Button
        type="square"
        view="outlined"
        icon="more"
        loading={loading}
        title={buttonTitle}
        data-testid={`${window.location.pathname}/table/open-menu-button`}
      />
    </Popover2>
  );
};

export default MenuDropdown;
