import React, {
  useMemo,
  useRef,
} from 'react';
import { Field, Form, Formik } from 'formik';
import { isEmpty, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { CheckboxField, DropdownField } from 'components/fields';
import { FormButtonsGroup } from 'components/blocks';
import { BUNDLE_PACK_ENABLED_SCHEMA } from 'consts/validationSchemas';
import IsFormChanged from 'components/forms/IsFormChanged';

import styles from './BundlePackEnabledForm.module.scss';

const BundlePackEnabledForm = ({
  changeMode,
  mode,
  onSubmit,
  initialValues = {},
  onDelete,
  onCancel,
  isPending,
  isFormPristine,
  formValuesRef,
  bundlePacksOptions,
  agreementsOptions,
}) => {
  // hooks
  const formRef = useRef();
  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state?.states.routes);
  // memed vars
  const isView = useMemo(() => mode === 'view', [mode]);
  const isEdit = useMemo(() => mode === 'edit', [mode]);
  const isAutoExtendDisabled = useMemo(
    () =>
      (!moment().isBetween(initialValues.effectiveFrom, initialValues.effectiveTill) && !initialValues.autoExtend)
      || mode === 'view',
    [initialValues, mode],
  );

  // functions
  const changeToEdit = () => changeMode('edit');

  const onSubmitModify = ({
    effectiveFrom, effectiveTill, ...values
  }) => {
    onSubmit(values);
  };
  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }

    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={onSubmitModify}
      validationSchema={BUNDLE_PACK_ENABLED_SCHEMA}
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
      }) => (
        <Form className={styles.container}>
          <div className={styles.content}>
            <Field
              name="serviceId"
              component={DropdownField}
              label="INSTANCES.PACKS_ENABLED.SERVICE_PACK"
              options={bundlePacksOptions}
              disabled={isView || isEdit}
              isRequired={!isView}
              placeholder="PLACEHOLDERS.SELECT"
            />
            <Field
              name="agreementId"
              component={DropdownField}
              label="INSTANCES.AGREEMENT"
              options={agreementsOptions}
              disabled={isView || isEdit}
              isRequired={!isView}
              placeholder="PLACEHOLDERS.SELECT"
            />
            {(isEdit || isView) && (
              <Field
                name="autoExtend"
                component={CheckboxField}
                text="INSTANCES.SERVICES_ENABLED.AUTO_EXTEND"
                disabled={isAutoExtendDisabled}
              />
            )}
          </div>
          <FormButtonsGroup
            onDelete={onDelete}
            onCancel={onCancel}
            setFieldTouched={setFieldTouched}
            errors={errors}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            isPending={isPending}
            showDelete={false}
          />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
        </Form>
      )}
    </Formik>
  );
};

export default BundlePackEnabledForm;
