import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getBankAccounts as getBankAccountsApi,
  getOwnBankAccounts as getOwnBankAccountsApi,
  postBankAccount as postBankAccountApi,
  putBankAccount as putBankAccountApi,
  deleteBankAccount as deleteBankAccountApi,
} from 'queries/bankAccounts';

import {
  getObject,
  getArray,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetBankAccounts,
      lastUpdated: lastUpdatedGetBankAccounts,
    },
    getBankAccounts,
  ] = useMutation(getBankAccountsApi);
  const [
    {
      isPending: isPendingGetOwnBankAccounts,
      lastUpdated: lastUpdatedOwnGetBankAccounts,
    },
    getOwnBankAccounts,
  ] = useMutation(getOwnBankAccountsApi);
  const [{ isPending: isPendingPostBankAccount }, postBankAccount] = useMutation(postBankAccountApi);
  const [{ isPending: isPendingPutBankAccount }, putBankAccount] = useMutation(putBankAccountApi);
  const [{ isPending: isPendingDeleteBankAccount }, deleteBankAccount] = useMutation(deleteBankAccountApi);

  const selector = useCallback(state => ({
    bankAccounts: getArray(state, 'bankAccounts'),
    bankAccountsMeta: getObject(state, 'bankAccountsMeta'),
    ownBankAccounts: getArray(state, 'ownBankAccounts'),
    ownBankAccountsMeta: getObject(state, 'ownBankAccountsMeta'),
  }), []);
  const data = useISESelector(selector);

  return {
    getBankAccounts,
    postBankAccount,
    putBankAccount,
    deleteBankAccount,
    getOwnBankAccounts,
    isPendingGetBankAccounts,
    isPendingPostBankAccount,
    isPendingPutBankAccount,
    isPendingDeleteBankAccount,
    isPendingGetOwnBankAccounts,
    lastUpdatedGetBankAccounts,
    lastUpdatedOwnGetBankAccounts,
    ...data,
  };
};
