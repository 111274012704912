import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  postAssignDidSender as postAssignDidSenderApi,
  getDidSenders as getDidSendersApi,
  deleteDidSenderActiveVersion as deleteDidSenderActiveVersionApi,
  putReAssignDidSender as putReAssignDidSenderApi,
  getDidSenderById as getDidSenderByIdApi,
  putDidSender as putDidSenderApi,
  putDeAssignDidSender as putDeAssignDidSenderApi,
  getDidSenderHistory as getDidSenderHistoryApi,
  postDidSender as postDidSenderApi,
  deleteDidSender as deleteDidSenderApi,
} from 'queries/didSenders';

import {
  getArray,
  getObject, useISESelector,
} from './selectors';

export default () => {
  const [
    { isPending: isPendingPostAssignDidSender },
    postAssignDidSender,
  ] = useMutation(postAssignDidSenderApi);

  const [
    { isPending: isPendingGetDidSenders },
    getDidSenders,
  ] = useMutation(getDidSendersApi);

  const [
    { isPending: isPendingDeleteDidSenderActiveVersion },
    deleteDidSenderActiveVersion,
  ] = useMutation(deleteDidSenderActiveVersionApi);

  const [
    { isPending: isPendingPutReAssignDidSender },
    putReAssignDidSender,
  ] = useMutation(putReAssignDidSenderApi);

  const [
    { isPending: isPendingGetDidSenderById },
    getDidSenderById,
  ] = useMutation(getDidSenderByIdApi);

  const [
    { isPending: isPendingPutDidSender },
    putDidSender,
  ] = useMutation(putDidSenderApi);

  const [
    { isPending: isPendingPutDeAssignDidSender },
    putDeAssignDidSender,
  ] = useMutation(putDeAssignDidSenderApi);

  const [
    { isPending: isPendingGetDidSenderHistory },
    getDidSenderHistory,
  ] = useMutation(getDidSenderHistoryApi);

  const [
    { isPending: isPendingPostDidSender },
    postDidSender,
  ] = useMutation(postDidSenderApi);

  const [
    { isPending: isPendingDeleteDidSender },
    deleteDidSender,
  ] = useMutation(deleteDidSenderApi);

  const selector = useCallback(state => ({
    didSenders: getArray(state, 'didSenders'),
    didSendersAvailable: getArray(state, 'didSendersAvailable'),
    didSendersAssigned: getArray(state, 'didSendersAssigned'),
    didSendersMeta: getObject(state, 'didSendersMeta'),
    didSendersAssignedMeta: getObject(state, 'didSendersAssignedMeta'),
    didSendersAvailableMeta: getObject(state, 'didSendersAvailableMeta'),
  }), []);
  const data = useISESelector(selector);

  return {
    postAssignDidSender,
    getDidSenders,
    deleteDidSenderActiveVersion,
    putReAssignDidSender,
    getDidSenderById,
    putDidSender,
    putDeAssignDidSender,
    getDidSenderHistory,
    postDidSender,
    deleteDidSender,
    isPendingPostAssignDidSender,
    isPendingGetDidSenders,
    isPendingDeleteDidSenderActiveVersion,
    isPendingPutReAssignDidSender,
    isPendingGetDidSenderById,
    isPendingPutDidSender,
    isPendingPutDeAssignDidSender,
    isPendingGetDidSenderHistory,
    isPendingPostDidSender,
    isPendingDeleteDidSender,
    ...data,
  };
};
