import endpoints from 'consts/endpoints';

export default () => ({
  url: endpoints.getCurrentUserUrl(),
  options: {
    method: 'POST',
  },
  meta: {
    authToken: true,
  },
  transform: data => ({
    currentUser: data,
  }),
  update: {
    currentUser: (oldValue, newValue) => newValue,
  },
});
