import React, { useEffect, useRef } from 'react';

import Table from 'components/Table';
import { CustomColumnsView } from 'components/blocks';
import Modal from 'components/Modal';
import { OWN_BANK_ACCOUNTS } from 'consts/columns';
import {
  useBankAccountsApi,
  useDictionariesApi,
  useCompaniesApi,
} from 'hooks/api';
import { ConfirmModal } from 'components/modals';
import { useScreen, useModalLogic } from 'hooks';
import toastRef from 'helpers/toast';

import OwnBankAccountForm from './OwnBankAccountForm';

const OwnBanksAccountScreen = ({
  screenRef,
}) => {
  const bodyRef = useRef(null);
  const {
    ownBankAccounts,
    ownBankAccountsMeta,
    isPendingGetOwnBankAccounts,
    lastUpdatedOwnGetBankAccounts,
    getOwnBankAccounts,
    deleteBankAccount,
    postBankAccount,
    putBankAccount,
    isPendingPostBankAccount,
    isPendingPutBankAccount,
    isPendingDeleteBankAccount,
  } = useBankAccountsApi();
  const {
    getCompaniesDictionary,
    getOwnLegalEntitiesDictionary,
    getParentBanksDictionary,
  } = useDictionariesApi();
  const { ownCompany } = useCompaniesApi();
  const {
    openModal,
    editableRecord,
    sendQuery,
    onCloseModal,
    limit,
    offset,
    setOffset,
    setLimit,
    onSortChanged,
    onFilterChanged,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    onDeleteClick,
    isFormPristine,
    formValuesRef,
    isOpenConfirm,
    closeConfirm,
    onDeleteConfirm,
    onConfirmClose,
    isOpenModal,
    onRowClicked,
  } = useScreen({
    screenRef,
    getFunc: getOwnBankAccounts,
    deleteFunc: deleteBankAccount,
    onRowClickCallback: data => data,
  });
  const [isOpenWarningModal, { openModal: openWarningModal, closeModal: closeWarningModal }] = useModalLogic();

  const onSubmit = (body, _, force = false) => {
    (editableRecord ? putBankAccount : postBankAccount)({
      params: { force },
      body: {
        ...body,
        companyId: ownCompany.id,
      },
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
        closeWarningModal();
      },
      errorCallback: ({ error, key } = {}) => {
        if (error === 'WARNING') {
          openWarningModal();
          bodyRef.current = { ...body };
        } else {
          toastRef?.current?.showMessage({ message: key || error, intent: 'danger' });
        }
      },
    });
  };
  const ignoreWarningAndSaveRecord = () => onSubmit(bodyRef.current, null, true);

  useEffect(() => {
    getCompaniesDictionary();
    getOwnLegalEntitiesDictionary();
    getParentBanksDictionary();
  }, []);

  return (
    <div>
      <Table
        limit={limit}
        total={ownBankAccountsMeta.size}
        page={offset}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        filteredColumns={filteredColumns}
        tableHeader="TABS.OWN_BANK_ACCOUNTS"
        openForm={() => openModal('add')}
        openCustomColumns={openCustomViewDrawer}
        buttonText="CONTROLS.OWN_BANK_ACCOUNTS.ADD"
        columnDefs={OWN_BANK_ACCOUNTS}
        rowData={ownBankAccounts}
        onRowClicked={onRowClicked}
        sendQuery={sendQuery}
        isPending={isPendingGetOwnBankAccounts || !lastUpdatedOwnGetBankAccounts}
        tableRef={tableRef}
      />
      <CustomColumnsView
        initialValues={filteredColumns}
        columnsNames={OWN_BANK_ACCOUNTS}
        onSubmit={onSaveCustomView}
        onClose={closeCustomViewDrawer}
        isOpen={isOpenCustomViewDrawer}
      />
      {
        isOpenModal && (
          <Modal
            mode={isOpenModal}
            closeModal={onCloseModal}
            title="SCREENS.OWN_BANK_ACCOUNTS.OWN_BANK_ACCOUNT"
            editableRecord={editableRecord}
          >
            <OwnBankAccountForm
              onSubmit={onSubmit}
              onCancel={onCloseModal}
              mode={isOpenModal}
              onDelete={onDeleteClick}
              changeMode={openModal}
              initialValues={editableRecord}
              isPending={isPendingPostBankAccount || isPendingPutBankAccount || isPendingDeleteBankAccount}
              isFormPristine={isFormPristine}
              formValuesRef={formValuesRef}
            />
          </Modal>
        )
      }
      {
        isOpenConfirm && (
          <ConfirmModal
            isDelete={isOpenConfirm === 'delete'}
            closeModal={closeConfirm}
            onConfirm={
              isOpenConfirm === 'delete' ? onDeleteConfirm : onConfirmClose
            }
          />
        )
      }
      {
        isOpenWarningModal && (
          <ConfirmModal
            title="SCREENS.BANK_ACCOUNTS.WARNING_TITLE"
            text="SCREENS.BANK_ACCOUNTS.WARNING_TEXT"
            leftText="CONTROLS.CANCEL"
            rightText="CONTROLS.SAVE"
            color="warning"
            iconColor="var(--orange30)"
            closeModal={closeWarningModal}
            onConfirm={ignoreWarningAndSaveRecord}
            isPending={isPendingPostBankAccount || isPendingPutBankAccount}
          />
        )
      }
    </div>
  );
};

export default OwnBanksAccountScreen;
