import React, { useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@blueprintjs/core';

import toastRef from 'helpers/toast';
import fetchFile from 'helpers/fetchFile';

import styles from './ImageField.module.scss';

const ImageField = ({
  label,
  field: {
    name,
    value,
  },
  form: {
    setFieldValue,
  },
  inputStyle,
  ratio,
  disabled,
  showRemoveButton,
}) => {
  const { t } = useTranslation();
  const [uploadedImage, setUploadedImage] = useState(null);

  const handleChange = ({ target }) => {
    const file = target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setUploadedImage(e.target.result);
      };

      reader.readAsDataURL(file);
      setFieldValue(name, file);
      target.value = '';
    }
  };
  const removeUploadedImage = () => {
    setFieldValue(name, null);
    setUploadedImage(null);
  };
  const downloadFile = () => {
    fetchFile({ url: value, fileName: value.split('/').at(-1) });
  };

  useLayoutEffect(() => {
    if (value) {
      fetch(value, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('boToken')}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.blob();
          }

          throw `${response.status} (${response.statusText})`;
        })
        .then((blob) => {
          if (typeof value === 'string' && value.endsWith('.svg')) {
            return blob.text().then(text => new Blob([text], { type: 'image/svg+xml' }));
          }

          return blob;
        })
        .then((response) => {
          setUploadedImage(URL.createObjectURL(response));
        })
        .catch((err) => {
          toastRef.current.showMessage({ message: err?.key ? err?.key : err, intent: 'danger' });
        });
    }
  }, []);

  return (
    <div className={styles.field}>
      {label && (
        <span className={styles.label}>
          {t(label)}
        </span>
      )}
      <div className={styles.content}>
        {uploadedImage ? (
          <img
            src={uploadedImage}
            style={inputStyle}
            className={styles.uploadedImage}
            alt=""
          />
        ) : (
          <label
            htmlFor={name}
            className={styles.input}
            style={inputStyle}
            data-testid={`${window.location.pathname}/image-field/${name}`}
          >
            <Icon
              icon="export"
              size={44}
              color="var(--blue60)"
            />
          </label>
        )}
        <div className={styles.tools}>
          {value && (
            <>
              {!disabled && (
                <Icon
                  icon="export"
                  size={18}
                  className={styles.toolButton}
                  tagName="label"
                  htmlFor={name}
                />
              )}
              {typeof value === 'string' && (
                <Icon
                  icon="import"
                  size={18}
                  className={styles.toolButton}
                  onClick={downloadFile}
                />
              )}
              {!disabled && showRemoveButton && typeof value !== 'string' && (
                <Icon
                  icon="trash"
                  size={18}
                  className={styles.toolButton}
                  onClick={removeUploadedImage}
                />
              )}
            </>
          )}
        </div>
        <input
          type="file"
          id={name}
          accept="image/*"
          onChange={handleChange}
          disabled={disabled}
        />
      </div>
      {ratio && (
        <span className={styles.hint}>
          {ratio}
        </span>
      )}
    </div>
  );
};

export default ImageField;
