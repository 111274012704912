// import { LIMITS } from 'consts';

import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import { Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import { useDropdownLogic } from 'hooks';
import { Dropdown } from '@setproduct-ui/core/Dropdown/Dropdown';

import './style.scss';

// todo: Костыль для сторибука, не видит ссылки на константы
const LIMITS = [10, 50, 100, 200, 500];

export const Pagination = ({
  total,
  limit,
  page,
  onChangeLimit,
  onChangePage,
  onRefreshClick,
  isPending,
  dense,
  styles = {},
  leftComponent = null,
  tableHeader,
  dataTestIdPrefix,
}) => {
  const { t } = useTranslation();
  const [isPendingModify, setIsPendingModify] = useState(false);
  const {
    buttonRef,
    dropdownRef,
    isDropdownOpen,
    setDropdownOpen,
    toggleDropdown,
  } = useDropdownLogic();

  const [selectedPage, setSelectedPage] = useState(page);

  const totalPages = useMemo(() => Math.ceil(total / limit), [total, limit]);
  const isFirstPageSelected = useMemo(() => Number(page) === 1, [page]);
  const isLastPageSelected = useMemo(() => Number(page) === totalPages, [page, totalPages]);
  const testIdPrefix = useMemo(() => dataTestIdPrefix || window.location.pathname, [dataTestIdPrefix]);

  const paginationClass = classNames('pagination', {
    pagination_dense: dense,
  });
  const leftIconClassName = classNames('pagination__icon', {
    disabled: isFirstPageSelected,
  });
  const rightIconClassName = classNames('pagination__icon', {
    disabled: isLastPageSelected,
  });
  const refreshClass = classNames('pagination__refresh', {
    pagination__refresh_spin: isPendingModify,
  });

  const onPageInputChange = (e) => {
    const newPage = e.target.value.replace(/\D/g, '');
    if (newPage <= totalPages && +newPage !== 0 || newPage === '') {
      setSelectedPage(newPage);
    }
  };

  const onPageInputBlur = () => {
    if (!selectedPage) {
      setSelectedPage(page);
    }
  };

  const onEnterPress = (e) => {
    if (e.key === 'Enter' && selectedPage && selectedPage !== page) {
      onChangePage(selectedPage);
    }
  };

  const refreshData = () => {
    if (!isPendingModify) {
      onRefreshClick();
    }
  };

  useEffect(() => {
    if (selectedPage !== page) {
      setSelectedPage(page);
    }
  }, [page]);

  useEffect(() => {
    if (selectedPage !== page) {
      setSelectedPage(selectedPage);
    }
  }, [selectedPage]);

  useEffect(() => {
    if (!isPending) {
      setTimeout(() => setIsPendingModify(false), 1000);
    } else {
      setIsPendingModify(true);
    }
  }, [isPending]);

  const dataTestId = useMemo(() => `${tableHeader ? `/${tableHeader.toLowerCase()?.replace('.', '-')}` : ''}`, [tableHeader]);

  return (
    <div
      data-testid={`${testIdPrefix}/pagination${dataTestId}`}
      className={paginationClass}
      style={styles.container}
    >
      {leftComponent}
      <Icon
        icon="chevron-backward"
        size={14}
        color={isFirstPageSelected ? '#B4B4BB' : '#2979FF'}
        className={leftIconClassName}
        data-testid={`${testIdPrefix}/pagination${dataTestId}/to-first-page`}
        onClick={() => {
          onChangePage(1);
        }}
      />
      <Icon
        icon="chevron-left"
        size={14}
        color={isFirstPageSelected ? '#B4B4BB' : '#2979FF'}
        className={leftIconClassName}
        data-testid={`${testIdPrefix}/pagination${dataTestId}/to-prev-page`}
        onClick={() => {
          onChangePage(page - 1);
        }}
      />
      <div className="pagination__wrapper">
        <p className="pagination__wrapper__text">{t('PAGINATION.PAGE')}</p>
        <input
          type="text"
          className="pagination__wrapper__input"
          onChange={onPageInputChange}
          onBlur={onPageInputBlur}
          onKeyPress={onEnterPress}
          value={selectedPage}
          data-testid={`${testIdPrefix}${dataTestId}/pagination/page`}
        />
        <p className="pagination__wrapper__text">
          {t('PAGINATION.OF')}
          <span>{totalPages || '0'}</span>
        </p>
      </div>
      <Icon
        icon="chevron-right"
        size={14}
        color={isLastPageSelected ? '#B4B4BB' : '#2979FF'}
        className={rightIconClassName}
        data-testid={`${testIdPrefix}${dataTestId}/pagination/to-next-page`}
        onClick={() => {
          onChangePage(Number(page) + 1);
        }}
      />
      <Icon
        icon="chevron-forward"
        size={14}
        color={isLastPageSelected ? '#B4B4BB' : '#2979FF'}
        className={rightIconClassName}
        data-testid={`${testIdPrefix}${dataTestId}/pagination/to-last-page`}
        onClick={() => {
          onChangePage(totalPages);
        }}
      />
      <Icon
        icon="refresh"
        size={16}
        color="var(--blue60)"
        className={refreshClass}
        onClick={refreshData}
        aria-disabled={isPendingModify}
        data-testid={`${testIdPrefix}${dataTestId}/pagination/refresh`}
      />
      <div className="pagination__rows" ref={dropdownRef}>
        <p className="pagination__rows__title">{t('PAGINATION.SHOW')}</p>
        <Dropdown
          view="outlined"
          color="default"
          text={limit}
          className="pagination__rows__dropdown"
          dense
          onClick={toggleDropdown}
          content={[]}
          ref={buttonRef}
          data-testid={`${testIdPrefix}${dataTestId}/pagination/limit-dropdown`}
        />
        {
          isDropdownOpen && (
            <div className="pagination__rows__container">
              {
                LIMITS.map(item => (
                  <button
                    className="pagination__rows__container__item"
                    onClick={() => {
                      onChangeLimit(item);
                      setDropdownOpen(false);
                    }}
                    type="button"
                    name="row"
                    value={item}
                    key={item}
                    data-testid={`${testIdPrefix}${dataTestId}/pagination/limit/${item}`}
                  >
                    {item}
                  </button>
                ))
              }
            </div>
          )
        }
        <p className="pagination__rows__count">
          {t('PAGINATION.ROWS')}
          <span className="pagination__rows__page-info">
            {`${(page - 1) * limit + 1}-${(page * limit) > total ? total : page * limit}`}
          </span>
          {t('PAGINATION.OF')}
          <span>{total || '0'}</span>
        </p>
      </div>
    </div>
  );
};
