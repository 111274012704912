import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNamesBuilder from 'classnames';
import { Icon } from '@blueprintjs/core';

import { Typography } from 'components/Typography';
import Input from '@setproduct-ui/core/Inputs/DefaultInput';
import copyInClipboard from 'helpers/copyInClipboard';

import './style.scss';

const TextField = ({
  name: externalName,
  value: externalValue,
  onChange: externalOnChange = Function.prototype,
  setFieldValue: externalSetFieldValue,
  field: {
    onChange = externalOnChange,
    value = externalValue,
    name = externalName,
  } = {},
  form: { setFieldValue = externalSetFieldValue, errors = {}, touched = {} } = {},
  placeholder,
  label,
  styles = {},
  classNames = {},
  clearButton = true,
  fieldType = 'text',
  isRequired,
  showErrorIcon,
  showErrorMessage = true,
  fieldError,
  fieldTouched,
  disabled,
  type,
  view = 'outlined',
  dense,
  children,
  newDesign,
  rightElement,
  withCopyButton,
  splitOnDigits = false,
  titleText,
  title,
  ...props
}) => {
  const { t } = useTranslation();

  const fieldClass = classNamesBuilder('text-field', {
    'text-field_new-design': newDesign,
    [`text-field_${type}`]: type,
    [classNames.container]: classNames.container,
  });
  const labelClass = classNamesBuilder('text-field__label', {
    'text-field__label_required': isRequired,
    'text-field__label_dense': dense,
    'text-field__label_new-design': newDesign,
    [classNames.label]: !!classNames.label,
  });

  const handleChange = (val) => {
    let copyOfValue = val;
    if (splitOnDigits) {
      copyOfValue = copyOfValue
        ?.replaceAll(' ', '');
    }
    if (setFieldValue) {
      setFieldValue(name, copyOfValue || null);
    } else {
      onChange(copyOfValue || null);
    }
  };

  const modifiedValue = useMemo(() => {
    if (splitOnDigits && value) {
      return String(value)
        ?.replace(/[^0-9.]/g, '')
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    return value;
  }, [splitOnDigits, value]);

  const rightElementToDisplay = useMemo(() => {
    if (rightElement) {
      return rightElement;
    }
    if (withCopyButton) {
      return (
        <Icon
          icon="duplicate"
          size={20}
          color="var(--blue70)"
          className="text-field__copy-button"
          onClick={() => copyInClipboard(value)}
        />
      );
    }

    return null;
  }, [rightElement, withCopyButton]);
  const isHaveError = useMemo(
    () => (errors[name] && touched[name]) || (fieldError && fieldTouched),
    [errors, touched, fieldError, fieldTouched, name],
  );
  const errorHelperText = useMemo(() => (fieldError && fieldTouched
    ? t(fieldError)
    : errors[name] && touched[name]
      ? typeof errors[name] === 'object' ? t(errors[name].key, errors[name].values) : t(errors[name])
      : ''), [fieldError, fieldTouched, errors, touched, name]);
  const titleModify = useMemo(() => {
    if (title !== undefined) {
      return title ? t(title) : undefined;
    }

    if (!showErrorMessage && errorHelperText) {
      return errorHelperText;
    }

    return undefined;
  }, [showErrorMessage, title, errorHelperText]);

  return (
    <>
      <Input
        type={fieldType}
        view={view}
        className={fieldClass}
        label={label && (
          <div
            style={styles?.label}
            className="text-field__label-container"
          >
            <Typography
              className={labelClass}
            >
              {label}
            </Typography>
            {titleText && (
              <Icon
                icon="info-sign"
                color="var(--grey30)"
                htmlTitle={t(titleText)}
                className="text-field__helper-icon"
                size={14}
                style={{
                  cursor: 'pointer',
                }}
              />
            )}
          </div>
        )}
        name={name}
        onChange={handleChange}
        value={modifiedValue}
        error={isHaveError}
        errorHelperText={showErrorMessage && errorHelperText}
        title={titleModify}
        placeholder={disabled ? '' : t(placeholder)}
        style={styles.container}
        clearButton={clearButton}
        showErrorIcon={showErrorIcon}
        disabled={disabled}
        color={isHaveError ? 'danger' : 'default'}
        dense={dense}
        rightElement={rightElementToDisplay}
        data-testid={name
          ? `${window.location.pathname}/field/${name}`
          : undefined}
        {...props}
      />
      {children}
    </>
  );
};

export default TextField;
