import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';
import { cancelQuery } from 'redux-query';
import { useDispatch } from 'react-redux';

import {
  getServicesEnabled as getServicesEnabledApi,
  deleteServiceEnabled as deleteServiceEnabledApi,
  putServiceEnabled as putServiceEnabledApi,
  postServiceEnabled as postServiceEnabledApi,
  getServices as getServicesApi,
} from 'queries/servicesEnabled';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const dispatch = useDispatch();

  const [
    {
      isPending: isPendingGetServicesEnabled,
      lastUpdated: lastUpdatedGetServicesEnabled,
    },
    getServicesEnabled,
  ] = useMutation(getServicesEnabledApi);
  const [
    {
      isPending: isPendingGetServices,
    },
    getServices,
  ] = useMutation(getServicesApi);
  const [
    {
      isPending: isPendingDeleteServiceEnabled,
    },
    deleteServiceEnabled,
  ] = useMutation(deleteServiceEnabledApi);
  const [
    {
      isPending: isPendingPutServiceEnabled,
    },
    putServiceEnabled,
  ] = useMutation(putServiceEnabledApi);
  const [
    {
      isPending: isPendingPostServiceEnabled,
    },
    postServiceEnabled,
  ] = useMutation(postServiceEnabledApi);

  const selector = useCallback(state => ({
    servicesEnabled: getArray(state, 'servicesEnabled'),
    servicesEnabledMeta: getObject(state, 'servicesEnabledMeta'),
    services: getArray(state, 'services'),
  }), []);
  const data = useISESelector(selector);
  const cancelGetServicesEnabledQuery = () => {
    dispatch(
      cancelQuery('getServicesEnabled'),
    );
  };

  return {
    getServicesEnabled,
    getServices,
    deleteServiceEnabled,
    putServiceEnabled,
    postServiceEnabled,
    cancelGetServicesEnabledQuery,
    isPendingGetServicesEnabled,
    isPendingGetServices,
    isPendingPutServiceEnabled,
    isPendingDeleteServiceEnabled,
    isPendingPostServiceEnabled,
    lastUpdatedGetServicesEnabled,
    ...data,
  };
};
