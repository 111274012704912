import React, {
  useMemo, useRef, useState,
} from 'react';
import { Field, Form, Formik } from 'formik';
import { isEmpty, isEqual, isNull } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { getDiffFromNow, round } from 'helpers';
import { DatePickerField, DropdownField } from 'components/fields';
import { FormButtonsGroup } from 'components/blocks';
import { PACKS_ENABLED_SCHEMA } from 'consts/validationSchemas';
import { usePacksApi } from 'hooks/api';
import IsFormChanged from 'components/forms/IsFormChanged';
import OnChangeComponent from 'components/forms/OnChangeComponent';
import RadioButtonField from 'components/fields/RadioButtonField';
import ChargePrice from 'components/blocks/ChargePrice';
import { PACKS_BILLING_TYPES } from 'consts';

import './style.scss';

const PacksEnabledForm = ({
  changeMode,
  mode,
  onSubmit,
  initialValues = {},
  onDelete,
  onCancel,
  isPending,
  isFormPristine,
  formValuesRef,
  agreementsOptions,
  packsOptions,
  timezonesOptions,
}) => {
  // styles
  const fieldStyle = ({
    width,
    marginTop,
    maxHeight,
    marginRight,
    marginLeft,
    bottom,
    top,
  } = {}) => ({
    container: {
      width,
      marginRight,
      marginLeft,
    },
    label: {
      marginTop,
    },
    overlay: {
      maxHeight,
      bottom,
      top,
    },
  });
  const agreementsDropdownStyles = {
    container: {
      marginTop: 24,
    },
    overlay: {
      maxHeight: 180,
    },
  };
  const servicesDropdownStyles = {
    overlay: {
      maxHeight: 180,
    },
  };
  const checkBoxStyles = {
    container: {
      marginLeft: 0,
      marginTop: 24,
    },
  };
  const timezoneDropdownFieldStyles = {
    container: {
      width: 268,
    },
    overlay: {
      bottom: '100%',
      right: 0,
      top: 'initial',
      left: 'initial',
    },
  };
  // hooks
  const formRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getPackPrice } = usePacksApi();
  const [selectedService, setSelectedService] = useState({});
  const [packPrice, setPackPrice] = useState();
  const { activeTab } = useSelector(state => state?.states.routes);
  // memed vars
  const isView = useMemo(() => mode === 'view', [mode]);
  const isEdit = useMemo(() => mode === 'edit', [mode]);
  const isAdd = useMemo(() => mode === 'add', [mode]);

  const isDisabledEffective = (target) => {
    if (isView) {
      return true;
    }
    if (isAdd) {
      return false;
    }
    if (isNull(target)) {
      return false;
    }

    return moment(target).isBefore(moment());
  };

  const agreementsByCurrency = useMemo(() => {
    if (selectedService?.currency) {
      return agreementsOptions.filter(
        ({ currency }) => currency === selectedService.currency,
      );
    }
    return agreementsOptions;
  }, [selectedService, packsOptions, agreementsOptions]);

  // functions
  const changeToEdit = () => changeMode('edit');

  const onSubmitModify = ({
    effectiveFrom, effectiveTill, effectiveFromTz, effectiveTillTz, ...values
  }) => {
    const {
      id,
      agreementId,
      serviceId,
      billing,
    } = values;
    const effectiveFromWithTz = isDisabledEffective(effectiveFrom) || getDiffFromNow(effectiveFrom) >= 0
      ? moment(effectiveFrom).tz(effectiveFromTz, true).utc().format('YYYY-MM-DDTHH:mm:ss')
      : 'now';
    let effectiveTillWithTz;
    if (effectiveTill) {
      effectiveTillWithTz = moment(effectiveTill)
        .tz(effectiveTillTz, true)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss');
    }
    const data = {
      effectiveFrom: effectiveFromWithTz === 'now' ? effectiveFromWithTz : `${effectiveFromWithTz}Z`,
      effectiveTill: effectiveTillWithTz ? `${effectiveTillWithTz}Z` : null,
      agreementId,
      serviceId,
    };
    if (id) {
      data.id = id;
    } else {
      data.billing = billing;
    }
    onSubmit(data);
  };

  const onChangeFormValues = (values) => {
    if (values.serviceId && values.agreementId && values.effectiveFrom && values.billing && isAdd) {
      const sendPoint = getDiffFromNow(values.effectiveFrom) < 0;

      getPackPrice({
        params: {
          serviceId: values.serviceId,
          agreementId: values.agreementId,
          effectiveFrom: sendPoint ? 'now' : `${moment(values.effectiveFrom).format('YYYY-MM-DDTHH:mm:ss')}Z`,
          effectiveTill: values.effectiveTill
            ? `${moment(values.effectiveTill).format('YYYY-MM-DDTHH:mm:ss')}Z`
            : undefined,
        },
        successCallback: (res) => {
          setPackPrice({
            price: `${round({ num: res.value })} ${res.agreementCurrency}`,
            service: res.serviceName,
            date: `Effective: ${moment(res.effectiveFrom).format('DD.MM.YYYY HH:mm:ss')}${res.effectiveTill
              ? `- ${moment(res.effectiveTill).format('DD.MM.YYYY HH:mm:ss')}` : ''}`,
          });
        },
        errorCallback: ({ key } = {}) => {
          setPackPrice({ errorMessage: t(key) || 'Unknown error' });
        },
      });
    } else if (packPrice) {
      setPackPrice(undefined);
    }
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }
    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };

  const onChangeAgreementId = ({ value: selectedAgreementId, values }) => {
    if (selectedAgreementId) {
      const { fromTz, tillTz } = agreementsOptions.find(I => I.value === selectedAgreementId);
      if (values?.effectiveFrom) {
        formRef.current?.setFieldValue('effectiveFromTz', fromTz);
      }
      if (values?.effectiveTill || values?.effectiveTill === null) {
        formRef.current?.setFieldValue('effectiveTillTz', tillTz);
      }
    }
  };

  const onChangeServiceId = ({ value: selectedServiceId }) => {
    const target = packsOptions.find(
      ({ value }) => value === selectedServiceId,
    );
    setSelectedService(target);
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        effectiveFrom: moment().startOf('day').toDate(),
        effectiveTill: null,
        billing: false,
        ...initialValues,
      }}
      onSubmit={onSubmitModify}
      validationSchema={PACKS_ENABLED_SCHEMA}
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
        values,
      }) => (
        <Form className="packs-enabled-form">
          <div className="packs-enabled-form__content">
            <Field
              name="serviceId"
              component={DropdownField}
              label="INSTANCES.PACKS_ENABLED.SERVICE_PACK"
              styles={servicesDropdownStyles}
              options={packsOptions}
              disabled={isView || isEdit}
              isRequired={!isView}
              placeholder="PLACEHOLDERS.SELECT"
            />
            <Field
              name="agreementId"
              component={DropdownField}
              label="INSTANCES.AGREEMENT"
              styles={agreementsDropdownStyles}
              options={agreementsByCurrency}
              disabled={isView || isEdit}
              isRequired={!isView}
              placeholder="PLACEHOLDERS.SELECT"
            />
            <div className="packs-enabled-form__row">
              <Field
                name="effectiveFrom"
                label="INSTANCES.EFFECTIVE_FROM"
                component={DatePickerField}
                styles={fieldStyle({
                  width: 268,
                  marginRight: 'auto',
                })}
                maxDate={values?.effectiveTill}
                minDate={moment().toDate()}
                disabled={isDisabledEffective(values.effectiveFrom)}
                isRequired
                tooltipMessage={t('TOOLTIPS.PACKS_ENABLED.EFFECTIVE_FROM')}
                timePrecision="second"
                placeholder="DD.MM.YYYY, HH:mm:ss"
                format="DD.MM.YYYY, HH:mm:ss"
                placement="right"
                datePickerProps={{
                  timePickerProps: {
                    showArrowButtons: true,
                  },
                }}
                isNow={!isView}
              />
              <Field
                name="effectiveFromTz"
                label="INSTANCES.PACKS_ENABLED.TIMEZONE"
                placeholder="PLACEHOLDERS.AGREEMENTS.SELECT_TIME_ZONE"
                component={DropdownField}
                options={timezonesOptions}
                styles={timezoneDropdownFieldStyles}
                disabled
                translateOptions={false}
              />
            </div>
            <div className="packs-enabled-form__row">
              <Field
                name="effectiveTill"
                label="INSTANCES.EFFECTIVE_TILL"
                component={DatePickerField}
                styles={fieldStyle({
                  width: 268,
                  marginRight: 'auto',
                })}
                minDate={moment(moment(values?.effectiveFrom) > moment()
                  ? values?.effectiveFrom
                  : moment())
                  .add(5, 'minutes')
                  .toDate()}
                disabled={isDisabledEffective(values.effectiveTill)}
                withIndefinitely
                tooltipMessage={t('TOOLTIPS.PACKS_ENABLED.EFFECTIVE_TILL')}
                timePrecision="second"
                placeholder="DD.MM.YYYY, HH:mm:ss"
                format="DD.MM.YYYY, HH:mm:ss"
                placement="right"
                datePickerProps={{
                  timePickerProps: {
                    showArrowButtons: true,
                  },
                }}
              />
              <Field
                name="effectiveTillTz"
                label="INSTANCES.PACKS_ENABLED.TIMEZONE"
                placeholder="PLACEHOLDERS.AGREEMENTS.SELECT_TIME_ZONE"
                component={DropdownField}
                options={timezonesOptions}
                styles={timezoneDropdownFieldStyles}
                disabled
                translateOptions={false}
              />
            </div>
            {!isEdit && !isView && (
              <Field
                name="billing"
                label="INSTANCES.BILLING"
                component={RadioButtonField}
                options={PACKS_BILLING_TYPES}
                styles={checkBoxStyles}
                isRequired
              />
            )}
            <ChargePrice
              style={{ marginTop: 8, marginLeft: 32 }}
              {...(packPrice && packPrice)}
            />
          </div>
          <FormButtonsGroup
            onDelete={onDelete}
            onCancel={onCancel}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            errors={errors}
            setFieldTouched={setFieldTouched}
            showDelete={!isDisabledEffective(values.effectiveFrom)}
            isPending={isPending}
          />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={onChangeFormValues} />
          <OnChangeComponent field="agreementId" onChange={onChangeAgreementId} />
          <OnChangeComponent field="serviceId" onChange={onChangeServiceId} />
        </Form>
      )}
    </Formik>
  );
};

export default PacksEnabledForm;
