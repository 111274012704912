import React from 'react';

import Table from 'components/Table';
import { NOTIFICATIONS_SETTINGS } from 'consts/columns';
import { useNotificationsApi } from 'hooks/api';
import { useScreen } from 'hooks';
import { CustomColumnsView } from 'components/blocks';
import Modal from 'components/Modal';
import ConfirmModal from 'components/modals/ConfirmModal';

import NotificationsForm from './NotificationsForm';

const NotificationScreens = ({ screenRef }) => {
  const {
    isPendingGetNotificationsScenarios,
    lastUpdatedGetNotificationsScenarios,
    isPendingPutNotificationsScenario,
    getNotificationsScenarios,
    putNotificationsScenario,
    notificationsScenarios,
    notificationsScenariosMeta,
  } = useNotificationsApi();

  const {
    openModal,
    editableRecord,
    sendQuery,
    onCloseModal,
    limit,
    offset,
    setOffset,
    setLimit,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    isOpenConfirm,
    onDeleteConfirm,
    onConfirmClose,
    onSaveCustomView,
    closeConfirm,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    isFormPristine,
    formValuesRef,
    isOpenModal,
    onRowClicked,
    onFilterChanged,
    onSortChanged,
  } = useScreen({
    screenRef,
    getFunc: getNotificationsScenarios,
    onRowClickCallback: data => data,
  });

  // const sectionsOptions = useMemo(() => routes.reduce((acc, parent) => {
  //   acc.push({
  //     value: parent.path,
  //     label: t(parent.name),
  //   });
  //   parent?.children?.map(child => acc.push({
  //     value: child.path,
  //     label: t(child.name),
  //   }));
  //   return acc;
  // }, []), [routes]);

  const getRowHeight = () => 50;

  const onSubmit = (data) => {
    putNotificationsScenario({
      body: {
        id: data.id,
        channelId: data.channelId,
        event: data.event,
        chapterUrl: data.chapterUrl,
        receivers: data.receivers
          ? data.receivers.split(',').map(I => I.trim())
          : [],
        template: data.template,
      },
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
    });
  };

  return (
    <div>
      <Table
        tableHeader="TABS.NOTIFICATIONS_SETTINGS"
        columnDefs={NOTIFICATIONS_SETTINGS}
        rowData={notificationsScenarios}
        onRowClicked={onRowClicked}
        showFilters={false}
        getRowHeight={getRowHeight}
        openCustomColumns={openCustomViewDrawer}
        limit={limit}
        total={notificationsScenariosMeta.size}
        page={offset}
        filteredColumns={filteredColumns}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        sendQuery={sendQuery}
        isPending={isPendingGetNotificationsScenarios || !lastUpdatedGetNotificationsScenarios}
        tableRef={tableRef}
        onFilterChanged={onFilterChanged}
        onSortChanged={onSortChanged}
      />
      {
        isOpenCustomViewDrawer && (
          <CustomColumnsView
            initialValues={filteredColumns}
            columnsNames={NOTIFICATIONS_SETTINGS}
            onSubmit={onSaveCustomView}
            onClose={closeCustomViewDrawer}
            isOpen={isOpenCustomViewDrawer}
          />
        )
      }
      {
        isOpenModal && (
          <Modal
            mode={isOpenModal}
            closeModal={onCloseModal}
            title="SCREENS.NOTIFICATIONS_SCENARIOS_SETTINGS.NOTIFICATION_SCENARIO"
            editableRecord={editableRecord}
            withInfo={false}
          >
            <NotificationsForm
              onSubmit={onSubmit}
              onCancel={onCloseModal}
              initialValues={editableRecord}
              mode={isOpenModal}
              changeMode={openModal}
              isFormPristine={isFormPristine}
              isPending={isPendingPutNotificationsScenario}
              // sectionsOptions={sectionsOptions}
              formValuesRef={formValuesRef}
            />
          </Modal>
        )
      }
      {
        isOpenConfirm && (
          <ConfirmModal
            isDelete={isOpenConfirm === 'delete'}
            closeModal={closeConfirm}
            onConfirm={
              isOpenConfirm === 'delete' ? onDeleteConfirm : onConfirmClose
            }
          />
        )
      }
    </div>
  );
};

export default NotificationScreens;
