import React, { useState, useMemo, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { InputGroup, Icon, Button } from '@blueprintjs/core';

import View from './_view.module.css';
import Type from './_type.module.css';

import Color from '../../../styles/color.module.css';
import { ThemeContext } from '../../ThemeContext';

/**
  __Component 'Default Input'__
* */

const TextInput = React.forwardRef((props, ref) => {
  const {
    view = 'smooth',
    color = 'primary',
    dense,
    className,
    placeholder,
    label,
    id,
    clearButton,
    clearIcon,
    rightElement,
    success,
    error,
    full,
    errorHelperText,
    fixed,
    value,
    onChange,
    autoComplete = 'off',
    onFocus,
    onBlur,
    regex,
    showErrorIcon,
    onClear,
    maxLength,
    ...restProps
  } = props;

  const [isActive, setActive] = useState(false); // component active state (focused)
  const [mouseDown, setMouseDown] = useState(false);

  const successIcon = (
    <Icon className={Type.resultIcon} color="#43A047" icon={restProps.successIcon || 'tick'} size={16} />
  );
  const errorIcon = (
    <Icon className={Type.resultIcon} icon={restProps.errorIcon || 'error'} color="#F6655A" size={16} />
  );

  const resultIcon = success ? successIcon : error && showErrorIcon ? errorIcon : rightElement;

  const isFilled = useMemo(() => !isActive && !!value, [isActive, value]);

  // add active class and placeholder
  const handleFocus = (e) => {
    setActive(true);

    if (onFocus) {
      onFocus(e);
    }
  };

  // remove active class and placeholder
  const handleBlur = () => {
    if (mouseDown) {
      setMouseDown(false);
    } else {
      setActive(false);
    }

    if (onBlur) {
      onBlur();
    }
  };

  const handleClear = (e) => {
    onChange('');
    setActive(false);

    if (onClear) {
      onClear(e);
    }
  };

  const handleChange = (e) => {
    if (regex) {
      const regExp = new RegExp(regex, 'i');

      if (regExp.test(e.target.value)) {
        onChange(e.target.value);
      } else {
        onChange(value || '');
      }
    } else {
      onChange(e.target.value);
    }
  };

  useEffect(() => {
    if (value === undefined) {
      onChange('');
    }
  }, [value]);

  return (
    <ThemeContext.Consumer>
      {({ dark }) => (
        <InputGroup
          {...restProps}
          ref={ref}
          value={value ?? ''}
          className={cx(
            fixed ? Type.fixed : dense ? Type.dense : Type.def,
            full && Type.full,
            dense ? View[dense] : View.def,
            isActive && View.active,
            clearButton && Type.clearButton,
            clearButton && View.clearButton,
            !success && !error && isFilled && View.isFilled,
            /** SUCCESS STYLE */
            success && View.success,
            /* ERROR STYLE */
            Color[color],
            error && View['error-helper-text'],
            resultIcon && Type['right-icon'],
            /* Dark mode */
            dark ? View[`${view}-dark`] : fixed ? View.fixed : View[view],
            className,
          )}
          placeholder={placeholder}
          autoComplete={autoComplete}
          maxLength={maxLength}
          leftIcon={(
            <>
              {label && <label>{label}</label>}
              {errorHelperText && error && (
                <div
                  className={cx(
                    Type['error-helper-text'],
                    View['error-helper-text'],
                  )}
                >
                  {errorHelperText}
                </div>
              )}
              {maxLength && (
                <div
                  className={cx(
                    Type['max-length'],
                    View['max-length'],
                  )}
                >
                  {`${value?.length || 0} / ${maxLength}`}
                </div>
              )}
            </>
          )}
          onFocus={handleFocus}
          onChange={handleChange}
          onBlur={handleBlur}
          rightElement={
            clearButton && isActive && value ? (
              <Button
                icon={<Icon icon={clearIcon || 'cross'} size="20" color="#F6655A" />}
                minimal
                onClick={e => handleClear(e)}
                onMouseDown={() => setMouseDown(true) }
              />
            ) : (
              resultIcon
            )
          }
        />
      )}
    </ThemeContext.Consumer>
  );
});

TextInput.propTypes = {
  /**
   ` The view of the component.
   * Variants: `outlined`
   * Default value (if undefined): `smooth` `
   */
  view: PropTypes.oneOf(['smooth', 'outlined', 'raised', 'filled']),
  /**
   * Placeholder text in the absence of any value.
   */
  placeholder: PropTypes.string,
  /**
   * Label text
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Form value of the input
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * If `true`, the component will take up the full width of its container.
   */
  fill: PropTypes.bool,
  /**
   * Under input helper text
   */
  helperText: PropTypes.string,
  /**
   * Set Clear button
   */
  clearButton: PropTypes.bool,
  /**
   * Set Success style for input
   */
  succes: PropTypes.bool,
  /**
   * Set Error style for input
   */
  error: PropTypes.bool,
  /**
   * Set Fixed label for input
   */
  fixed: PropTypes.bool,
  /**
   * Element to render on right side of input.
   */
  rightElement: PropTypes.element,
  /**
   * Custom right icon for Success style
   */
  successIcon: PropTypes.string,
  /**
   * Custom right icon for Error style
   */
  errorIcon: PropTypes.string,
  /**
   * Set custom icon for "Clear button" element
   */
  clearIcon: PropTypes.string,
};

export default TextInput;
