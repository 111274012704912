import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import moment from 'moment-timezone';
import { useHistory, useParams } from 'react-router-dom';

import {
  useServicesEnabledApi,
  useDictionariesApi,
  usePacksApi,
  useBundlesEnabledApi,
} from 'hooks/api';
import { useFilteredColumns, useScreen, useTable } from 'hooks';
import ScrollableTabs from 'components/ScrollableTabs';
import Table from 'components/Table';
import Button from '@setproduct-ui/core/Button';
import ConfirmModal from 'components/modals/ConfirmModal';
import { CustomColumnsView } from 'components/blocks';
import {
  SERVICES_ENABLED,
  PACKS_ENABLED,
  BUNDLES_ENABLED,
} from 'consts/columns';
import Modal from 'components/Modal';

import { SERVICES_ENABLED_SCREEN_TABS, SERVICES_ENABLED_TABS_DICTIONARY } from './consts';
import ServiceEnabledForm from './ServiceEnabledForm';
import PacksEnabledForm from './PacksEnabledForm';
import BundleInitiatorEnabledForm from './BundleInitiatorEnabledForm';
import BundlePackEnabledForm from './BundlePackEnabledForm';

const ServicesEnabledScreen = ({ screenRef }) => {
  const servicesTableRef = useRef(null);
  const packsTableRef = useRef(null);
  const bundleInitiatorsTableRef = useRef(null);
  const bundlePacksTableRef = useRef(null);
  const history = useHistory();
  const { initialTab } = useParams();
  const [activeTab, setActiveTab] = useState(SERVICES_ENABLED_TABS_DICTIONARY[initialTab] || 0);
  const {
    getServicesEnabled,
    putServiceEnabled,
    postServiceEnabled,
    isPendingGetServicesEnabled,
    isPendingPutServiceEnabled,
    isPendingPostServiceEnabled,
    lastUpdatedGetServicesEnabled,
    servicesEnabled,
    servicesEnabledMeta,
  } = useServicesEnabledApi();
  const {
    packsEnabled,
    packsEnabledMeta,
    getPacksEnabled,
    putPackEnabled,
    postPackEnabled,
    isPendingGetPacksEnabled,
    isPendingPutPackEnabled,
    isPendingPostPackEnabled,
    lastUpdatedGetPacksEnabled,
  } = usePacksApi();
  const {
    getBundleInitiatorsEnabled,
    getBundlePacksEnabled,
    postBundleInitiatorEnabled,
    postBundlePackEnabled,
    patchBundleInitiatorEnabled,
    patchBundlePackEnabled,
    isPendingGetBundleInitiatorsEnabled,
    isPendingGetBundlePacksEnabled,
    isPendingPostBundleInitiatorEnabled,
    isPendingPostBundlePackEnabled,
    isPendingPatchBundleInitiatorEnabled,
    isPendingPatchBundlePackEnabled,
    lastUpdatedGetBundleInitiatorsEnabled,
    lastUpdatedGetBundlePacksEnabled,
    bundleInitiatorsEnabled,
    bundleInitiatorsEnabledMeta,
    bundlePacksEnabled,
    bundlePacksEnabledMeta,
  } = useBundlesEnabledApi();
  const {
    timezones,
    servicesOptions,
    getServicesDictionary,
    getCompaniesDictionary,
    agreementsDictionary,
    agreementsOptions,
    getAgreementsDictionary,
    isPendingGetAgreementsDictionary,
  } = useDictionariesApi();
  const {
    isOpenCustomViewDrawer: servicesIsOpenCustomViewDrawer,
    closeCustomViewDrawer: servicesCloseCustomViewDrawer,
    openCustomViewDrawer: servicesOpenCustomViewDrawer,
    onSaveCustomView: servicesOnSaveCustomView,
    filteredColumns: servicesFilteredColumns,
  } = useFilteredColumns({
    sessionKey: `${window.location.pathname}_services`,
  });
  const {
    isOpenCustomViewDrawer: packsIsOpenCustomViewDrawer,
    closeCustomViewDrawer: packsCloseCustomViewDrawer,
    openCustomViewDrawer: packsOpenCustomViewDrawer,
    onSaveCustomView: packsOnSaveCustomView,
    filteredColumns: packsFilteredColumns,
  } = useFilteredColumns({
    sessionKey: `${window.location.pathname}_services`,
  });
  const {
    isOpenCustomViewDrawer: bundleInitiatorsIsOpenCustomViewDrawer,
    closeCustomViewDrawer: bundleInitiatorsCloseCustomViewDrawer,
    openCustomViewDrawer: bundleInitiatorsOpenCustomViewDrawer,
    onSaveCustomView: bundleInitiatorsOnSaveCustomView,
    filteredColumns: bundleInitiatorsFilteredColumns,
  } = useFilteredColumns({
    sessionKey: `${window.location.pathname}_bundleInitiators`,
  });

  const {
    isOpenCustomViewDrawer: bundlePacksIsOpenCustomViewDrawer,
    closeCustomViewDrawer: bundlePacksCloseCustomViewDrawer,
    openCustomViewDrawer: bundlePacksOpenCustomViewDrawer,
    onSaveCustomView: bundlePacksOnSaveCustomView,
    filteredColumns: bundlePacksFilteredColumns,
  } = useFilteredColumns({
    sessionKey: `${window.location.pathname}_bundlePacks`,
  });
  const {
    sendQuery: servicesSendQuery,
    limit: servicesLimit,
    offset: servicesOffset,
    onSortChanged: servicesOnSortChanged,
    onFilterChanged: servicesOnFilterChanged,
    setAndSendLimit: servicesSetLimit,
    setAndSendOffset: servicesSetOffset,
  } = useTable({
    sendQuery: getServicesEnabled,
    tableRef: servicesTableRef,
    initialFilters: history?.location?.pathname?.endsWith('services') && history?.location?.state?.tableFilters,
  });
  const {
    sendQuery: packsSendQuery,
    limit: packsLimit,
    offset: packsOffset,
    onSortChanged: packsOnSortChanged,
    onFilterChanged: packsOnFilterChanged,
    setAndSendLimit: packsSetLimit,
    setAndSendOffset: packsSetOffset,
  } = useTable({
    sendQuery: getPacksEnabled,
    tableRef: packsTableRef,
    initialFilters: history?.location?.pathname?.endsWith('packs') && history?.location?.state?.tableFilters,
  });
  const {
    sendQuery: bundleInitiatorsSendQuery,
    limit: bundleInitiatorsLimit,
    offset: bundleInitiatorsOffset,
    onSortChanged: bundleInitiatorsOnSortChanged,
    onFilterChanged: bundleInitiatorsOnFilterChanged,
    setAndSendLimit: bundleInitiatorsSetLimit,
    setAndSendOffset: bundleInitiatorsSetOffset,
  } = useTable({
    sendQuery: getBundleInitiatorsEnabled,
    tableRef: bundleInitiatorsTableRef,
    useNewFilters: true,
  });

  const {
    sendQuery: bundlePacksSendQuery,
    limit: bundlePacksLimit,
    offset: bundlePacksOffset,
    onSortChanged: bundlePacksOnSortChanged,
    onFilterChanged: bundlePacksOnFilterChanged,
    setAndSendLimit: bundlePacksSetLimit,
    setAndSendOffset: bundlePacksSetOffset,
  } = useTable({
    sendQuery: getBundlePacksEnabled,
    tableRef: bundlePacksTableRef,
    useNewFilters: true,
  });
  const {
    openModal,
    editableRecord,
    onCloseModal,
    onDeleteClick,
    isFormPristine,
    formValuesRef,
    isOpenConfirm,
    onConfirmClose,
    closeConfirm,
    isOpenModal,
    setEditableRecord,
  } = useScreen({
    screenRef,
  });

  const agreementsOptionsModify = useMemo(() => agreementsOptions.map(item => ({
    value: item.value,
    label: `${item.companyName} - ${item.label}`,
    currency: item.currency,
    fromTz: item.actualEffectiveFromTz,
    tillTz: item.actualEffectiveTillTz,
  })), [agreementsOptions]);
  const filteredServicesOptions = useMemo(() => servicesOptions
    .filter(item => item.type === 1), [servicesOptions]);
  const filteredPacksOptions = useMemo(() => servicesOptions
    .filter(item => item.type === 2 || item.type === 3), [servicesOptions]);
  const filteredBundleInitiatorsOptions = useMemo(() => servicesOptions
    .filter(item => item.type === 6), [servicesOptions]);
  const filteredBundlePacksOptions = useMemo(() => servicesOptions
    .filter(item => item.type === 7), [servicesOptions]);
  const timezonesOptions = useMemo(() => timezones
    .filter((v, i, a) => a.findIndex(I => (I.areaName === v.areaName)) === i)
    .map(I => ({
      value: I.areaName,
      label: I.label,
    })), [timezones]);

  const {
    openCustomViewDrawer,
    isOpenCustomViewDrawer,
    columnNames,
    addButtonText,
    filteredColumns,
    onSaveCustomView,
    closeCustomViewDrawer,
    onSubmit,
    modalTitle,
    FormComponent,
  } = useMemo(() => {
    switch (activeTab) {
      case 0: return {
        openCustomViewDrawer: servicesOpenCustomViewDrawer,
        addButtonText: 'CONTROLS.SERVICES_ENABLED.ADD',
        isOpenCustomViewDrawer: servicesIsOpenCustomViewDrawer,
        columnNames: SERVICES_ENABLED,
        filteredColumns: servicesFilteredColumns,
        onSaveCustomView: servicesOnSaveCustomView,
        closeCustomViewDrawer: servicesCloseCustomViewDrawer,
        onSubmit: (body) => {
          (body?.id ? putServiceEnabled : postServiceEnabled)({
            body,
            successCallback: () => {
              servicesSendQuery();
              onCloseModal({ isSave: true });
            },
          });
        },
        modalTitle: 'SCREENS.SERVICES_ENABLED.SERVICE_ENABLED',
        FormComponent: ServiceEnabledForm,
      };
      case 1: return {
        openCustomViewDrawer: packsOpenCustomViewDrawer,
        addButtonText: 'CONTROLS.PACKS_ENABLED.ADD',
        isOpenCustomViewDrawer: packsIsOpenCustomViewDrawer,
        columnNames: PACKS_ENABLED,
        filteredColumns: packsFilteredColumns,
        onSaveCustomView: packsOnSaveCustomView,
        closeCustomViewDrawer: packsCloseCustomViewDrawer,
        onSubmit: (body) => {
          (body?.id ? putPackEnabled : postPackEnabled)({
            body,
            successCallback: () => {
              packsSendQuery();
              onCloseModal({ isSave: true });
            },
          });
        },
        modalTitle: 'SCREENS.PACKS_ENABLED.PACK',
        FormComponent: PacksEnabledForm,
      };
      case 2: return {
        openCustomViewDrawer: bundleInitiatorsOpenCustomViewDrawer,
        addButtonText: 'CONTROLS.SERVICES_ENABLED.ADD_BUNDLE_INITIATOR',
        isOpenCustomViewDrawer: bundleInitiatorsIsOpenCustomViewDrawer,
        columnNames: BUNDLES_ENABLED,
        filteredColumns: bundleInitiatorsFilteredColumns,
        onSaveCustomView: bundleInitiatorsOnSaveCustomView,
        closeCustomViewDrawer: bundleInitiatorsCloseCustomViewDrawer,
        onSubmit: ({ id, ...body }) => {
          (id ? patchBundleInitiatorEnabled : postBundleInitiatorEnabled)({
            body: id ? {
              id,
              hideFromClient: body.hideFromClient,
            } : body,
            successCallback: () => {
              bundleInitiatorsSendQuery();
              onCloseModal({ isSave: true });
            },
          });
        },
        modalTitle: 'SCREENS.SERVICES_ENABLED.BUNDLE_INITIATOR_ENABLED',
        FormComponent: BundleInitiatorEnabledForm,
      };
      case 3: return {
        openCustomViewDrawer: bundlePacksOpenCustomViewDrawer,
        addButtonText: 'CONTROLS.SERVICES_ENABLED.ADD_BUNDLE_PACK',
        isOpenCustomViewDrawer: bundlePacksIsOpenCustomViewDrawer,
        columnNames: BUNDLES_ENABLED,
        filteredColumns: bundlePacksFilteredColumns,
        onSaveCustomView: bundlePacksOnSaveCustomView,
        closeCustomViewDrawer: bundlePacksCloseCustomViewDrawer,
        onSubmit: ({ id, ...body }) => {
          (id ? patchBundlePackEnabled : postBundlePackEnabled)({
            body: id ? {
              id,
              autoExtend: body.autoExtend,
            } : body,
            successCallback: () => {
              bundlePacksSendQuery();
              onCloseModal({ isSave: true });
            },
          });
        },
        modalTitle: 'SCREENS.SERVICES_ENABLED.BUNDLE_PACK_ENABLED',
        FormComponent: BundlePackEnabledForm,
      };
      default: return {
        openCustomViewDrawer: Function.prototype,
        addButtonText: '',
        isOpenCustomViewDrawer: false,
        filteredColumns: [],
        onSaveCustomView: Function.prototype,
        closeCustomViewDrawer: Function.prototype,
        onDeleteConfirm: Function.prototype,
        onSubmit: Function.prototype,
        modalTitle: '',
        PacksEnabledForm: Function.prototype,
      };
    }
  }, [
    activeTab,
    servicesIsOpenCustomViewDrawer,
    packsIsOpenCustomViewDrawer,
    bundleInitiatorsIsOpenCustomViewDrawer,
    bundlePacksIsOpenCustomViewDrawer,
  ]);

  const handleChangeActiveTab = (newVal) => {
    setActiveTab(newVal);
    history.push(`/services-enabled/${SERVICES_ENABLED_SCREEN_TABS[newVal].id}`);
  };
  const onRowClicked = ({ data }) => {
    const agreementData = agreementsDictionary[data.agreementId];

    setEditableRecord({
      ...data,
      effectiveFrom: new Date(moment(data.effectiveFrom).tz(agreementData?.actualEffectiveFromTz).format('YYYY-MM-DDTHH:mm:ss')),
      effectiveTill: data.effectiveTill
        ? new Date(moment(data.effectiveTill).tz(agreementData?.actualEffectiveTillTz).format('YYYY-MM-DDTHH:mm:ss'))
        : null,
    });
    openModal('view');
  };
  const onBundleInitialRowClicked = ({ data }) => {
    setEditableRecord({
      ...data,
      effectiveFrom: new Date(moment(data.effectiveFrom)),
      effectiveTill: data.effectiveTill ? new Date(moment(data.effectiveTill)) : null,
    });
    openModal('view');
  };
  const onBundlePackRowClicked = ({ data }) => {
    setEditableRecord({
      ...data,
    });
    openModal('view');
  };
  const renderPanel = (tab) => {
    switch (tab.id) {
      case 'services': return (
        <Table
          dataTestIdPrefix="/bo/services-enabled/services"
          columnDefs={SERVICES_ENABLED}
          rowData={servicesEnabled}
          onRowClicked={onRowClicked}
          showFilters={false}
          limit={servicesLimit}
          total={servicesEnabledMeta.size}
          page={servicesOffset}
          filteredColumns={servicesFilteredColumns}
          onChangePage={servicesSetOffset}
          onChangeLimit={servicesSetLimit}
          sendQuery={servicesSendQuery}
          isPending={isPendingGetServicesEnabled || !lastUpdatedGetServicesEnabled || isPendingGetAgreementsDictionary}
          tableRef={servicesTableRef}
          onFilterChanged={servicesOnFilterChanged}
          onSortChanged={servicesOnSortChanged}
        />
      );
      case 'packs': return (
        <Table
          dataTestIdPrefix="/bo/services-enabled/packs"
          columnDefs={PACKS_ENABLED}
          rowData={packsEnabled}
          onRowClicked={onRowClicked}
          showFilters={false}
          limit={packsLimit}
          total={packsEnabledMeta.size}
          page={packsOffset}
          filteredColumns={packsFilteredColumns}
          onChangePage={packsSetOffset}
          onChangeLimit={packsSetLimit}
          sendQuery={packsSendQuery}
          isPending={isPendingGetPacksEnabled || !lastUpdatedGetPacksEnabled || isPendingGetAgreementsDictionary}
          tableRef={packsTableRef}
          onFilterChanged={packsOnFilterChanged}
          onSortChanged={packsOnSortChanged}
        />
      );
      case 'bundle-initiators': return (
        <Table
          dataTestIdPrefix="/bo/services-enabled/bundle-initiators"
          columnDefs={BUNDLES_ENABLED}
          rowData={bundleInitiatorsEnabled}
          onRowClicked={onBundleInitialRowClicked}
          showFilters={false}
          limit={bundleInitiatorsLimit}
          total={bundleInitiatorsEnabledMeta.size}
          page={bundleInitiatorsOffset}
          filteredColumns={bundleInitiatorsFilteredColumns}
          onChangePage={bundleInitiatorsSetOffset}
          onChangeLimit={bundleInitiatorsSetLimit}
          sendQuery={bundleInitiatorsSendQuery}
          isPending={
            isPendingGetBundleInitiatorsEnabled
            || !lastUpdatedGetBundleInitiatorsEnabled
          }
          tableRef={bundleInitiatorsTableRef}
          onFilterChanged={bundleInitiatorsOnFilterChanged}
          onSortChanged={bundleInitiatorsOnSortChanged}
        />
      );
      case 'bundle-packs': return (
        <Table
          dataTestIdPrefix="/bo/services-enabled/bundle-packs"
          columnDefs={BUNDLES_ENABLED}
          rowData={bundlePacksEnabled}
          onRowClicked={onBundlePackRowClicked}
          showFilters={false}
          limit={bundlePacksLimit}
          total={bundlePacksEnabledMeta.size}
          page={bundlePacksOffset}
          filteredColumns={bundlePacksFilteredColumns}
          onChangePage={bundlePacksSetOffset}
          onChangeLimit={bundlePacksSetLimit}
          sendQuery={bundlePacksSendQuery}
          isPending={
            isPendingGetBundlePacksEnabled
            || !lastUpdatedGetBundlePacksEnabled
          }
          tableRef={bundlePacksTableRef}
          onFilterChanged={bundlePacksOnFilterChanged}
          onSortChanged={bundlePacksOnSortChanged}
        />
      );
      default: return null;
    }
  };

  useEffect(() => {
    getServicesDictionary();
    getAgreementsDictionary();
    getCompaniesDictionary();
  }, []);

  return (
    <div>
      <ScrollableTabs
        activeTab={activeTab}
        setActiveTab={handleChangeActiveTab}
        tabsList={SERVICES_ENABLED_SCREEN_TABS}
        styles={{ wrapper: { flex: 1 } }}
        renderPanel={renderPanel}
        alwaysShowTabsPanelElement
        elementInTabsPanel={(
          <div style={{ display: 'flex', flexDirection: 'row', gap: 14 }}>
            <Button
              type="default"
              view="outlined"
              color="primary"
              icon="add-column-right"
              onClick={openCustomViewDrawer}
              text="CONTROLS.COLUMNS"
              data-testid={`${window.location.pathname}/table/columns`}
              dense
            />
            <Button
              icon="plus"
              color="primary"
              onClick={() => openModal('add')}
              text={addButtonText}
              data-testid={`${window.location.pathname}/create-instance/${SERVICES_ENABLED_SCREEN_TABS[activeTab]?.id}`}
              dense
            />
          </div>
        )}
      />
      {
        isOpenCustomViewDrawer && (
          <CustomColumnsView
            initialValues={filteredColumns}
            columnsNames={columnNames}
            onSubmit={onSaveCustomView}
            onClose={closeCustomViewDrawer}
            isOpen
          />
        )
      }
      {
        isOpenModal && (
          <Modal
            mode={isOpenModal}
            closeModal={onCloseModal}
            title={modalTitle}
            editableRecord={editableRecord}
          >
            <FormComponent
              onSubmit={onSubmit}
              onCancel={onCloseModal}
              initialValues={editableRecord}
              mode={isOpenModal}
              changeMode={openModal}
              onDelete={onDeleteClick}
              isFormPristine={isFormPristine}
              isPending={
                isPendingPostServiceEnabled
                || isPendingPutServiceEnabled
                || isPendingPostPackEnabled
                || isPendingPutPackEnabled
                || isPendingPostBundleInitiatorEnabled
                || isPendingPostBundlePackEnabled
                || isPendingPatchBundleInitiatorEnabled
                || isPendingPatchBundlePackEnabled
              }
              formValuesRef={formValuesRef}
              agreementsOptions={agreementsOptionsModify}
              servicesOptions={filteredServicesOptions}
              packsOptions={filteredPacksOptions}
              bundleInitiatorsOptions={filteredBundleInitiatorsOptions}
              bundlePacksOptions={filteredBundlePacksOptions}
              timezonesOptions={timezonesOptions}
            />
          </Modal>
        )
      }
      {
        isOpenConfirm && (
          <ConfirmModal
            closeModal={closeConfirm}
            onConfirm={onConfirmClose}
          />
        )
      }
    </div>
  );
};

export default ServicesEnabledScreen;
