import React, { useRef, useEffect, useMemo } from 'react';
import cx from 'classnames';
import Highlighter from 'react-highlight-words';

import MediaPreview from 'components/MediaPreview';

import styles from './TemplatePreview.module.scss';

const TemplatePreview = ({
  name,
  file,
  headerText,
  bodyText,
  footerText,
  buttons = [],
  buttonsOutside = [],
  cards,
  html,
  channel,
}) => {
  const shadowRootRef = useRef();

  const {
    highlightTag,
    searchWords,
  } = useMemo(() => {
    if (channel === 4) {
      return {
        // eslint-disable-next-line react/no-unstable-nested-components
        highlightTag: ({ children }) => {
          const target = children.replace(/\*([^*]+?)\*/gm, '$1')
            .replace(/~([^~]+?)~/gm, '$1')
            .replace(/_([^_]+?)_/gm, '$1')
            .replace(/```([^```]+?)```/gm, '$1');
          if (children.match(/~([^~]+?)~/gm)?.length) {
            return <s>{target}</s>;
          }
          if (children.match(/\*([^*]+?)\*/gm)?.length) {
            return <b>{target}</b>;
          }
          if (children.match(/_([^_]+?)_/gm)?.length) {
            return <i>{target}</i>;
          }
          if (children.match(/```([^```]+?)```/gm)?.length) {
            return <kbd>{target}</kbd>;
          }
          return <span className={styles.highlight}>{target}</span>;
        },
        searchWords: [
          /{{.([^]+?)}}/gm,
          /\*([^*]+?)\*/gm,
          /~([^~]+?)~/gm,
          /_([^_]+?)_/gm,
          /```([^```]+?)```/gm,
        ],
      };
    }
    return {
      highlightTag: 'span',
      searchWords: [/{{.([^]+?)}}/gm],
    };
  }, [channel]);

  const renderPreview = (card, index) => (
    <div className={styles.card} key={index}>
      {(card?.file || file) && (
      <MediaPreview file={card?.file || file} />
      )}
      {(card?.headerText || headerText) && (
        <Highlighter
          className={cx(styles.text, styles.text_header)}
          highlightClassName={styles.highlight}
          highlightTag={highlightTag}
          searchWords={searchWords}
          textToHighlight={card?.headerText || headerText}
          autoEscape={false}
        />
      )}
      {(card?.bodyText || bodyText) && (
        <Highlighter
          className={styles.text}
          highlightClassName={styles.highlight}
          highlightTag={highlightTag}
          searchWords={searchWords}
          textToHighlight={card?.bodyText || bodyText}
          autoEscape={false}
        />
      )}
      {(card?.footerText || footerText) && (
        <Highlighter
          className={cx(styles.text, styles.text_footer)}
          highlightClassName={styles.highlight}
          highlightTag={highlightTag}
          searchWords={searchWords}
          textToHighlight={card?.footerText || footerText}
          autoEscape={false}
        />
      )}
      {(card?.buttons || buttons)?.map(I => (
        <div className={styles.button} key={I}>
          {I}
        </div>
      ))}
      {index + 1 < cards?.length && (
      <div className={styles.divider} />
      )}
    </div>
  );

  useEffect(() => {
    if (shadowRootRef.current && html) {
      const hostElement = shadowRootRef.current;

      if (!hostElement.shadowRoot) {
        const shadowRoot = hostElement.attachShadow({ mode: 'open' });

        shadowRoot.innerHTML = html;
      } else {
        hostElement.shadowRoot.innerHTML = html;
      }
    }
  }, [html]);

  return (
    <div className={styles.container}>
      <span className={styles.title}>{name}</span>
      <div className={styles.contentWrapper}>
        {cards ? (
          <>
            {bodyText && (
              <div className={styles.text}>
                {bodyText}
              </div>
            )}
            {cards?.map((card, index) => (
              renderPreview(card, index)
            ))}
          </>
        ) : renderPreview()}
      </div>
      {!!buttonsOutside?.length && (
        <>
          <div className={styles.divider} />
          {buttonsOutside?.map(I => <div key={I} className={styles.button}>{I}</div>)}
        </>
      )}
      {html && (
        <div ref={shadowRootRef} style={{ zoom: 0.5 }} />
      )}
    </div>
  );
};

export default TemplatePreview;
