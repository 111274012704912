import React, { useMemo, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { isEqual, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Callout, Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import {
  TextField,
  CheckboxField,
  TextAreaField,
  DropdownField,
} from 'components/fields';
import { COMPANY_SCHEMA } from 'consts/validationSchemas';
import IsFormChanged from 'components/forms/IsFormChanged';
import { FormButtonsGroup } from 'components/blocks';
import { useDictionariesApi } from 'hooks/api';
import { useContentShadow } from 'hooks';

import './style.scss';

const CompanyForm = ({
  initialValues = {
    isReseller: false,
    isModerated: false,
    ownResellersAllowed: false,
    twoFactorAuthentication: false,
    isActive: true,
  },
  onSubmit,
  onCancel,
  onDelete,
  mode,
  changeMode,
  isPending,
  isFormPristine,
  formValuesRef,
}) => {
  const { t } = useTranslation();
  const fieldStyle = ({ width, marginTop } = {}) => ({
    container: {
      width,
    },
    label: {
      marginTop,
    },
  });

  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);
  const contentRef = useRef(null);
  const {
    showTopShadow,
    showBottomShadow,
  } = useContentShadow(contentRef);
  const { ownLegalEntitiesOptions } = useDictionariesApi();

  const isView = useMemo(() => mode === 'view', [mode]);

  const changeToEdit = () => changeMode('edit');
  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }

    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={COMPANY_SCHEMA}
      validateOnMount
    >
      {({
        handleSubmit, errors, setFieldTouched, values,
      }) => (
        <Form className="company-form">
          {showTopShadow && <div className="company-form__shadow" />}
          <div className="company-form__content" ref={contentRef}>
            <Field
              name="name"
              label="INSTANCES.COMPANY"
              component={TextField}
              styles={fieldStyle()}
              placeholder="PLACEHOLDERS.COMPANIES.PARTNER_NAME"
              disabled={isView}
              isRequired={!isView}
              fill
            />
            <Field
              name="parentCompanyLEId"
              label="INSTANCES.OWN_LEGAL_ENTITY"
              component={DropdownField}
              options={ownLegalEntitiesOptions}
              styles={fieldStyle({ marginTop: 24 })}
              placeholder="PLACEHOLDERS.SELECT"
              disabled={mode !== 'add'}
              isRequired={mode === 'add'}
              fill
            />
            <Field
              name="comment"
              label="INSTANCES.DESCRIPTION"
              component={TextAreaField}
              styles={fieldStyle({ marginTop: 24 })}
              minRows={2}
              placeholder="PLACEHOLDERS.ENTER_DESCRIPTION"
              disabled={isView}
            />
            <Field
              name="isActive"
              component={CheckboxField}
              text="INSTANCES.ENABLED"
              styles={fieldStyle({ marginTop: 24 })}
              disabled={isView}
            />
            <Field
              name="isReseller"
              component={CheckboxField}
              text="INSTANCES.COMPANIES.IS_RESELLER"
              styles={fieldStyle({ marginTop: 16 })}
              disabled={isView}
            />
            <Field
              name="ownResellersAllowed"
              component={CheckboxField}
              text="INSTANCES.COMPANIES.RESELLERS_ALLOWED"
              styles={fieldStyle({ marginTop: 16 })}
              disabled={isView}
            />
            <Field
              name="isModerated"
              component={CheckboxField}
              text="INSTANCES.COMPANIES.MODERATION"
              styles={fieldStyle({ marginTop: 16 })}
              disabled={isView}
            />
            <Field
              name="twoFactorAuthentication"
              component={CheckboxField}
              text="INSTANCES.COMPANIES.TWO_FACTOR_AUTHENTICATION"
              styles={fieldStyle({ marginTop: 16 })}
              disabled={isView}
            />
            {values.twoFactorAuthentication && (
              <Callout
                view="smooth"
                title={t('INSTANCES.COMPANIES.WARNING')}
                icon={<Icon icon="info-sign" color="var(--grey30)" size={20} />}
                style={{ marginTop: 5 }}
                dense
              >
                {t('TOASTS.COMPANIES.2FA_WARNING')}
              </Callout>
            )}
          </div>
          <FormButtonsGroup
            onDelete={onDelete}
            onCancel={onCancel}
            setFieldTouched={setFieldTouched}
            errors={errors}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            isPending={isPending}
            showShadow={showBottomShadow}
          />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
        </Form>
      )}
    </Formik>
  );
};

export default CompanyForm;
