/* eslint-disable camelcase */
import { sortBy } from 'lodash';

import endpoints from 'consts/endpoints';

export default () => ({
  url: endpoints.getItemsWithDefinitionsUrl(),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: ({ data }) => {
    const currentDate = new Date();

    const definitionsList = data.reduce((result, item) => {
      if (item.dictionaryId === 1) {
        const { dictionaryId, key, definitions } = item;

        const filteredDefinitions = definitions.reduce((acc, def) => {
          const { definition, effective_from, effective_till } = def;

          const isEffectiveFromValid = !effective_from || new Date(effective_from) <= currentDate;
          const isEffectiveTillValid = !effective_till || new Date(effective_till) >= currentDate;

          if (isEffectiveFromValid && isEffectiveTillValid) {
            const label = definition?.name_en || definition?.name || key;
            acc.push({ dictionaryId, value: key, label });
          }

          return acc;
        }, []);

        return [...result, ...filteredDefinitions];
      }

      return [...result];
    }, []);

    return {
      countriesOptions: sortBy(definitionsList, ['label']),
      countriesDictionary: definitionsList.reduce((acc, { value, label }) => {
        acc[value] = {
          name: label,
        };

        return acc;
      }, {}),
    };
  },
  update: {
    countriesOptions: (oldValue, newValue) => newValue,
    countriesDictionary: (oldValue, newValue) => newValue,
  },
});
