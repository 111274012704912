import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useImperativeHandle,
} from 'react';
import { List, AutoSizer } from 'react-virtualized';
import cx from 'classnames';

import {
  useServicesApi,
  useDictionariesApi,
} from 'hooks/api';
import Spinner from '@setproduct-ui/core/Spinner';
import Color from '@setproduct-ui/styles/color.module.css';

import {
  makeTreeFromRawFlatData,
  makeTreeFromImportPreviewData,
  modifyNodeWithChildrenById,
  recalculateDisplayLinksFor,
  removeNodeById,
  modifyNodeById,
  addChildToNode,
  recalculateSiblingValues,
  recalculateLinksAndSiblings,
  recalculateMetaData,
  getTreeKey,
} from './utils';
import Row from './Row';
import styles from './TreeTable.module.scss';
import { ReactComponent as CirclePlusIcon } from './icons/circle-plus.svg';

const TreeTable = ({
  scopeId,
  serviceType,
  currency,
  readOnly,
  imperativeRef,
  activeRowRef,
  listRef,
  highlightActiveRow,
  externalData,
  parentInfoRules,
  initialLevel,
}) => {
  const listWrapperRef = useRef();
  const headerRef = useRef();
  const maxLevelRef = useRef(1);
  const [treeData, setTreeData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [activeRow, setActiveRow] = useState('');

  const {
    resourceAttributesDictionary,
    getScopeItems,
    postScopeItem,
    patchScopeItem,
    deleteScopeItem,
    isPendingPostScopeItem,
    isPendingPatchScopeItem,
    isPendingDeleteScopeItem,
    isPendingGetScopeItems,
  } = useServicesApi();
  const {
    resourcesDictionary,
    definitionsList,
  } = useDictionariesApi();

  const gridTemplateColumns = useMemo(() => {
    if (readOnly && (serviceType === 3 || serviceType === 6)) {
      return `2px 32px 24px minmax(${225 + 24 * maxLevelRef.current}px, 1fr) minmax(220px, 1fr)`;
    }
    if (serviceType === 3 || serviceType === 6) {
      return `2px 32px 24px minmax(${225 + 24 * maxLevelRef.current}px, 1fr) minmax(220px, 1fr) 68px`;
    }
    if (readOnly && (serviceType === 2 || serviceType === 7)) {
      return `2px 32px 24px minmax(${225 + 24 * maxLevelRef.current}px, 1fr) minmax(220px, 1fr) repeat(2, minmax(200px, 1fr))`;
    }
    if (serviceType === 2 || serviceType === 7) {
      return `2px 32px 24px minmax(${225 + 24 * maxLevelRef.current}px, 1fr) minmax(220px, 1fr) repeat(2, minmax(200px, 1fr)) 68px`;
    }
    if (readOnly) {
      return `2px 32px 24px minmax(${225 + 24 * maxLevelRef.current}px, 1fr) minmax(220px, 1fr) minmax(250px, 1fr) repeat(2, minmax(200px, 1fr))`;
    }
    return `2px 32px 24px minmax(${225 + 24 * maxLevelRef.current}px, 1fr) minmax(220px, 1fr) minmax(250px, 1fr) repeat(2, minmax(200px, 1fr)) 68px`;
  }, [maxLevelRef.current, readOnly, serviceType]);
  const priceTypeColumnName = useMemo(() => {
    if (serviceType === 1) {
      return 'Price type';
    }
    if (serviceType === 2 || serviceType === 3 || serviceType === 6 || serviceType === 7) {
      return 'Status';
    }

    return 'Unknown service type';
  }, [currency, serviceType]);
  const flatTreeData = useMemo(() => {
    const result = [];

    const traverse = ({ children, ...node }) => {
      result.push({
        ...node,
        childrenSize: children?.length,
      });

      if (children && children.length > 0) {
        children.forEach(child => traverse(child));
      }
    };

    treeData.forEach(root => traverse(root));

    return [...result, { id: 'addNewRoot' }];
  }, [treeData]);

  const onChevronClick = (nodeId) => {
    setTreeData(modifyNodeWithChildrenById({
      tree: treeData,
      nodeId,
      updateNodeCallback: node => ({
        collapsed: !node.collapsed,
      }),
      updateChildCallback: ({ parent }) => ({
        hiddenByCollapse: parent.collapsed || parent.hiddenByCollapse,
      }),
    }));
    listRef.current.recomputeRowHeights();
  };
  const onSearchChange = (nodeId, searchValue) => {
    const treeDataWithUpdatedSearchFlags = modifyNodeWithChildrenById({
      tree: treeData,
      nodeId,
      updateNodeCallback: () => ({
        searchValue,
      }),
      updateChildCallback: ({
        node: {
          dictionaryId,
          matchAttributeValues,
          level,
          hiddenBySearch,
        },
        parent,
        initialParent,
      }) => {
        if (level - 1 === initialParent.level) {
          if (searchValue) {
            const resourceCellLabel = (level === 2
              ? resourcesDictionary[matchAttributeValues?.[0]]?.name
              : dictionaryId
                ? definitionsList[dictionaryId]?.find(item => item.value === matchAttributeValues?.[0])?.label
                : matchAttributeValues?.[0])?.toLowerCase();

            return {
              hiddenBySearch: !resourceCellLabel?.includes(searchValue.toLowerCase()),
              searchIsActive: true,
              levelOfSearch: initialParent.level,
            };
          }

          if (hiddenBySearch) {
            return {
              hiddenBySearch: false,
              searchIsActive: false,
            };
          }
        }

        return {
          hiddenBySearch: searchValue ? parent.hiddenBySearch : false,
          searchIsActive: searchValue && !parent.hiddenBySearch,
          levelOfSearch: initialParent.level,
        };
      },
    });

    setTreeData(recalculateDisplayLinksFor(treeDataWithUpdatedSearchFlags));
    listRef.current.recomputeRowHeights();
  };
  const onDeleteRow = (id, callback) => {
    if (id === 'newRow') {
      setActiveRow('');
      setSelectedRow('');
      setTreeData(prev => recalculateDisplayLinksFor(removeNodeById(prev, id)));
    } else {
      deleteScopeItem({
        id,
        successCallback: () => {
          setActiveRow('');
          setSelectedRow('');
          setTreeData(prev => recalculateLinksAndSiblings(removeNodeById(prev, id)));
          listRef.current.recomputeRowHeights();
          callback?.();
        },
      });
    }
  };
  const onChangeActiveRow = async (rowId, formRef, rowRef) => {
    const savedInitialValues = localStorage.getItem('savedInitialValues');

    if (rowId) {
      if (activeRow && activeRow !== rowId && savedInitialValues) {
        highlightActiveRow();
        return;
      }
      if (activeRow === 'newRow') {
        onDeleteRow(activeRow);
      }
      activeRowRef.current = rowRef.current;
    } else {
      activeRowRef.current = null;
    }
    setActiveRow(rowId);
    setSelectedRow(rowId);
  };
  const addChild = (parent) => {
    setTreeData(prev => recalculateMetaData(addChildToNode({
      tree: prev,
      parentNodeId: parent.id,
      resourceAttributesDictionary,
      maxLevelRef,
    })));
    listRef.current.recomputeRowHeights();
    setActiveRow('newRow');
    setSelectedRow('newRow');
  };
  const addParent = () => {
    if (!activeRow) {
      setActiveRow('newRow');
      setSelectedRow('newRow');
      setTreeData(prev => [
        ...prev,
        {
          id: 'newRow',
          billingRule: null,
          childrenMatchAttributeId: 4,
          matchAttributeId: 3,
          matchAttributeValues: null,
          parentId: null,
          price: null,
          priceType: null,
          quantityRule: null,
          scaleCounterGroupBy: null,
          scaleType: null,
          scopeId,
          level: 1,
          treeKey: getTreeKey(1, prev.length),
          hiddenBySearch: false,
          hiddenByCollapse: false,
          siblingValues: prev?.map(I => I.matchAttributeValues?.[0]),
        },
      ]);
    }
  };

  const onSaveRow = ({
    id,
    matchAttributeValues,
    debugName,
    priceTypeCell,
    level,
    price,
    ...values
  }, successCallback) => {
    (id === 'newRow' ? postScopeItem : patchScopeItem)({
      body: {
        scopeId,
        id,
        matchAttributeValues: !matchAttributeValues || matchAttributeValues === 'null'
          ? []
          // todo: нужно чтобы значения колонки resource всех уровней были строками
          : [String(matchAttributeValues)],
        price: priceTypeCell === '2/price'
          ? price?.map?.(item => ({
            price: +item.price,
            tier: item.tier === null ? null : +item.tier,
          }))
          : price ? +price : price,
        ...values,
      },
      successCallback: ({ data }) => {
        setActiveRow('');
        setSelectedRow('');
        setTreeData(prev => recalculateSiblingValues(modifyNodeById({
          tree: prev,
          nodeId: id,
          updateNodeCallback: node => ({
            ...node,
            ...data,
          }),
        })));
        listRef.current.recomputeRowHeights();
        successCallback?.();
      },
    });
  };
  const getNodesAndMakeTree = async () => {
    await localStorage.removeItem('savedInitialValues');
    setActiveRow('');
    setSelectedRow({});
    maxLevelRef.current = 1;

    if (externalData) {
      setTreeData(
        makeTreeFromImportPreviewData(externalData, resourceAttributesDictionary, maxLevelRef, initialLevel, parentInfoRules),
      );
    } else {
      getScopeItems({
        serviceScopeId: scopeId,
        successCallback: ({ data }) => {
          if (data.length) {
            setTreeData(makeTreeFromRawFlatData(data, resourceAttributesDictionary, maxLevelRef));
          } else {
            setTreeData([]);
          }
        },
      });
    }
  };
  const itemRenderer = ({
    index, style,
  }) => {
    const itemData = flatTreeData[index];

    if (itemData?.hiddenByCollapse || itemData.hiddenBySearch) {
      return null;
    }
    if (itemData.id === 'addNewRoot') {
      if (readOnly) {
        return null;
      }

      return (
        <div
          key={itemData.id}
          style={style}
        >
          <CirclePlusIcon
            icon="add"
            onClick={addParent}
            className={cx(styles.button, Color.primary, {
              [styles.button_disabled]: !!activeRow,
            })}
            title={activeRow ? 'Keep or delete the previous node before adding a new one' : ''}
          />
        </div>
      );
    }

    return (
      <div
        key={itemData.id}
        style={{
          ...style,
          gridTemplateColumns,
        }}
        className={styles.item}
      >
        <Row
          data={itemData}
          onDeleteRow={onDeleteRow}
          addChild={addChild}
          isPending={isPendingPostScopeItem || isPendingDeleteScopeItem || isPendingPatchScopeItem}
          onSubmit={onSaveRow}
          activeRow={activeRow}
          activeRowRef={activeRowRef}
          onChangeActiveRow={onChangeActiveRow}
          isPendingDelete={isPendingDeleteScopeItem}
          isPendingEdit={isPendingPatchScopeItem || isPendingPostScopeItem}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          serviceType={serviceType}
          serviceCurrency={currency}
          getNodesListAndMakeTree={getNodesAndMakeTree}
          readOnly={readOnly}
          onChevronClick={onChevronClick}
          onSearchChange={onSearchChange}
          flatTreeData={flatTreeData}
          rowIndex={index}
          listRef={listRef}
        />
      </div>
    );
  };
  const getRowHeight = ({ index }) => {
    const itemData = flatTreeData[index];

    if (itemData.id === activeRow) {
      const savedInitialValues = localStorage.getItem('savedInitialValues');

      if (savedInitialValues) {
        const parsedValues = JSON.parse(savedInitialValues);

        if (parsedValues?.priceTypeCell === '2/price') {
          return 32 + 32 * (parsedValues.price?.length || 0) + 32;
        }
      }
    }
    if (itemData?.hiddenByCollapse || itemData?.hiddenBySearch) {
      return 0;
    }
    const defaultItemHeight = itemData.childrenMatchAttributeId && !readOnly ? 53 : 32;

    if (itemData.priceType === 2) {
      return defaultItemHeight + 32 * (itemData.price?.length || 0) + (itemData.scaleCounterGroupBy ? 32 : 0);
    }

    return defaultItemHeight;
  };

  useImperativeHandle(imperativeRef, () => ({
    redrawTree() {
      getNodesAndMakeTree();
    },
  }));

  useEffect(() => {
    getNodesAndMakeTree();
  }, [scopeId, externalData]);
  useEffect(() => {
    const element = listWrapperRef.current?.children[0]?.children[0];
    const onScroll = ({ target }) => {
      headerRef.current.scrollLeft = target.scrollLeft;
    };

    element?.addEventListener?.('scroll', onScroll);

    return () => {
      element?.removeEventListener?.('scroll', onScroll);
    };
  }, [listWrapperRef.current, isPendingGetScopeItems]);
  useEffect(() => {
    if (activeRow === 'newRow') {
      listRef.current.scrollToRow(flatTreeData.findIndex(I => I.id === 'newRow'));
    }
  }, [activeRow, flatTreeData]);

  return (
    isPendingGetScopeItems
      ? <Spinner className={styles.spinner} />
      : (
        <div className={styles.content}>
          <div
            className={cx(styles.header, { [styles.header_readOnly]: readOnly })}
            style={{ gridTemplateColumns }}
            ref={headerRef}
          >
            <div />
            <div />
            <div />
            <div>Resources</div>
            <div>{priceTypeColumnName}</div>
            {serviceType === 1 && (<div>{`Price, ${currency}`}</div>)}
            {serviceType !== 3 && serviceType !== 6 && (
              <>
                <div>Billing rule</div>
                <div>Quantity rule</div>
              </>
            )}
            {!readOnly && (
              <div />
            )}
          </div>
          <div className={styles.list} ref={listWrapperRef}>
            <AutoSizer>
              {({ height, width }) => (
                <List
                  ref={listRef}
                  width={width}
                  height={height}
                  rowCount={flatTreeData.length}
                  rowHeight={getRowHeight}
                  rowRenderer={itemRenderer}
                />
              )}
            </AutoSizer>
          </div>
        </div>
      )
  );
};

export default TreeTable;
