import React, { useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button as ButtonSource, Icon } from '@blueprintjs/core';
import { useTranslation } from "react-i18next";

import Type from './_type.module.css';
import View from './_view.module.css';

import Color from '../../styles/color.module.css';
import { ThemeContext } from '../ThemeContext';

/**
  __Component 'Button'__
* */

const Button = React.forwardRef((props, ref) => {
  const {
    type = 'default',
    view = 'filled',
    color = 'default',
    active,
    dense,
    iconColor,
    className,
    icon,
    iconSize,
    iconStyle,
    text,
    title,
    ...restProps
  } = props;
  const { t } = useTranslation();

  const size = useMemo(() => {
    if (iconSize) {
      return iconSize;
    }

    if (dense) {
      switch (type) {
        case 'action': return 24;
        case 'circle': return 20;
        default: return 12;
      }
    }

    switch (type) {
      case 'action': return 32;
      case 'circle': return 24;
      default: return 20;
    }
  }, [iconSize, dense]);
  const leftIcon = useMemo(() => {
    if (icon) {
      if (typeof icon === 'string') {
        return (
          <Icon
            icon={icon}
            size={size}
            color={iconColor}
            style={iconStyle}
          />
        );
      }
      return (
        <span
          aria-hidden="true"
          className="bp3-icon"
        >
          {icon}
        </span>
      );
    }
    return null;
  }, [size, icon, iconColor, iconStyle]);

  return (
    <ThemeContext.Consumer>
      {({ isDark }) => (
        <ButtonSource
          {...restProps}
          ref={ref}
          className={cx(
            Type[type],
            dense && Type.dense,
            isDark ? View[`${view}-dark`] : View[view],
            Color[color],
            active && View.focused,
            className,
          )}
          text={t ? t(text) : text}
          icon={leftIcon}
          title={title ? t(title) : undefined}
        />
      )}
    </ThemeContext.Consumer>
  );
});

Button.propTypes = {
  /**
   `The type of the component.
   * Variants: `card` `action` `circle` `icon`
   * Default value (if undefined): `default` `
   */
  type: PropTypes.oneOf(['default', 'card', 'action', 'circle', 'icon', 'square']),
  /**
   ` The view of the component.
   * Variants: `flat` `smooth` `outlined` `raised`
   * Default value (if undefined): `filled` `
   */
  view: PropTypes.oneOf(['filled', 'flat', 'smooth', 'outlined', 'raised']),
  /**
  ` The color of the component.
   * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    'default',
    'primary',
    'warning',
    'danger',
    'success',
    'primary_alt',
    'warning_alt',
    'danger_alt',
    'success_alt',
  ]),
  /**
   * Click event handler.
   */
  onClick: PropTypes.func,
  /**
   * Whether this component should expand to fill its container.
   */
  fill: PropTypes.bool,
  /**
   * Name of a Blueprint UI icon (or an icon element) to render before the text.
   */
  icon: PropTypes.any,
  /**
   * Name of a Blueprint UI icon (or an icon element) to render after the text.
   */
  rightIcon: PropTypes.any,
  /**
   * If set to `true`, the button will display a centered loading spinner instead of its contents.
   * The width of the button is not affected by the value of this prop.
   */
  loading: PropTypes.bool,
  /**
   * If `true`, the button will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Dense size
   */
  dense: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string,
};

export default Button;
