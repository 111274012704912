export const SERVICES_ENABLED_TABS_DICTIONARY = {
  services: 0,
  packs: 1,
  'bundle-initiators': 2,
  'bundle-packs': 3,
};
export const SERVICES_ENABLED_SCREEN_TABS = [
  {
    id: 'services',
    title: 'TABS.SERVICES_ENABLED',
    withoutCross: true,
  },
  {
    id: 'packs',
    title: 'TABS.PACKS_ENABLED',
    withoutCross: true,
  },
  {
    id: 'bundle-initiators',
    title: 'TABS.BUNDLE_INITIATORS_ENABLED',
    withoutCross: true,
  },
  {
    id: 'bundle-packs',
    title: 'TABS.BUNDLE_PACKS_ENABLED',
    withoutCross: true,
  },
];
