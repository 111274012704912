import moment from 'moment';
import React, { useMemo, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { isEqual, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { numbers } from 'consts/inputPatterns';
import {
  TextField,
  DropdownField, DatePickerField,
} from 'components/fields';
import { CALLER_IDS_SCHEMA } from 'consts/validationSchemas';
import { FormButtonsGroup } from 'components/blocks';
import { useContentShadow } from 'hooks';
import IsFormChanged from 'components/forms/IsFormChanged';

import styles from './CallerForm.module.scss';

const CallerIdForm = ({
  initialValues = {},
  onSubmit,
  onCancel,
  onDelete,
  mode,
  changeMode,
  isPending,
  isFormPristine,
  formValuesRef,
  companiesOptions,
}) => {
  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);
  const contentRef = useRef(null);
  const {
    showTopShadow,
    showBottomShadow,
  } = useContentShadow(contentRef);
  const isView = useMemo(() => mode === 'view', [mode]);
  const isEdit = useMemo(() => mode === 'edit', [mode]);
  const isAdd = useMemo(() => mode === 'add', [mode]);

  const getTillMinDate = ({ from, till }) => {
    if (isAdd) {
      return moment(from).clone().add(1, 'day').toDate();
    }
    if (isEdit && initialValues.actualVersionId) {
      return moment().toDate();
    }
    if (isEdit && !initialValues.actualVersionId) {
      if (moment(till).isBefore(moment())) {
        return moment().toDate();
      }
      return moment(from).clone().add(1, 'day').toDate();
    }
    return undefined;
  };
  const isDisabledFrom = () => {
    if (isEdit) {
      return !!initialValues.actualVersionId;
    }
    return false;
  };
  const isDisabledOwnerCompany = () => {
    if (isEdit) {
      return !!initialValues.actualVersionId;
    }
    return false;
  };
  const isDisabledTill = ({ till, from }) => {
    if (isAdd && !from) {
      return true;
    }
    return !!(isEdit && initialValues.actualVersionId && moment(till).isBefore(moment()));
  };
  const changeToEdit = () => changeMode('edit');
  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }

    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };

  const onSubmitHandler = ({
    effectiveFrom,
    effectiveTill,
    ownerCompanyId,
    senderId,
  }) => {
    onSubmit({
      id: isEdit && initialValues.id,
      effectiveFrom,
      effectiveTill,
      ownerCompanyId,
      senderId,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
      validationSchema={CALLER_IDS_SCHEMA}
      validateOnChange
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
        values,
      }) => (
        <Form className={styles.form}>
          {showTopShadow && <div className={styles.shadow} />}
          <div className={styles.content} ref={contentRef}>
            <Field
              name="senderId"
              label="INSTANCES.NUMBER"
              component={TextField}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              disabled={isView || isEdit}
              isRequired={!isView && !isEdit}
              regex={numbers()}
              full
            />
            <Field
              name="ownerCompanyId"
              label="INSTANCES.CALLER_IDS.OWNER_COMPANY"
              component={DropdownField}
              options={companiesOptions}
              placeholder="PLACEHOLDERS.SELECT"
              disabled={isView || isDisabledOwnerCompany()}
              isRequired={!isView && !isEdit}
            />
            <Field
              name="effectiveFrom"
              label="INSTANCES.CALLER_IDS.START_DATE"
              placeholder="PLACEHOLDERS.SELECT_DATE"
              component={DatePickerField}
              format="DD.MM.YYYY"
              disabled={isView || isDisabledFrom()}
              isRequired={!isView}
            />
            <Field
              name="effectiveTill"
              label="INSTANCES.CALLER_IDS.EXPIRATION_DATE"
              placeholder="PLACEHOLDERS.SELECT_DATE"
              component={DatePickerField}
              format="DD.MM.YYYY"
              minDate={getTillMinDate({ from: values.effectiveFrom, till: values.effectiveTill })}
              withIndefinitely
              disabled={isView || isDisabledTill({ from: values.effectiveFrom, till: values.effectiveTill })}
              isRequired={!isView}
            />
          </div>
          <FormButtonsGroup
            onDelete={onDelete}
            onCancel={onCancel}
            setFieldTouched={setFieldTouched}
            errors={errors}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            isPending={isPending}
            showShadow={showBottomShadow}
          />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
        </Form>
      )}
    </Formik>
  );
};

export default CallerIdForm;
