import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getCountries as getCountriesApi,
  getIndustries as getIndustriesApi,
  getTimezones as getTimezonesApi,
  getCurrencies as getCurrenciesApi,
  getCompaniesDictionary as getCompaniesDictionaryApi,
  getResourcesDictionary as getResourcesDictionaryApi,
  getLegalEntitiesDictionary as getLegalEntitiesDictionaryApi,
  getServicesDictionary as getServicesDictionaryApi,
  getOwnLegalEntitiesDictionary as getOwnLegalEntitiesDictionaryApi,
  getItemLinks as getItemLinksApi,
  getListWithDefinitions as getListWithDefinitionsApi,
  getFilteredItems as getFilteredItemsApi,
  getSendersDictionary as getSendersDictionaryApi,
  getCampaignsDictionary as getCampaignsDictionaryApi,
  getUsersDictionary as getUsersDictionaryApi,
  getServiceDomainsDictionary as getServiceDomainsDictionaryApi,
  getDictionariesWithLinks as getDictionariesWithLinksApi,
  getAgreementsDictionary as getAgreementsDictionaryApi,
  getPortalsDictionary as getPortalsDictionaryApi,
  getOwnAgreementsDictionary as getOwnAgreementsDictionaryApi,
  getAttributes as getAttributesApi,
  getIntegrationsDictionary as getIntegrationsDictionaryApi,
  getFlowsDictionary as getFlowsDictionaryApi,
  getBanksDictionary as getBanksDictionaryApi,
  getCountriesDictionary as getCountriesDictionaryApi,
  getBankAccountsDictionary as getBankAccountsDictionaryApi,
  getOwnBankAccountsDictionary as getOwnBankAccountsDictionaryApi,
  getParentBanksDictionary as getParentBanksDictionaryApi,
} from 'queries/dictionaries';

import {
  getArray,
  getObject,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetCountries,
      lastUpdated: lastUpdatedGetCountries,
    },
    getCountries,
  ] = useMutation(getCountriesApi);
  const [
    {
      isPending: isPendingGetUsersDictionary,
      lastUpdated: lastUpdatedGetUsersDictionary,
    }, getUsersDictionary,
  ] = useMutation(getUsersDictionaryApi);
  const [
    {
      isPending: isPendingGetIndustries,
      lastUpdated: lastUpdatedGetIndustries,
    }, getIndustries,
  ] = useMutation(getIndustriesApi);
  const [
    {
      isPending: isPendingGetTimezones,
      lastUpdated: lastUpdatedGetTimezones,
    }, getTimezones,
  ] = useMutation(getTimezonesApi);
  const [
    {
      isPending: isPendingGetCurrencies,
      lastUpdated: lastUpdatedGetCurrencies,
    }, getCurrencies,
  ] = useMutation(getCurrenciesApi);
  const [
    {
      isPending: isPendingGetCompaniesDictionary,
      lastUpdated: lastUpdatedGetCompaniesDictionary,
    }, getCompaniesDictionary,
  ] = useMutation(getCompaniesDictionaryApi);
  const [
    {
      isPending: isPendingGetCampaignsDictionary,
      lastUpdated: lastUpdatedGetCampaignsDictionary,
    },
    getCampaignsDictionary,
  ] = useMutation(getCampaignsDictionaryApi);
  const [, getResourcesDictionary] = useMutation(getResourcesDictionaryApi);
  const [, getLegalEntitiesDictionary] = useMutation(getLegalEntitiesDictionaryApi);
  const [, getOwnLegalEntitiesDictionary] = useMutation(getOwnLegalEntitiesDictionaryApi);
  const [, getServicesDictionary] = useMutation(getServicesDictionaryApi);
  const [, getIntegrationsDictionary] = useMutation(getIntegrationsDictionaryApi);
  const [, getItemLinks] = useMutation(getItemLinksApi);
  const [{ isPending: isPendingGetListWithDefinitions }, getListWithDefinitions] = useMutation(getListWithDefinitionsApi);
  const [{ isPending: isPendingGetFilteredItems }, getFilteredItems] = useMutation(getFilteredItemsApi);
  const [, getSendersDictionary] = useMutation(getSendersDictionaryApi);
  const [{ isPending: isPendingGetServiceDomains }, getServiceDomainsDictionary] = useMutation(getServiceDomainsDictionaryApi);
  const [, getDictionariesWithLinks] = useMutation(getDictionariesWithLinksApi);
  const [{ isPending: isPendingGetAgreementsDictionary }, getAgreementsDictionary] = useMutation(getAgreementsDictionaryApi);
  const [, getPortalsDictionary] = useMutation(getPortalsDictionaryApi);
  const [{ isPending: isPendingGetOwnAgreementsDictionary }, getOwnAgreementsDictionary] = useMutation(getOwnAgreementsDictionaryApi);
  const [, getAttributes] = useMutation(getAttributesApi);
  const [{ isPending: isPendingGetFlowsDictionary }, getFlowsDictionary] = useMutation(getFlowsDictionaryApi);
  const [, getBanksDictionary] = useMutation(getBanksDictionaryApi);
  const [, getCountriesDictionary] = useMutation(getCountriesDictionaryApi);
  const [, getBankAccountsDictionary] = useMutation(getBankAccountsDictionaryApi);
  const [, getOwnBankAccountsDictionary] = useMutation(getOwnBankAccountsDictionaryApi);
  const [, getParentBanksDictionary] = useMutation(getParentBanksDictionaryApi);

  const selector = useCallback((state) => {
    const ownCompany = getObject(state, 'ownCompany');

    return ({
      countries: getArray(state, 'countries'),
      campaignsDictionary: getArray(state, 'campaignsDictionary'),
      boUsersDictionary: getArray(state, 'boUsersDictionary'),
      boUsersOptions: getArray(state, 'boUsersOptions'),
      users: getArray(state, 'users'),
      industries: getArray(state, 'industries'),
      timezones: getArray(state, 'timezones'),
      timezonesDictionary: getObject(state, 'timezonesDictionary'),
      currencies: getArray(state, 'currencies'),
      companiesOptionsWithOwn: [
        {
          value: ownCompany.id,
          label: ownCompany.name,
          labelChip: {
            tag: 'own',
            color: 'primary',
          },
          style: {
            fontWeight: 600,
          },
        },
        ...getArray(state, 'companiesOptions'),
      ],
      companiesOptions: getArray(state, 'companiesOptions'),
      companiesDictionary: {
        [ownCompany.id]: {
          ...ownCompany,
        },
        ...getObject(state, 'companiesDictionary'),
      },
      resourcesOptions: getArray(state, 'resourcesOptions'),
      resourcesDictionary: getObject(state, 'resourcesDictionary'),
      legalEntitiesOptions: getArray(state, 'legalEntitiesOptions'),
      legalEntitiesDictionary: getObject(state, 'legalEntitiesDictionary'),
      ownLegalEntitiesOptions: getArray(state, 'ownLegalEntitiesOptions'),
      ownLegalEntitiesDictionary: getObject(state, 'ownLegalEntitiesDictionary'),
      servicesOptions: getArray(state, 'servicesOptions'),
      servicesDictionary: getObject(state, 'servicesDictionary'),
      integrationsOptions: getArray(state, 'integrationsOptions'),
      integrationsDictionary: getObject(state, 'integrationsDictionary'),
      itemLinks: getArray(state, 'itemLinks'),
      definitionsList: getArray(state, 'definitionsList'),
      sendersOptions: getArray(state, 'sendersOptions'),
      sendersDictionary: getObject(state, 'sendersDictionary'),
      serviceDomainsOptions: getArray(state, 'serviceDomainsOptions'),
      serviceDomainsDictionary: getObject(state, 'serviceDomainsDictionary'),
      dictionariesWithLinks: getObject(state, 'dictionariesWithLinks'),
      agreementsOptions: getArray(state, 'agreementsOptions'),
      ownAgreementsOptions: getArray(state, 'ownAgreementsOptions'),
      agreementsDictionary: {
        ...getObject(state, 'ownAgreementsDictionary'),
        ...getObject(state, 'agreementsDictionary'),
      },
      portalsOptions: getArray(state, 'portalsOptions'),
      portalsDictionary: getObject(state, 'portalsDictionary'),
      attributes: getArray(state, 'attributes'),
      flowsDictionary: getObject(state, 'flowsDictionary'),
      banksOptions: getArray(state, 'banksOptions'),
      banksDictionary: getObject(state, 'banksDictionary'),
      countriesOptions: getArray(state, 'countriesOptions'),
      countriesDictionary: getObject(state, 'countriesDictionary'),
      bankAccountsOptions: getArray(state, 'bankAccountsOptions'),
      bankAccountsDictionary: {
        ...getObject(state, 'bankAccountsDictionary'),
        ...getObject(state, 'ownBankAccountsDictionary'),
      },
      ownBankAccountsOptions: getArray(state, 'ownBankAccountsOptions'),
      parentBanksOptions: getArray(state, 'parentBanksOptions'),
      parentBanksDictionary: getObject(state, 'parentBanksDictionary'),
    });
  }, []);
  const data = useISESelector(selector);

  return {
    getCampaignsDictionary,
    getCountries,
    getUsersDictionary,
    getIndustries,
    getTimezones,
    getCurrencies,
    getCompaniesDictionary,
    getResourcesDictionary,
    getLegalEntitiesDictionary,
    getServicesDictionary,
    getOwnLegalEntitiesDictionary,
    getItemLinks,
    getListWithDefinitions,
    getFilteredItems,
    getSendersDictionary,
    getServiceDomainsDictionary,
    getDictionariesWithLinks,
    getAgreementsDictionary,
    getPortalsDictionary,
    getOwnAgreementsDictionary,
    getAttributes,
    getIntegrationsDictionary,
    getFlowsDictionary,
    getBanksDictionary,
    getCountriesDictionary,
    getBankAccountsDictionary,
    getOwnBankAccountsDictionary,
    getParentBanksDictionary,
    isPendingGetCountries,
    isPendingGetUsersDictionary,
    isPendingGetCampaignsDictionary,
    isPendingGetIndustries,
    isPendingGetTimezones,
    isPendingGetCurrencies,
    isPendingGetCompaniesDictionary,
    isPendingGetListWithDefinitions,
    isPendingGetFilteredItems,
    isPendingGetServiceDomains,
    isPendingGetAgreementsDictionary,
    isPendingGetOwnAgreementsDictionary,
    isPendingGetFlowsDictionary,
    lastUpdatedGetCountries,
    lastUpdatedGetUsersDictionary,
    lastUpdatedGetIndustries,
    lastUpdatedGetTimezones,
    lastUpdatedGetCurrencies,
    lastUpdatedGetCampaignsDictionary,
    lastUpdatedGetCompaniesDictionary,
    ...data,
  };
};
