import React, { useMemo } from 'react';
import { Icon } from '@blueprintjs/core';
import { useWindowSize } from 'react-use';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { useUsersApi } from 'hooks/api';

import styles from './CallerIdsButtonsCellRenderer.module.scss';

export default ({
  data,
  prepareAndSetEditableRecord,
  setEditableRecord,
  openModal,
  openConfirm,
  isPoolTable = true,
}) => {
  const { t } = useTranslation();
  const { currentUser } = useUsersApi();
  const { width } = useWindowSize();

  const iconSize = useMemo(() => (width <= 1366 ? 12 : 16), [width]);
  const isOwner = useMemo(() => data.ownerCompanyId === currentUser.companyId, [data, currentUser]);

  let buttons = [
    {
      id: 'assign',
      icon: 'confirm',
      onClick: () => {
        prepareAndSetEditableRecord(data);
        openModal('assign');
      },
      color: 'var(--green70)',
      title: 'Assign',
      disabled: !isOwner,
    },
    {
      id: 'history',
      icon: 'history',
      onClick: () => {
        prepareAndSetEditableRecord(data);
        openModal('history');
      },
      color: 'var(--orange70)',
      title: 'Assignment history',
      disabled: !isOwner,
    },
    {
      id: 'trash',
      icon: 'trash',
      onClick: () => {
        prepareAndSetEditableRecord(data);
        openConfirm('delete');
      },
      color: 'var(--red70)',
      title: 'Delete',
    },
  ];

  if (!data.isAvailable) {
    buttons = [
      {
        id: 'reassign',
        icon: 'automatic-updates',
        onClick: () => {
          prepareAndSetEditableRecord(data);
          openModal('reassign');
        },
        color: 'var(--yellow70)',
        title: 'Reassign',
        disabled: !isOwner,
      },
      {
        id: 'history',
        icon: 'history',
        onClick: () => {
          prepareAndSetEditableRecord(data);
          openModal('history');
        },
        color: 'var(--orange70)',
        title: 'Assignment history',
        disabled: !isOwner,
      },
      {
        id: 'deassign',
        icon: 'ban-circle',
        onClick: () => {
          setEditableRecord(data);
          openConfirm('deassign');
        },
        color: 'var(--red70)',
        title: 'Deassign',
        disabled: !isOwner,
      },
    ];
  }

  if (isPoolTable) {
    buttons.unshift({
      id: 'edit',
      icon: 'edit',
      onClick: () => {
        prepareAndSetEditableRecord(data);
        openModal('view');
      },
      color: 'var(--blue70)',
      title: 'Edit',
    });
  }

  return (
    <div className={styles.container}>
      {buttons && buttons.map(button => (
        <Icon
          key={button.id}
          size={iconSize}
          {...button}
          onClick={(e) => {
            e.stopPropagation();
            if (!button.disabled) {
              button.onClick(data);
            }
          }}
          className={cx(styles.button, { [styles.button_disabled]: button.disabled })}
          htmlTitle={t(button.title)}
        />
      ))}
    </div>
  );
};
