import endpoints from 'consts/endpoints';

export default ({
  limit = 1000,
  offset = 0,
  successCallback,
  errorCallback,
  saveToRedux = true,
  ...queries
} = {}) => ({
  url: endpoints.getConversationTemplatesUrl(),
  body: {
    limit,
    offset: offset ? ((offset - 1) * limit) : offset,
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  transform: (response) => {
    const {
      resourceList,
      ...other
    } = response;

    return {
      conversationTemplates: resourceList,
      conversationTemplatesMeta: other,
    };
  },
  update: {
    conversationTemplates: (oldValue, newValue) => (saveToRedux ? newValue : oldValue),
    conversationTemplatesMeta: (oldValue, newValue) => (saveToRedux ? newValue : oldValue),
  },
});
