import React, {
  useMemo, useRef,
} from 'react';
import { Field, Form, Formik } from 'formik';
import { isEmpty, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { CheckboxField, DatePickerField, DropdownField } from 'components/fields';
import { FormButtonsGroup } from 'components/blocks';
import { BUNDLE_INITIATOR_ENABLED_SCHEMA } from 'consts/validationSchemas';
import IsFormChanged from 'components/forms/IsFormChanged';

import styles from './BundleInitiatorEnabledForm.module.scss';

const BundleInitiatorEnabledForm = ({
  changeMode,
  mode,
  onSubmit,
  initialValues = {
    effectiveTill: null,
  },
  onDelete,
  onCancel,
  isPending,
  isFormPristine,
  formValuesRef,
  bundleInitiatorsOptions,
  agreementsOptions,
}) => {
  // hooks
  const formRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { activeTab } = useSelector(state => state?.states.routes);
  // memed vars
  const isView = useMemo(() => mode === 'view', [mode]);
  const isEdit = useMemo(() => mode === 'edit', [mode]);

  // functions
  const changeToEdit = () => changeMode('edit');

  const onSubmitModify = ({
    effectiveFrom, effectiveTill, ...values
  }) => {
    onSubmit({
      effectiveFrom: moment(effectiveFrom).format('YYYY-MM-DDTHH:mm:ssZ'),
      effectiveTill: !effectiveTill ? null : moment(effectiveTill).format('YYYY-MM-DDTHH:mm:ssZ'),
      ...values,
    });
  };
  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }

    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        effectiveFrom: moment().startOf('day').toDate(),
        ...initialValues,
      }}
      onSubmit={onSubmitModify}
      validationSchema={BUNDLE_INITIATOR_ENABLED_SCHEMA}
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
        values,
      }) => (
        <Form className={styles.container}>
          <div className={styles.content}>
            <Field
              name="serviceId"
              component={DropdownField}
              label="INSTANCES.PACKS_ENABLED.SERVICE_PACK"
              options={bundleInitiatorsOptions}
              disabled={isView || isEdit}
              isRequired={!isView}
              placeholder="PLACEHOLDERS.SELECT"
            />
            <Field
              name="agreementId"
              component={DropdownField}
              label="INSTANCES.AGREEMENT"
              options={agreementsOptions}
              disabled={isView || isEdit}
              isRequired={!isView}
              placeholder="PLACEHOLDERS.SELECT"
            />
            <Field
              name="effectiveFrom"
              label="INSTANCES.EFFECTIVE_FROM"
              component={DatePickerField}
              maxDate={values?.effectiveTill}
              minDate={moment().toDate()}
              disabled={isView || isEdit}
              tooltipMessage={t('TOOLTIPS.PACKS_ENABLED.EFFECTIVE_FROM')}
              timePrecision="second"
              placeholder="DD.MM.YYYY, HH:mm:ss"
              format="DD.MM.YYYY, HH:mm:ss"
              placement="right"
              isNow={!isView}
              isRequired
            />
            <Field
              name="effectiveTill"
              label="INSTANCES.EFFECTIVE_TILL"
              component={DatePickerField}
              minDate={moment(moment(values?.effectiveFrom) > moment()
                ? values?.effectiveFrom
                : moment())
                .add(5, 'minutes')
                .toDate()}
              disabled={isView || isEdit}
              tooltipMessage={t('TOOLTIPS.PACKS_ENABLED.EFFECTIVE_TILL')}
              timePrecision="second"
              placeholder="DD.MM.YYYY, HH:mm:ss"
              format="DD.MM.YYYY, HH:mm:ss"
              placement="right"
              withIndefinitely
            />
            <Field
              name="hideFromClient"
              component={CheckboxField}
              text="INSTANCES.SERVICES_ENABLED.DO_NOT_SHOW"
              disabled={isView}
            />
          </div>
          <FormButtonsGroup
            onDelete={onDelete}
            onCancel={onCancel}
            setFieldTouched={setFieldTouched}
            errors={errors}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            isPending={isPending}
            showDelete={false}
          />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
        </Form>
      )}
    </Formik>
  );
};

export default BundleInitiatorEnabledForm;
