import React, { useEffect } from 'react';

import Table from 'components/Table';
import { CustomColumnsView } from 'components/blocks';
import Modal from 'components/Modal';
import { BANKS } from 'consts/columns';
import {
  useBanksApi,
  useCompaniesApi,
  useDictionariesApi,
} from 'hooks/api';
import { ConfirmModal } from 'components/modals';
import { useScreen } from 'hooks';

import BankForm from './BankForm';

const BanksScreen = ({
  screenRef,
}) => {
  const {
    banks,
    banksMeta,
    isPendingGetBanks,
    lastUpdatedGetBanks,
    getBanks,
    deleteBank,
    postBank,
    putBank,
    isPendingPostBank,
    isPendingPutBank,
    isPendingDeleteBank,
  } = useBanksApi();
  const { ownCompany } = useCompaniesApi();
  const { getCountriesDictionary } = useDictionariesApi();
  const {
    openModal,
    editableRecord,
    sendQuery,
    onCloseModal,
    limit,
    offset,
    setOffset,
    setLimit,
    onSortChanged,
    onFilterChanged,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    onDeleteClick,
    isFormPristine,
    formValuesRef,
    isOpenConfirm,
    closeConfirm,
    onDeleteConfirm,
    onConfirmClose,
    isOpenModal,
    onRowClicked,
  } = useScreen({
    screenRef,
    getFunc: getBanks,
    deleteFunc: deleteBank,
    onRowClickCallback: data => data,
  });

  const onSubmit = ({ swiftCode, branchCode, ...body }) => {
    (editableRecord ? putBank : postBank)({
      body: {
        ...body,
        companyId: ownCompany.id,
        swiftCode: swiftCode?.toUpperCase(),
        branchCode: branchCode?.toUpperCase?.() || undefined,
      },
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
    });
  };

  useEffect(() => {
    getCountriesDictionary();
  }, []);

  return (
    <div>
      <Table
        limit={limit}
        total={banksMeta.size}
        page={offset}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        filteredColumns={filteredColumns}
        tableHeader="TABS.BANKS"
        openForm={() => openModal('add')}
        openCustomColumns={openCustomViewDrawer}
        buttonText="CONTROLS.BANKS.ADD"
        columnDefs={BANKS}
        rowData={banks}
        onRowClicked={onRowClicked}
        sendQuery={sendQuery}
        isPending={isPendingGetBanks || !lastUpdatedGetBanks}
        tableRef={tableRef}
      />
      <CustomColumnsView
        initialValues={filteredColumns}
        columnsNames={BANKS}
        onSubmit={onSaveCustomView}
        onClose={closeCustomViewDrawer}
        isOpen={isOpenCustomViewDrawer}
      />
      {
        isOpenModal && (
          <Modal
            mode={isOpenModal}
            closeModal={onCloseModal}
            title="SCREENS.BANKS.BANK"
            editableRecord={editableRecord}
          >
            <BankForm
              onSubmit={onSubmit}
              onCancel={onCloseModal}
              mode={isOpenModal}
              onDelete={onDeleteClick}
              changeMode={openModal}
              initialValues={editableRecord}
              isPending={isPendingPostBank || isPendingPutBank || isPendingDeleteBank}
              isFormPristine={isFormPristine}
              formValuesRef={formValuesRef}
            />
          </Modal>
        )
      }
      {
        isOpenConfirm && (
          <ConfirmModal
            isDelete={isOpenConfirm === 'delete'}
            closeModal={closeConfirm}
            onConfirm={
              isOpenConfirm === 'delete' ? onDeleteConfirm : onConfirmClose
            }
          />
        )
      }
    </div>
  );
};

export default BanksScreen;
