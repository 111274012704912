import { useMutation } from 'redux-query-react';
import { useCallback } from 'react';

import {
  getConversationTemplates as getConversationTemplatesApi,
  getContentTemplates as getContentTemplatesApi,
} from 'queries/constructorTemplates';

import { getArray, getObject, useISESelector } from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetConversationTemplates,
      lastUpdated: lastUpdatedGetConversationTemplates,
    },
    getConversationTemplates,
  ] = useMutation(getConversationTemplatesApi);
  const [
    {
      isPending: isPendingGetContentTemplates,
      lastUpdated: lastUpdatedGetContentTemplates,
    },
    getContentTemplates,
  ] = useMutation(getContentTemplatesApi);

  const selector = useCallback(state => ({
    conversationTemplates: getArray(state, 'conversationTemplates'),
    conversationTemplatesMeta: getObject(state, 'conversationTemplatesMeta'),
    contentTemplates: getArray(state, 'contentTemplates'),
    contentTemplatesMeta: getObject(state, 'contentTemplatesMeta'),
  }), []);

  const data = useISESelector(selector);

  return {
    isPendingGetConversationTemplates,
    isPendingGetContentTemplates,
    lastUpdatedGetConversationTemplates,
    lastUpdatedGetContentTemplates,
    getConversationTemplates,
    getContentTemplates,
    ...data,
  };
};
