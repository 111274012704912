import cx from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';

import Spinner from '@setproduct-ui/core/Spinner';
import Button from '@setproduct-ui/core/Button';
import { useDictionariesApi, useDidSendersApi } from 'hooks/api';
import {
  TextField,
  DropdownField,
  DatePickerField,
} from 'components/fields';
import { FormButtonsGroup } from 'components/blocks';

import styles from './HistoryForm.module.scss';

const HistoryForm = ({
  initialValues = {},
  onSubmit,
  onCancel,
  onDelete,
  mode,
  isPending,
  companiesOptions,
}) => {
  const { t } = useTranslation();
  const [history, setHistory] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [selectedVersionId, setSelectedVersionId] = useState(null);
  const {
    getDidSenderHistory,
    isPendingGetDidSenderHistory,
  } = useDidSendersApi();
  const {
    agreementsOptions,
    companiesDictionary,
  } = useDictionariesApi();
  const {
    deleteDidSenderActiveVersion,
    isPendingDeleteDidSenderActiveVersion,
  } = useDidSendersApi();

  const addStatusToVersions = (data) => {
    const now = moment();

    const processedData = data.map((item) => {
      const fromDate = moment(item.actualEffectiveFrom);
      const tillDate = moment(item.actualEffectiveTill);

      let status; let
        indicatorColorClass;

      if (fromDate.isAfter(now)) {
        status = t('INSTANCES.CALLER_IDS.SCHEDULE');
        indicatorColorClass = styles.schedule;
      } else if (fromDate.isBefore(now) && tillDate.isAfter(now)) {
        status = t('INSTANCES.CALLER_IDS.ACTIVE');
        indicatorColorClass = styles.active;
      } else if (tillDate.isBefore(now)) {
        status = t('INSTANCES.CALLER_IDS.PREVIOUS');
        indicatorColorClass = styles.previous;
      }

      return {
        ...item,
        status,
        indicatorColorClass,
      };
    });

    return processedData.sort((a, b) =>
      moment(b.actualEffectiveTill).valueOf() - moment(a.actualEffectiveTill).valueOf());
  };

  const onClickVersion = ({
    id,
    ...props
  }) => {
    setSelectedVersionId(id);
    setFormValues({
      senderId: initialValues.senderId,
      ...props,
    });
  };
  const getVersions = ({ setFirstActive }) => {
    getDidSenderHistory({
      id: initialValues.id,
      successCallback: (data) => {
        if (data?.length) {
          if (setFirstActive) {
            setSelectedVersionId(data[0].id);
          }
          setFormValues({
            senderId: initialValues.senderId,
            ...data[0],
          });
          setHistory(addStatusToVersions(data));
        }
      },
    });
  };
  const onClickVersionDelete = (id) => {
    deleteDidSenderActiveVersion({
      id,
      successCallback: () => {
        setFormValues({});
        setHistory([]);
        getVersions({
          setFirstActive: selectedVersionId === id,
        });
      },
    });
  };

  useEffect(() => {
    getVersions({ setFirstActive: true });
  }, []);

  if (isPendingGetDidSenderHistory) {
    return <Spinner className={styles.spinner} />;
  }

  if (!history.length) {
    return (
      <div className={styles.noHistory}>
        <span className={styles.noHistoryHeader}>{t('INSTANCES.CALLER_IDS.NO_RESULT_FOUND')}</span>
        <span className={styles.noHistoryBody}>{t('INSTANCES.CALLER_IDS.TRY_DIFFERENTLY')}</span>
      </div>
    );
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={onSubmit}
      validateOnMount
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
        values,
      }) => (
        <Form className={styles.form}>
          <div className={styles.history}>
            {history.map(({
              id,
              companyId,
              actualEffectiveFrom,
              actualEffectiveTill,
              status,
              indicatorColorClass,
              ...props
            }) => (
              <div
                role="presentation"
                className={cx(styles.version, {
                  [styles.version_selected]: selectedVersionId === id,
                })}
                onClick={() => onClickVersion({
                  id,
                  companyId,
                  actualEffectiveFrom,
                  actualEffectiveTill,
                  ...props,
                })}
              >
                <div className={styles.left}>
                  <span className={styles.company}>{companiesDictionary?.[companyId]?.name}</span>
                  <span className={styles.dates}>{`${moment(actualEffectiveFrom).format('D MMM, YYYY')} - ${moment(actualEffectiveTill).format('D MMM, YYYY')}`}</span>
                </div>
                <div className={styles.center}>
                  <div className={styles.statusContainer}>
                    <span className={`${styles.indicator} ${indicatorColorClass}`} />
                    <span>{status}</span>
                  </div>
                </div>
                <div className={styles.right}>
                  {moment(actualEffectiveFrom).isAfter() && (
                  <Button
                    type="icon"
                    view="flat"
                    color="default"
                    icon="remove"
                    loading={isPendingDeleteDidSenderActiveVersion}
                    onClick={() => onClickVersionDelete(id)}
                    className={styles.button}
                    iconColor="var(--red50)"
                  />
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.divider} />
          <div className={styles.fieldsContainer}>
            <div className={styles.fields}>
              <Field
                name="senderId"
                label="INSTANCES.NUMBER"
                component={TextField}
                placeholder="PLACEHOLDERS.ENTER_VALUE"
                disabled
                full
              />
              <Field
                name="companyId"
                label="INSTANCES.COMPANY"
                component={DropdownField}
                options={companiesOptions}
                placeholder="PLACEHOLDERS.SELECT"
                disabled
              />
              <Field
                name="agreementId"
                label="INSTANCES.AGREEMENT"
                component={DropdownField}
                options={agreementsOptions}
                placeholder="PLACEHOLDERS.SELECT"
                disabled
              />
              <Field
                name="actualEffectiveFrom"
                label="INSTANCES.CALLER_IDS.START_DATE"
                placeholder="PLACEHOLDERS.SELECT_DATE"
                component={DatePickerField}
                format="DD.MM.YYYY"
                minDate={values.actualEffectiveFrom}
                disabled
              />
              <Field
                name="actualEffectiveTill"
                label="INSTANCES.CALLER_IDS.EXPIRATION_DATE"
                placeholder="PLACEHOLDERS.SELECT_DATE"
                component={DatePickerField}
                format="DD.MM.YYYY"
                withIndefinitely
                disabled
              />
            </div>
            <FormButtonsGroup
              onDelete={onDelete}
              onCancel={onCancel}
              setFieldTouched={setFieldTouched}
              errors={errors}
              onApply={handleSubmit}
              mode={mode}
              isPending={isPending || isPendingDeleteDidSenderActiveVersion}
              showDelete={false}
              showSave={false}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default HistoryForm;
