import React, {
  useMemo,
} from 'react';
import { Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import cx from 'classnames';
import { Field, Formik } from 'formik';

import Color from '@setproduct-ui/styles/color.module.css';
import { ConfirmModal } from 'components/modals';
import Dialog from '@setproduct-ui/core/Dialog';
import Button from '@setproduct-ui/core/Button';

import styles from './ModalTable.module.scss';

const ModalTable = ({
  rowData,
  columnsName,
  onSubmit,
  onDeleteClick,
  activeRow,
  setActiveRow,
  isModalOpen,
  onCloseModal,
  confirmDelete,
  isPending = {},
  idToDelete,
  confirmChanges,
  formFieldConfig,
  getInitialVilue,
  formRef,
  addAndEditRow = false,
}) => {
  const { t } = useTranslation();
  const userTZ = useMemo(() => Intl.DateTimeFormat().resolvedOptions()?.timeZone, []);

  const getFormattedDate = (date) => {
    if (date) {
      return moment(`${date}+0000`).format('DD.MM.YYYY HH:mm:ss');
    }
    return (
      <span style={{ color: 'var(--grey40)' }}>
        {`∞ ${t('CONTROLS.INDEFINITELY')}`}
      </span>
    );
  };
  const addNewRow = () => {
    if (!activeRow) {
      setActiveRow('newRow');
    }
  };
  const resetActiveRow = () => setActiveRow(null);

  const createCellTable = (value) => {
    switch (value.type) {
      case 'number':
        if (value.onClick) {
          return (
            <button
              type="button"
              className={styles.click}
              onClick={() => value.onClick(value.value)}
            >
              {value.value}
            </button>
          );
        }
        return value.value;
      case 'string':
        if (value.secondValue) {
          return (
            <>
              {value.value}
              ,
              <span className={styles.currency}>
                {value.secondValue}
              </span>
            </>
          );
        }
        return value.value;
      case 'date':
        return getFormattedDate(value.value);
      default:
        return 'no value';
    }
  };

  return (
    <div
      className={cx(styles.table, { [styles.table_pinLastColumn]: addAndEditRow })}
      style={{ 'grid-template-columns': `repeat(${columnsName.length}, minmax(min-content, 1fr)) ${addAndEditRow ? '68px' : ''}` }}
    >
      <div className={styles.header}>
        {columnsName.map(item => (item.field === 'actualEffectiveFrom' || item.field === 'actualEffectiveTill'
          ? (
            <div>
              {t(item.headerName)}
              <Icon
                icon="info-sign"
                size={14}
                htmlTitle={t('TOOLTIPS.TIMEZONE', { TZ: userTZ })}
              />
            </div>
          ) : (
            <div>
              {t(item.headerName)}
            </div>
          )))}
        {addAndEditRow && (<div />)}
      </div>
      {rowData.map(item => (
        <div className={cx(styles.row, { [styles.row_active]: item.id === activeRow })} key={item.id}>
          {activeRow === item.id ? (
            <>
              <div />
              <Formik
                initialValues={getInitialVilue(activeRow)}
                onSubmit={onSubmit}
                innerRef={formRef}
              >
                {({ handleSubmit, values }) => (
                  <>
                    {formFieldConfig.map(field => (
                      field.type === 'dropdown'
                        ? (
                          <Field
                            name={field.name}
                            component={field.component}
                            options={field.options}
                            placeholder={field.placeholder}
                            dense
                          />
                        )
                        : (
                          <Field
                            name={field.name}
                            component={field.component}
                            maxDate={field.maxDate ? field.maxDate(values) : undefined}
                            minDate={field.minDate ? field.minDate(values) : undefined}
                            timePrecision={field.timePrecision}
                            withIndefinitely
                            dense
                          />
                        )
                    ))}
                    <div className={styles.controlsWrapper}>
                      <Icon
                        icon="undo"
                        onClick={resetActiveRow}
                        size={16}
                        className={Color.default}
                      />
                      <Icon
                        icon="tick"
                        onClick={handleSubmit}
                        size={16}
                        className={Color.primary}
                      />
                    </div>
                  </>
                )}
              </Formik>
            </>
          )
            : (
              <>
                {Object.values(item.config).map(value => (
                  <div style={value.style && { color: value.style }} title={value?.value}>
                    {createCellTable(value)}
                  </div>
                ))}
                {addAndEditRow && (
                <div className={styles.controlsWrapper}>
                  {!activeRow && (
                  <>
                    <Icon
                      icon="edit"
                      onClick={() => setActiveRow(item.id)}
                      size={16}
                      className={Color.primary}
                    />
                    <Icon
                      icon="trash"
                      onClick={() => onDeleteClick(item.id)}
                      size={16}
                      className={Color.danger}
                    />
                  </>
                  )}
                </div>
                )}
              </>
            )}
        </div>
      ))}
      {activeRow === 'newRow' && (
      <div className={cx(styles.row, styles.row_active)}>
        <div />
        <Formik
          initialValues={getInitialVilue(activeRow)}
          onSubmit={onSubmit}
          innerRef={formRef}
        >
          {({ handleSubmit, values }) => (
            <>
              {formFieldConfig.map(field => (
                field.type === 'dropdown'
                  ? (
                    <Field
                      name={field.name}
                      component={field.component}
                      options={field.options}
                      placeholder={field.placeholder}
                      dense
                    />
                  )
                  : (
                    <Field
                      name={field.name}
                      component={field.component}
                      maxDate={field.maxDate ? field.maxDate(values) : undefined}
                      minDate={field.minDate ? field.minDate(values) : undefined}
                      timePrecision={field.timePrecision}
                      withIndefinitely
                      dense
                    />
                  )
              ))}
              <div className={styles.controlsWrapper}>
                <Icon
                  icon="undo"
                  onClick={resetActiveRow}
                  size={16}
                  className={Color.default}
                />
                <Icon
                  icon="tick"
                  onClick={handleSubmit}
                  size={16}
                  className={Color.primary}
                />
              </div>
            </>
          )}
        </Formik>
      </div>
      )}
      {addAndEditRow && (
      <div style={{ display: 'contents' }}>
        <Icon
          icon="add"
          className={cx(styles.addButton, { [styles.addButton_disabled]: !!activeRow })}
          size={20}
          onClick={addNewRow}
        />
      </div>
      )}
      {isModalOpen === 'delete' && (
      <ConfirmModal
        onConfirm={confirmDelete}
        closeModal={onCloseModal}
        isPending={isPending.delete}
        isDelete
      />
      )}
      {isModalOpen === 'confirmChanges' && (
      <Dialog
        withCloseButton={false}
        view="raised"
        color="warning"
        icon="warning-sign"
        iconColor="var(--orange30)"
        title="Warning"
        text="Your changes affect the past, which could result in traffic recalculations for the affected periods. Are you sure you want to save the changes?"
        leftButton={(
          <Button
            text="CONTROLS.CANCEL"
            view="flat"
            onClick={onCloseModal}
            disabled={isPending.put || isPending.post}
          />
              )}
        rightButton={(
          <Button
            text={`CONTROLS.${idToDelete ? 'DELETE' : 'SAVE'}`}
            view="smooth"
            color={idToDelete ? 'danger' : 'primary'}
            onClick={idToDelete ? confirmDelete : confirmChanges}
            loading={isPending.put || isPending.post}
          />
)}
        isOpen
      />
      )}
    </div>
  );
};
export default ModalTable;
