import React, { useMemo, useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { isEqual, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  TextField,
  DropdownField,
} from 'components/fields';
import { BANK_ACCOUNT_SCHEMA } from 'consts/validationSchemas';
import IsFormChanged from 'components/forms/IsFormChanged';
import OnChangeComponent from 'components/forms/OnChangeComponent';
import { FormButtonsGroup } from 'components/blocks';
import { useDictionariesApi } from 'hooks/api';
import { useContentShadow } from 'hooks';

import styles from './BankAccountForm.module.scss';

const BankAccountForm = ({
  initialValues = {},
  onSubmit,
  onCancel,
  onDelete,
  mode,
  changeMode,
  isPending,
  isFormPristine,
  formValuesRef,
}) => {
  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);
  const [filteredLegalEntities, setFilteredLegalEntities] = useState([]);
  const contentRef = useRef(null);
  const {
    showTopShadow,
    showBottomShadow,
  } = useContentShadow(contentRef);
  const {
    companiesOptions,
    legalEntitiesOptions,
    banksOptions,
  } = useDictionariesApi();

  const isView = useMemo(() => mode === 'view', [mode]);

  const onCompanyChanged = ({ value, form }) => {
    if (form.dirty) {
      form.setFieldValue('legalEntityId', undefined);
    }

    setFilteredLegalEntities(legalEntitiesOptions?.filter(I => I.companyId === value));
  };
  const changeToEdit = () => changeMode('edit');
  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }

    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={BANK_ACCOUNT_SCHEMA}
      validateOnMount
    >
      {({
        handleSubmit, errors, setFieldTouched, values,
      }) => (
        <Form className={styles.container}>
          {showTopShadow && <div className={styles.shadow} />}
          <div className={styles.content} ref={contentRef}>
            <Field
              name="name"
              label="INSTANCES.NAME"
              component={TextField}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              disabled={isView}
              isRequired={!isView}
              fill
            />
            <Field
              name="companyId"
              label="INSTANCES.COMPANY"
              component={DropdownField}
              options={companiesOptions}
              placeholder="PLACEHOLDERS.SELECT"
              disabled={isView}
              isRequired={!isView}
              fill
            />
            <Field
              name="legalEntityId"
              label="INSTANCES.LEGAL_ENTITY"
              component={DropdownField}
              options={filteredLegalEntities}
              placeholder="PLACEHOLDERS.SELECT"
              disabled={isView || !values.companyId}
              isRequired={!isView}
              fill
            />
            <Field
              name="bankId"
              label="INSTANCES.BANK"
              component={DropdownField}
              options={banksOptions}
              placeholder="PLACEHOLDERS.SELECT"
              disabled={isView}
              isRequired={!isView}
              fill
            />
            <Field
              name="accountHolder"
              label="INSTANCES.ACCOUNT_HOLDER"
              component={TextField}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              disabled={isView}
              isRequired={!isView}
              fill
            />
            <Field
              name="accountNumber"
              label="INSTANCES.ACCOUNT_NUMBER"
              component={TextField}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              disabled={isView}
              isRequired={!isView}
              fill
            />
          </div>
          <FormButtonsGroup
            onDelete={onDelete}
            onCancel={onCancel}
            setFieldTouched={setFieldTouched}
            errors={errors}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            isPending={isPending}
            showShadow={showBottomShadow}
          />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
          <OnChangeComponent field="companyId" onChange={onCompanyChanged} />
        </Form>
      )}
    </Formik>
  );
};

export default BankAccountForm;
