import endpoints from 'consts/endpoints';

export default ({
  successCallback = () => {},
  errorCallback = () => {},
} = {}) => ({
  url: endpoints.getOwnBankAccountsUrl(),
  body: {
    limit: 1000,
    offset: 0,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
  transform: ({ resourceList }) => ({
    ownBankAccountsOptions: resourceList.map(({ id, name, ...rest }) => ({
      value: id,
      label: name,
      ...rest,
    })),
    ownBankAccountsDictionary: resourceList.reduce((acc, { id, ...values }) => {
      acc[id] = {
        ...values,
      };

      return acc;
    }, {}),
  }),
  update: {
    ownBankAccountsOptions: (oldValue, newValue) => newValue,
    ownBankAccountsDictionary: (oldValue, newValue) => newValue,
  },
});
