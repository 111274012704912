import moment from 'moment/moment';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ConfirmModal } from 'components/modals';
import Modal from 'components/Modal';
import { CustomColumnsView } from 'components/blocks';
import {
  CALLER_IDS_POOL,
  CALLER_IDS_AVAILABLE,
  CALLER_IDS_ASSIGNED,
} from 'consts/columns';
import {
  useCompaniesApi, useDictionariesApi, useDidSendersApi, useUsersApi,
} from 'hooks/api';
import { useFilteredColumns, useScreen, useTable } from 'hooks';
import Table from 'components/Table';
import Button from '@setproduct-ui/core/Button';
import ScrollableTabs from 'components/ScrollableTabs';

import AssignForm from './AssignForm';
import HistoryForm from './HistoryForm';
import CallerIdForm from './CallerIdForm';
import styles from './CallerIdsScreen.module.scss';

const CallerIdsScreen = ({ screenRef }) => {
  const { ownCompany } = useCompaniesApi();
  const currentTabsIds = !ownCompany.parentCompanyId ? {
    pool: 0,
    available: 1,
    assigned: 2,
  } : {
    available: 1,
    assigned: 2,
  };
  const currentTabsList = !ownCompany.parentCompanyId ? [
    {
      id: 'pool',
      title: 'TABS.POOL',
      withoutCross: true,
    },
    {
      id: 'available',
      title: 'TABS.AVAILABLE',
      withoutCross: true,
    },
    {
      id: 'assigned',
      title: 'TABS.ASSIGNED',
      withoutCross: true,
    },
  ] : [
    {
      id: 'available',
      title: 'TABS.AVAILABLE',
      withoutCross: true,
    },
    {
      id: 'assigned',
      title: 'TABS.ASSIGNED',
      withoutCross: true,
    },
  ];

  const history = useHistory();
  const { initialTab } = useParams();
  const { currentUser } = useUsersApi();
  const [activeTab, setActiveTab] = useState(currentTabsIds[initialTab] || Object.values(currentTabsIds)[0]);
  const poolTableRef = useRef(null);
  const availableTableRef = useRef(null);
  const assignedTableRef = useRef(null);
  const {
    didSenders,
    didSendersAvailable,
    didSendersAssigned,
    didSendersMeta,
    didSendersAssignedMeta,
    didSendersAvailableMeta,
    getDidSenders,
    postAssignDidSender,
    putReAssignDidSender,
    putDidSender,
    putDeAssignDidSender,
    postDidSender,
    deleteDidSender,
    isPendingGetDidSenders,
    isPendingPostAssignDidSender,
    isPendingPutReAssignDidSender,
    isPendingPutDidSender,
    isPendingPostDidSender,
    isPendingDeleteDidSender,
  } = useDidSendersApi();
  const {
    companiesOptionsWithOwn,
    getCompaniesDictionary,
    getAgreementsDictionary,
  } = useDictionariesApi();
  const {
    sendQuery: poolSendQuery,
    limit: poolLimit,
    offset: poolOffset,
    onSortChanged: poolOnSortChanged,
    onFilterChanged: poolOnFilterChanged,
    setAndSendLimit: poolSetLimit,
    setAndSendOffset: poolSetOffset,
  } = useTable({
    sendQuery: getDidSenders,
    tableRef: poolTableRef,
  });
  const {
    sendQuery: availableSendQuery,
    limit: availableLimit,
    offset: availableOffset,
    onSortChanged: availableOnSortChanged,
    onFilterChanged: availableOnFilterChanged,
    setAndSendLimit: availableSetLimit,
    setAndSendOffset: availableSetOffset,
  } = useTable({
    sendQuery: (props) => {
      getDidSenders({
        ...props,
        'isAvailable[of]': true,
        'ownerCompanyId[of]': currentUser.companyId,
        storeKey: 'didSendersAvailable',
      });
    },
    tableRef: availableTableRef,
  });
  const {
    sendQuery: assignedSendQuery,
    limit: assignedLimit,
    offset: assignedOffset,
    onSortChanged: assignedOnSortChanged,
    onFilterChanged: assignedOnFilterChanged,
    setAndSendLimit: assignedSetLimit,
    setAndSendOffset: assignedSetOffset,
  } = useTable({
    sendQuery: (props) => {
      getDidSenders({
        ...props,
        'isAvailable[of]': false,
        'ownerCompanyId[of]': currentUser.companyId,
        storeKey: 'didSendersAssigned',
      });
    },
    tableRef: assignedTableRef,
  });
  const {
    isOpenCustomViewDrawer: poolIsOpenCustomViewDrawer,
    closeCustomViewDrawer: poolCloseCustomViewDrawer,
    openCustomViewDrawer: poolOpenCustomViewDrawer,
    onSaveCustomView: poolOnSaveCustomView,
    filteredColumns: poolFilteredColumns,
  } = useFilteredColumns({
    sessionKey: `${window.location.pathname}_pool`,
  });
  const {
    isOpenCustomViewDrawer: availableIsOpenCustomViewDrawer,
    closeCustomViewDrawer: availableCloseCustomViewDrawer,
    openCustomViewDrawer: availableOpenCustomViewDrawer,
    onSaveCustomView: availableOnSaveCustomView,
    filteredColumns: availableFilteredColumns,
  } = useFilteredColumns({
    sessionKey: `${window.location.pathname}_available`,
  });
  const {
    isOpenCustomViewDrawer: assignedIsOpenCustomViewDrawer,
    closeCustomViewDrawer: assignedCloseCustomViewDrawer,
    openCustomViewDrawer: assignedOpenCustomViewDrawer,
    onSaveCustomView: assignedOnSaveCustomView,
    filteredColumns: assignedFilteredColumns,
  } = useFilteredColumns({
    sessionKey: `${window.location.pathname}_assigned`,
  });
  const {
    openModal,
    editableRecord,
    onCloseModal,
    onDeleteClick,
    isFormPristine,
    formValuesRef,
    isOpenConfirm,
    onConfirmClose,
    closeConfirm,
    isOpenModal,
    setEditableRecord,
    openConfirm,
  } = useScreen({
    screenRef,
  });
  const {
    columnDefs,
    filteredColumns,
    onSaveCustomView,
    closeCustomViewDrawer,
    openCustomViewDrawer,
    isOpenCustomViewDrawer,
    sendQuery,
  } = useMemo(() => {
    switch (activeTab) {
      case 1:
        return {
          columnDefs: CALLER_IDS_AVAILABLE,
          filteredColumns: availableFilteredColumns,
          onSaveCustomView: availableOnSaveCustomView,
          closeCustomViewDrawer: availableCloseCustomViewDrawer,
          openCustomViewDrawer: availableOpenCustomViewDrawer,
          isOpenCustomViewDrawer: availableIsOpenCustomViewDrawer,
          sendQuery: availableSendQuery,
        };
      case 2:
        return {
          columnDefs: CALLER_IDS_ASSIGNED,
          filteredColumns: assignedFilteredColumns,
          onSaveCustomView: assignedOnSaveCustomView,
          closeCustomViewDrawer: assignedCloseCustomViewDrawer,
          openCustomViewDrawer: assignedOpenCustomViewDrawer,
          isOpenCustomViewDrawer: assignedIsOpenCustomViewDrawer,
          sendQuery: assignedSendQuery,
        };
      case 0:
      default:
        return {
          columnDefs: CALLER_IDS_POOL,
          filteredColumns: poolFilteredColumns,
          onSaveCustomView: poolOnSaveCustomView,
          closeCustomViewDrawer: poolCloseCustomViewDrawer,
          openCustomViewDrawer: poolOpenCustomViewDrawer,
          isOpenCustomViewDrawer: poolIsOpenCustomViewDrawer,
          sendQuery: poolSendQuery,
        };
    }
  }, [
    activeTab,
    availableFilteredColumns,
    assignedFilteredColumns,
    poolFilteredColumns,
    poolIsOpenCustomViewDrawer,
    assignedIsOpenCustomViewDrawer,
    availableIsOpenCustomViewDrawer,
  ]);
  const prepareAndSetEditableRecord = (data) => {
    setEditableRecord({
      ...data,
      effectiveFrom: moment(data.effectiveFrom).toDate(),
      effectiveTill: data.effectiveTill === null ? null : moment(data.effectiveTill).toDate(),
      actualEffectiveFrom: moment(data.actualEffectiveFrom).toDate(),
      actualEffectiveTill: data.actualEffectiveTill === null ? null : moment(data.actualEffectiveTill).toDate(),
      createdAt: moment(data.actualCreatedAt).toDate(),
      updatedAt: moment(data.actualUpdatedAt).toDate(),
    });
  };
  const poolColumns = useMemo(() => [
    ...CALLER_IDS_POOL,
    {
      headerName: 'INSTANCES.ACTIONS',
      cellClass: 'appearing-cell',
      cellRenderer: 'cellRendererCallerIdsButtons',
      width: 120,
      minWidth: 120,
      resizable: false,
      sortable: false,
      lockVisible: true,
      pinned: 'right',
      cellRendererParams: {
        prepareAndSetEditableRecord,
        setEditableRecord,
        openModal,
        openConfirm,
      },
    },
  ], []);
  const availableColumns = useMemo(() => [
    ...CALLER_IDS_AVAILABLE,
    {
      headerName: 'INSTANCES.ACTIONS',
      cellClass: 'appearing-cell',
      cellRenderer: 'cellRendererCallerIdsButtons',
      width: 120,
      minWidth: 120,
      resizable: false,
      sortable: false,
      lockVisible: true,
      pinned: 'right',
      cellRendererParams: {
        prepareAndSetEditableRecord,
        setEditableRecord,
        openModal,
        openConfirm,
        isPoolTable: false,
      },
    },
  ], []);
  const assignColumns = useMemo(() => [
    ...CALLER_IDS_ASSIGNED,
    {
      headerName: 'INSTANCES.ACTIONS',
      cellClass: 'appearing-cell',
      cellRenderer: 'cellRendererCallerIdsButtons',
      width: 120,
      minWidth: 120,
      resizable: false,
      sortable: false,
      lockVisible: true,
      pinned: 'right',
      cellRendererParams: {
        prepareAndSetEditableRecord,
        setEditableRecord,
        openModal,
        openConfirm,
        isPoolTable: false,
      },
    },
  ], []);

  const handleChangeActiveTab = (newVal) => {
    history.push(`/caller-ids/${currentTabsList[newVal].id}`);
    setActiveTab(newVal);
  };
  const onRowClicked = ({ data }) => {
    prepareAndSetEditableRecord(data);
    openModal('view');
  };
  const onSubmitAssign = (body) => {
    if (isOpenModal === 'reassign') {
      putReAssignDidSender({
        body,
        successCallback: () => {
          sendQuery();
          onCloseModal({ isSave: true });
        },
      });
    } else {
      postAssignDidSender({
        body,
        successCallback: () => {
          sendQuery();
          onCloseModal({ isSave: true });
        },
      });
    }
  };
  const onSubmitCallerId = (body) => {
    (editableRecord ? putDidSender : postDidSender)({
      body,
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
    });
  };
  const onDeleteConfirm = () => {
    closeConfirm();
    deleteDidSender({
      id: editableRecord.id,
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
      errorCallback: () => onCloseModal({ isSave: true }),
    });
  };
  const onDeassignConfirm = () => {
    closeConfirm();
    putDeAssignDidSender({
      id: editableRecord.actualVersionId,
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
      errorCallback: () => onCloseModal({ isSave: true }),
    });
  };
  const renderPanel = (tab) => {
    switch (tab.id) {
      case 'pool':
        return (
          <Table
            dataTestIdPrefix="/bo/caller-ids/pool"
            columnDefs={poolColumns}
            rowData={didSenders}
            onRowClicked={onRowClicked}
            showFilters={false}
            limit={poolLimit}
            total={didSendersMeta?.size}
            page={poolOffset}
            filteredColumns={poolFilteredColumns}
            onChangePage={poolSetOffset}
            onChangeLimit={poolSetLimit}
            sendQuery={poolSendQuery}
            isPending={isPendingGetDidSenders}
            tableRef={poolTableRef}
            onSortChanged={poolOnSortChanged}
            onFilterChanged={poolOnFilterChanged}
          />
        );
      case 'available':
        return (
          <Table
            dataTestIdPrefix="/bo/caller-ids/available"
            columnDefs={availableColumns}
            rowData={didSendersAvailable}
            showFilters={false}
            limit={availableLimit}
            total={didSendersAvailableMeta?.size}
            page={availableOffset}
            filteredColumns={availableFilteredColumns}
            onChangePage={availableSetOffset}
            onChangeLimit={availableSetLimit}
            sendQuery={availableSendQuery}
            isPending={isPendingGetDidSenders}
            tableRef={availableTableRef}
            onSortChanged={availableOnSortChanged}
            onFilterChanged={availableOnFilterChanged}
          />
        );
      case 'assigned':
        return (
          <Table
            dataTestIdPrefix="/bo/caller-ids/assigned"
            columnDefs={assignColumns}
            rowData={didSendersAssigned}
            showFilters={false}
            limit={assignedLimit}
            total={didSendersAssignedMeta?.size}
            page={assignedOffset}
            filteredColumns={assignedFilteredColumns}
            onChangePage={assignedSetOffset}
            onChangeLimit={assignedSetLimit}
            sendQuery={assignedSendQuery}
            isPending={isPendingGetDidSenders}
            tableRef={assignedTableRef}
            onSortChanged={assignedOnSortChanged}
            onFilterChanged={assignedOnFilterChanged}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    getCompaniesDictionary();
    getAgreementsDictionary();
  }, []);
  useEffect(() => {
    sendQuery();
  }, [activeTab]);

  return (
    <div className={styles.container}>
      <ScrollableTabs
        activeTab={activeTab}
        setActiveTab={handleChangeActiveTab}
        tabsList={currentTabsList}
        styles={{ wrapper: { flex: 1 } }}
        renderPanel={renderPanel}
        alwaysShowTabsPanelElement
        elementInTabsPanel={(
          <div style={{ display: 'flex', flexDirection: 'row', gap: 14 }}>
            <Button
              type="default"
              view="outlined"
              color="primary"
              icon="add-column-right"
              onClick={openCustomViewDrawer}
              text="CONTROLS.COLUMNS"
              data-testid={`${window.location.pathname}/table/columns`}
              dense
            />
            {activeTab === 0 && (
              <Button
                icon="plus"
                color="primary"
                className="screen-header__button"
                onClick={() => openModal('add')}
                text="CONTROLS.CALLER_IDS.NEW_NUMBER"
                data-testid={`${window.location.pathname}/create-instance`}
                dense
              />
            )}
          </div>
        )}
      />
      {isOpenCustomViewDrawer && (
        <CustomColumnsView
          initialValues={filteredColumns}
          columnsNames={columnDefs}
          onSubmit={onSaveCustomView}
          onClose={closeCustomViewDrawer}
          isOpen={isOpenCustomViewDrawer}
        />
      )}
      {(isOpenModal === 'assign' || isOpenModal === 'reassign') && (
        <Modal
          mode={isOpenModal}
          closeModal={onCloseModal}
          title="INSTANCES.NUMBER"
          editableRecord={editableRecord}
        >
          <AssignForm
            onSubmit={onSubmitAssign}
            onCancel={onCloseModal}
            mode={isOpenModal}
            onDelete={onDeleteClick}
            changeMode={openModal}
            initialValues={editableRecord}
            companiesOptions={companiesOptionsWithOwn}
            isPending={isPendingPutReAssignDidSender || isPendingPostAssignDidSender}
            isFormPristine={isFormPristine}
            formValuesRef={formValuesRef}
          />
        </Modal>
      )}
      {isOpenModal === 'history' && (
        <Modal
          mode={isOpenModal}
          closeModal={onCloseModal}
          titleText="INSTANCES.ASSIGNMENT_HISTORY"
          size="lg"
          editableRecord={editableRecord}
        >
          <HistoryForm
            onSubmit={onSubmitAssign}
            onCancel={onCloseModal}
            mode={isOpenModal}
            onDelete={onDeleteClick}
            changeMode={openModal}
            initialValues={editableRecord}
            companiesOptions={companiesOptionsWithOwn}
            isPending={isPendingPutReAssignDidSender || isPendingPostAssignDidSender}
            formValuesRef={formValuesRef}
          />
        </Modal>
      )}
      {(isOpenModal === 'view'
          || isOpenModal === 'add'
          || isOpenModal === 'edit'
      ) && (
        <Modal
          mode={isOpenModal}
          closeModal={onCloseModal}
          title="INSTANCES.NUMBER"
          editableRecord={editableRecord}
        >
          <CallerIdForm
            onSubmit={onSubmitCallerId}
            onCancel={onCloseModal}
            mode={isOpenModal}
            onDelete={onDeleteClick}
            changeMode={openModal}
            initialValues={editableRecord}
            companiesOptions={companiesOptionsWithOwn}
            isPending={isPendingDeleteDidSender || isPendingPutDidSender || isPendingPostDidSender}
            isFormPristine={isFormPristine}
            formValuesRef={formValuesRef}
          />
        </Modal>
      )}
      {isOpenConfirm === 'deassign' && (
        <ConfirmModal
          title="INSTANCES.CALLER_IDS.DEASSIGN_NUMBER"
          text="Are you sure you want to deassign the number?"
          leftText="Cancel"
          rightText="Confirm"
          closeModal={closeConfirm}
          onConfirm={onDeassignConfirm}
        />
      )}
      {(isOpenConfirm === 'confirm' || isOpenConfirm === 'delete') && isOpenConfirm !== 'history' && (
        <ConfirmModal
          isDelete={isOpenConfirm === 'delete'}
          closeModal={closeConfirm}
          onConfirm={
            isOpenConfirm === 'delete' ? onDeleteConfirm : onConfirmClose
          }
        />
      )}
    </div>
  );
};

export default CallerIdsScreen;
