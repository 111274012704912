import React, { useState, useEffect, useMemo } from 'react';
import {
  DonutChart,
} from 'bizcharts';
import moment from 'moment';
import { Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import { useCompaniesApi, useDictionariesApi } from 'hooks/api';
import kFormatter from 'helpers/kFormatter';
import round from 'helpers/round';
import { CHANNELS_DICTIONARY } from 'consts';

import styles from './OverviewTab.module.scss';

const OverviewTab = ({
  companyId,
  selectedPeriod,
}) => {
  const { t } = useTranslation();
  const [topSenders, setTopSenders] = useState([]);
  const [topChatbots, setTopChatbots] = useState([]);
  const [topChannels, setTopChannels] = useState([]);
  const {
    getTopSendersByCompany,
    getTopChatbotsByCompany,
    getTopChannelsByCompany,
    isPendingGetTopSendersByCompany,
    isPendingGetTopChatbotsByCompany,
    isPendingGetTopChannelsByCompany,
  } = useCompaniesApi();
  const { flowsDictionary } = useDictionariesApi();

  /* eslint-disable camelcase */
  const getLegend = (data, externalKeyField) => ({
    position: 'bottom',
    layout: 'horizontal',
    itemName: {
      style: () => ({
        fontSize: 13,
        fontWeight: 400,
        lineHeight: 20,
        fill: 'var(--grey50)',
        width: 200,
      }),
    },
    itemValue: {
      formatter: keyField => kFormatter(data?.find(I => I[externalKeyField] === keyField)?.total_count),
      style: config => ({
        fontWeight: 'bold',
        fontSize: 13,
        lineHeight: 20,
        opacity: config.unchecked ? 0.1 : 1,
      }),
    },
  });

  const sendersLegend = useMemo(() => getLegend(topSenders, 'sender_id'), [topSenders]);
  const chatbotsLegend = useMemo(() => getLegend(topChatbots, 'flow_id'), [topChatbots]);
  const meta = useMemo(() => ({
    flow_id: {
      formatter: val => flowsDictionary[val]?.name,
    },
    total_count: {
      formatter: val => round({ num: val, cutDecimalZeroes: true }),
    },
  }), []);
  const {
    channelsColors,
    donutColors,
  } = useMemo(() => {
    const colorsDictionary = {
      1: '#787885',
      2: '#E11D48',
      3: '#8847DD',
      4: '#2CA560',
      5: '#02BCB3',
      6: '#1f4921',
      7: '#3D59EB',
      8: '#FF9D00',
      10102: '#ec407a',
      10103: '#6f1f19',
      10201: '#e5ae40',
    };

    return {
      channelsColors: colorsDictionary,
      donutColors: Object.values(colorsDictionary)?.map(I => I)?.slice(2, 7),
    };
  }, []);
  const tooltip = useMemo(() => ({
    domStyles: {
      'g2-tooltip': {
        backgroundColor: 'var(--white)',
        boxShadow: 'var(--greyShadow8dp)',
        opacity: 1,
        borderRadius: '8px',
      },
      'g2-tooltip-name': {
        color: 'var(--grey50)',
        textTransform: 'capitalize',
        fontSize: 13,
      },
      'g2-tooltip-value': {
        color: 'var(--black)',
        fontSize: 13,
      },
      'g2-tooltip-title': {
        color: 'var(--black)',
        fontSize: 13,
      },
    },
  }), []);

  useEffect(() => {
    const filters = {
      company_id: companyId,
      period: `${selectedPeriod} day`,
      limit: 5,
    };

    getTopChannelsByCompany({
      company_id: companyId,
      tz: Intl.DateTimeFormat().resolvedOptions()?.timeZone,
      period_start: moment().subtract(selectedPeriod, 'days').format('YYYY-MM-DD'),
      period_end: moment().format('YYYY-MM-DD'),
      granularity: '1 day',
      successCallback: (data) => {
        const totalCount = data?.find(I => I.g === 3)?.cnt_t;
        const channelsDictionaryModify = {
          ...CHANNELS_DICTIONARY,
          10102: 'Flash Call',
          10103: 'Pin-to-Speech',
          10201: 'Lanck voice',
        };

        setTopChannels(data?.reduce((acc, item) => {
          if (item.g === 1 && !!channelsDictionaryModify[item.channel_type]) {
            acc.push({
              channel: channelsDictionaryModify[item.channel_type],
              percent: item.cnt_t / totalCount * 100,
              color: channelsColors[item.channel_type],
            });
          }
          return acc;
        }, []));
      },
    });
    getTopSendersByCompany({
      ...filters,
      successCallback: data => setTopSenders(data?.filter(I => !I.g)),
    });
    getTopChatbotsByCompany({
      ...filters,
      successCallback: data => setTopChatbots(data?.filter(I => !I.g)?.map(I => ({ ...I, flow_id: I.flow_id?.toString() }))),
    });
  }, [selectedPeriod]);

  return (
    <div className={styles.container}>
      <span className={styles.title}>{t('SCREENS.COMPANIES.CHANNELS')}</span>
      <span className={styles.subtitle}>{t('SCREENS.COMPANIES.CHANNELS_DESCRIPTION')}</span>
      <div className={styles.channelsWrapper}>
        {topChannels?.map(I => (
          <div className={styles.channelItem} style={{ maxWidth: `${I.percent}%` }}>
            <span title={I.channel}>{I.channel}</span>
            <span>{`${round({ num: I.percent, cutDecimalZeroes: true })}%`}</span>
            <div style={{ backgroundColor: I.color }} />
          </div>
        ))}
        {!isPendingGetTopChannelsByCompany && !topChannels?.length && (
          <div className={styles.noData}>
            <Icon size={24} icon="info-sign" color="var(--grey40)" />
            {t('SCREENS.COMPANIES.NO_DATA')}
          </div>
        )}
      </div>
      <div className={styles.donutChartsWrapper}>
        <div className={styles.topSendersWrapper}>
          <span className={styles.title}>{t('SCREENS.COMPANIES.TOP_SENDERS')}</span>
          <span className={styles.subtitle}>{t('SCREENS.COMPANIES.TOP_SENDERS_DESCRIPTION')}</span>
          {!!topSenders?.length && !isPendingGetTopSendersByCompany ? (
            <DonutChart
              data={topSenders}
              autoFit
              radius={1}
              innerRadius={0.77}
              color={donutColors}
              padding="auto"
              angleField="total_count"
              colorField="sender_id"
              meta={meta}
              statistic={{ visible: false }}
              label={{ visible: false }}
              legend={sendersLegend}
              tooltip={tooltip}
            />
          ) : (
            <div className={styles.noData}>
              <Icon size={24} icon="info-sign" color="var(--grey40)" />
              {t('SCREENS.COMPANIES.NO_DATA')}
            </div>
          )}
        </div>
        <div className={styles.topChatbotsWrapper}>
          <span className={styles.title}>{t('SCREENS.COMPANIES.TOP_CHATBOTS')}</span>
          <span className={styles.subtitle}>{t('SCREENS.COMPANIES.TOP_CHATBOTS_DESCRIPTION')}</span>
          {!!topChatbots?.length && !isPendingGetTopChatbotsByCompany ? (
            <DonutChart
              data={topChatbots}
              autoFit
              radius={1}
              innerRadius={0.77}
              color={donutColors}
              padding="auto"
              angleField="total_count"
              colorField="flow_id"
              meta={meta}
              statistic={{ visible: false }}
              label={{ visible: false }}
              legend={chatbotsLegend}
              tooltip={tooltip}
            />
          ) : (
            <div className={styles.noData}>
              <Icon size={24} icon="info-sign" color="var(--grey40)" />
              {t('SCREENS.COMPANIES.NO_DATA')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OverviewTab;
