import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getBanks as getBanksApi,
  postBank as postBankApi,
  putBank as putBankApi,
  deleteBank as deleteBankApi,
} from 'queries/banks';

import {
  getObject,
  getArray,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetBanks,
      lastUpdated: lastUpdatedGetBanks,
    },
    getBanks,
  ] = useMutation(getBanksApi);
  const [{ isPending: isPendingPostBank }, postBank] = useMutation(postBankApi);
  const [{ isPending: isPendingPutBank }, putBank] = useMutation(putBankApi);
  const [{ isPending: isPendingDeleteBank }, deleteBank] = useMutation(deleteBankApi);

  const selector = useCallback(state => ({
    banks: getArray(state, 'banks'),
    banksMeta: getObject(state, 'banksMeta'),
  }), []);
  const data = useISESelector(selector);

  return {
    getBanks,
    postBank,
    putBank,
    deleteBank,
    isPendingGetBanks,
    isPendingPostBank,
    isPendingPutBank,
    isPendingDeleteBank,
    lastUpdatedGetBanks,
    ...data,
  };
};
