import endpoints from 'consts/endpoints';

export default ({
  id,
  successCallback,
} = {}) => ({
  url: endpoints.getBalancesByCompanyIdUrl(id),
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
  },
});
