import React, { useEffect, useRef, useState } from 'react';

import Button from '@setproduct-ui/core/Button';

import Card from './Card';
import styles from './AgreementsCardsList.module.scss';

const AgreementsCardsList = ({
  isVisible,
  agreementsList,
  selectedCard,
  setSelectedCard,
  balancesDictionary,
}) => {
  const cardsListRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const isElementVisible = element => new Promise((resolve) => {
    if (element) {
      const observer = new IntersectionObserver(([entry], obs) => {
        resolve(entry.isIntersecting);
        obs.disconnect();
      }, {
        root: null,
        rootMargin: '0px',
        threshold: 1,
      });

      observer.observe(element);
    } else {
      resolve(false);
    }
  });
  const slideTo = async (reverse) => {
    const elementsArray = await Promise.all(
      [...cardsListRef.current.children || []].map(async element => ({
        element,
        visible: await isElementVisible(element),
      })),
    );

    if (reverse) {
      elementsArray?.reverse();
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < elementsArray?.length; (i++)) {
      if (!elementsArray[i].visible) {
        if (!elementsArray.slice(i + 1).some(item => item.visible)) {
          elementsArray[i].element.scrollIntoView({ behavior: 'smooth' });
          break;
        }

        // eslint-disable-next-line no-plusplus
        for (let j = i; j < elementsArray?.length; j++) {
          if (!elementsArray[j]?.visible) {
            elementsArray[j].element.scrollIntoView({ behavior: 'smooth' });
            break;
          }
        }
      }
    }
  };

  useEffect(() => {
    if (isVisible) {
      const container = cardsListRef.current;
      const updateScrollButtons = () => {
        setCanScrollLeft(container.scrollLeft > 0);
        setCanScrollRight(
          container.scrollLeft < container.scrollWidth - container.clientWidth,
        );
      };

      if (container) {
        updateScrollButtons();
        container.addEventListener('scroll', updateScrollButtons);
      }

      return () => {
        container?.removeEventListener('scroll', updateScrollButtons);
      };
    }

    return null;
  }, [agreementsList, isVisible]);

  return (
    <div className={styles.container}>
      {canScrollLeft && (
        <Button
          icon="chevron-left"
          onClick={() => slideTo(true)}
          className={styles.slideButtonLeft}
          view="smooth"
        />
      )}
      <div className={styles.cardsList} ref={cardsListRef}>
        {agreementsList.map(agreement => (
          <Card
            key={agreement.id}
            isActive={selectedCard === agreement.id}
            onClick={() => setSelectedCard(agreement.id)}
            balancesDictionary={balancesDictionary}
            {...agreement}
          />
        ))}
      </div>
      {canScrollRight && (
        <Button
          icon="chevron-right"
          onClick={() => slideTo()}
          className={styles.slideButtonRight}
          view="smooth"
        />
      )}
    </div>
  );
};

export default AgreementsCardsList;
