import { useDictionariesApi } from 'hooks/api';

export default ({
  value,
  dictionaryKey,
  showMissedOption = true,
  fieldKeyToDisplay = 'name',
}) => {
  const dictionaries = useDictionariesApi();

  return dictionaries?.[dictionaryKey]?.[value]?.[fieldKeyToDisplay]
    || ((value && showMissedOption) ? `Missed option: ${value}` : '');
};
