import React from 'react';
import { useTranslation } from 'react-i18next';

import './style.scss';

export default ({
  value,
  valueFormatted,
}) => {
  const { t } = useTranslation();

  return <span className="text-cell-renderer" title={t(valueFormatted || value)}>{t(valueFormatted || value)}</span>;
};
