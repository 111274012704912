import React, { useState, useEffect } from 'react';
import { Tab } from '@blueprintjs/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import TabsSegmented from '@setproduct-ui/core/Tabs/Segmented';
import Table from 'components/Table';
import { useTable } from 'hooks';
import { useWhatsAppTemplatesApi, useConstructorTemplatesApi } from 'hooks/api';
import { CHANNELS_DICTIONARY, CHANNELS_OPTIONS } from 'consts';
import convertTemplateToConstructorFormat from 'screens/AccountManagementScreens/WhatsAppTemplatesScreen/WhatsAppTemplateForm/converterTemplateToConstructorFormat';

import TemplatePreview from './TemplatePreview';
import styles from './AssetsTab.module.scss';

const AssetsTab = ({
  isActiveTab,
  companyId,
}) => {
  const CONTENT_COLUMNS = [
    {
      headerName: 'INSTANCES.NAME',
      field: 'name',
      flex: 1,
      minWidth: 115,
      filter: 'maskColumnFilter',
    },
    {
      headerName: 'INSTANCES.CHANNEL',
      field: 'channel',
      valueFormatter: ({ value }) => CHANNELS_DICTIONARY[value],
      filter: 'listColumnFilter',
      headerComponentParams: {
        items: CHANNELS_OPTIONS,
      },
    },
    {
      headerName: 'INSTANCES.UPDATED',
      field: 'updatedAt',
      valueFormatter: ({ value }) => moment(value).format('DD.MM.YYYY HH:mm:ss'),
      filter: 'dateColumnFilter',
    },
  ];
  const CONVERSATION_COLUMNS = [
    {
      headerName: 'INSTANCES.NAME',
      field: 'name',
      flex: 1,
      minWidth: 115,
      filter: 'maskColumnFilter',
    },
    {
      headerName: 'INSTANCES.CHANNEL',
      field: 'channel',
      valueFormatter: ({ value }) => CHANNELS_DICTIONARY[value],
      filter: 'listColumnFilter',
      headerComponentParams: {
        items: CHANNELS_OPTIONS,
      },
    },
    {
      headerName: 'INSTANCES.UPDATED',
      field: 'updatedAt',
      valueFormatter: ({ value }) => moment(value).format('DD.MM.YYYY HH:mm:ss'),
      filter: 'dateColumnFilter',
    },
  ];
  const WA_COLUMNS = [
    {
      headerName: 'INSTANCES.NAME',
      field: 'name',
      flex: 1,
      minWidth: 115,
      filter: 'maskColumnFilter',
    },
    {
      headerName: 'INSTANCES.WHATS_APP_TEMPLATES.CATEGORY',
      field: 'category',
      filter: 'listColumnFilter',
      headerComponentParams: {
        reservedList: 'whatsAppCategories',
      },
    },
    {
      headerName: 'INSTANCES.CREATED',
      field: 'createdAt',
      valueFormatter: ({ value }) => moment(value).format('DD.MM.YYYY HH:mm:ss'),
      filter: 'dateColumnFilter',
    },
  ];

  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(null);
  const [whatsAppTemplatesList, setWhatsAppTemplatesList] = useState([]);
  const [contentTemplatesList, setContentTemplatesList] = useState([]);
  const [conversationTemplatesList, setConversationTemplatesList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const {
    getWhatsAppTemplates,
    isPendingGetTemplates,
    lastUpdatedGetTemplates,
  } = useWhatsAppTemplatesApi();
  const {
    getConversationTemplates,
    getContentTemplates,
    isPendingGetConversationTemplates,
    isPendingGetContentTemplates,
    lastUpdatedGetConversationTemplates,
    lastUpdatedGetContentTemplates,
  } = useConstructorTemplatesApi();
  const {
    onFilterChanged: onContentFilterChanged,
    onSortChanged: onContentSortChanged,
    sendQuery: getContentTemplatesList,
  } = useTable({
    sendQuery: (params) => {
      getContentTemplates({
        ...params,
        limit: 1000,
        'companyId[of]': companyId,
        saveToRedux: false,
        successCallback: ({ content }) =>
          setContentTemplatesList(content),
      });
    },
  });
  const {
    onFilterChanged: onConversationFilterChanged,
    onSortChanged: onConversationSortChanged,
    sendQuery: getConversationTemplatesList,
  } = useTable({
    sendQuery: (params) => {
      getConversationTemplates({
        ...params,
        limit: 1000,
        'companyId[of]': companyId,
        saveToRedux: false,
        successCallback: ({ resourceList }) =>
          setConversationTemplatesList(resourceList),
      });
    },
  });
  const {
    onFilterChanged: onWhatsAppFilterChanged,
    onSortChanged: onWhatsAppSortChanged,
    sendQuery: getWhatsAppTemplatesList,
  } = useTable({
    sendQuery: (params) => {
      getWhatsAppTemplates({
        ...params,
        'companyId[of]': companyId,
        limit: 1000,
        saveToRedux: false,
        successCallback: ({ resourceList }) =>
          setWhatsAppTemplatesList(resourceList),
      });
    },
  });

  const handleWhatsAppRowClick = ({ data }) => {
    const convertedData = convertTemplateToConstructorFormat(data);

    setSelectedTemplate({
      name: data.name,
      file: !!convertedData?.header?.format && convertedData?.header?.format !== 'TEXT' ? convertedData.header : null,
      headerText: convertedData.header?.text,
      bodyText: convertedData.selectedType === 'whatsAppAuthenticationCard'
        ? '{VERIFICATION_CODE} is your verification code. For your security, do not share this code.'
        : convertedData?.body?.text,
      footerText: convertedData?.footer?.text,
      buttons: convertedData.suggestionsInside?.map(I => I.text),
      cards: convertedData.cards?.map(I => ({
        headerText: I.header?.text,
        bodyText: I.body?.text,
        file: !!I?.header?.format && I?.header?.format !== 'TEXT' ? I.header : null,
        footerText: I?.footer?.text,
        buttons: I.suggestionsInside?.map(suggestion => suggestion.text),
      })),
      channel: 4,
    });
  };
  const handleConversationRowClick = ({ data }) => {
    const textContent = data.messages?.find(I => I.type === 'text' || I.text || I.textMessage);

    setSelectedTemplate({
      name: data.name,
      bodyText: textContent?.text?.body || textContent?.text || textContent?.textMessage || textContent,
      file: data.messages?.find(I => I.fileMessage || I.fileMimeType),
    });
  };
  const handleContentRowClick = ({ data }) => {
    const content = JSON.parse(data.content);
    const html = data?.message?.body?.rendered?.find(I => I.contentType === 'html')?.content;

    setSelectedTemplate({
      name: data.name,
      headerText: content?.title || content?.header?.text || content?.subTitle,
      bodyText: content?.message
        || content?.body?.text
        || content?.text?.body
        || content?.subject
        || (content.location && `Latitude: ${content.location.latitude}\nLongitude: ${content.location.longitude}\nName: ${content.location.name}\nAddress: ${content.location.address}`),
      footerText: content?.footer?.text,
      file: content?.image || (!!content?.header?.type && content?.header?.type !== 'text' ? content.header : null),
      buttons: (content?.suggestionsInside?.length && content?.suggestionsInside?.map(I => I.text))
        || (content?.buttonCaption && [content?.buttonCaption])
        || (content?.action?.name && [content?.action?.name])
        || (content?.action?.button && [content?.action?.button]),
      buttonsOutside: content?.suggestionsOutside?.map(I => I.text),
      cards: content.cards?.length ? content.cards?.map(I => ({
        headerText: I?.title,
        bodyText: I?.message,
        file: I?.image,
        buttons: I?.suggestionsInside?.map(suggestion => suggestion.text),
      })) : null,
      html,
      channel: data.channel,
    });
  };

  useEffect(() => {
    getWhatsAppTemplatesList();
    getConversationTemplatesList();
    getContentTemplatesList();
  }, []);
  useEffect(() => {
    if (isActiveTab && !activeTab) {
      setActiveTab('content');
    }
  }, [isActiveTab]);

  return (
    <div className={styles.container}>
      <div className={styles.tableWrapper}>
        <div className={styles.header}>
          <span>{t('SCREENS.COMPANIES.TEMPLATES')}</span>
          <TabsSegmented
            color="default"
            id="assetsTabs"
            selectedTabId={activeTab}
            onChange={setActiveTab}
          >
            <Tab id="content" title={t('SCREENS.COMPANIES.CONTENT')} />
            <Tab id="conversation" title={t('SCREENS.COMPANIES.CONVERSATION')} />
            <Tab id="whatsApp" title="Whats App" />
          </TabsSegmented>
        </div>
        {activeTab === 'content' && (
          <Table
            columnDefs={CONTENT_COLUMNS}
            rowData={contentTemplatesList}
            onRowClicked={handleContentRowClick}
            isPending={isPendingGetContentTemplates || !lastUpdatedGetContentTemplates}
            withPagination={false}
            rowHeight={50}
            denseRowHeight={50}
            headerHeight={28}
            denseHeaderHeight={28}
            sendQuery={getContentTemplatesList}
            onFilterChanged={onContentFilterChanged}
            onSortChanged={onContentSortChanged}
          />
        )}
        {activeTab === 'conversation' && (
          <Table
            columnDefs={CONVERSATION_COLUMNS}
            rowData={conversationTemplatesList}
            onRowClicked={handleConversationRowClick}
            isPending={isPendingGetConversationTemplates || !lastUpdatedGetConversationTemplates}
            withPagination={false}
            rowHeight={50}
            denseRowHeight={50}
            headerHeight={28}
            denseHeaderHeight={28}
            sendQuery={getConversationTemplatesList}
            onFilterChanged={onConversationFilterChanged}
            onSortChanged={onConversationSortChanged}
          />
        )}
        {activeTab === 'whatsApp' && (
          <Table
            columnDefs={WA_COLUMNS}
            rowData={whatsAppTemplatesList}
            onRowClicked={handleWhatsAppRowClick}
            isPending={isPendingGetTemplates || !lastUpdatedGetTemplates}
            withPagination={false}
            rowHeight={50}
            denseRowHeight={50}
            headerHeight={28}
            denseHeaderHeight={28}
            sendQuery={getWhatsAppTemplatesList}
            onFilterChanged={onWhatsAppFilterChanged}
            onSortChanged={onWhatsAppSortChanged}
          />
        )}
      </div>
      <div className={styles.previewWrapper}>
        {selectedTemplate ? (
          <TemplatePreview {...selectedTemplate} />
        ) : <div className={styles.previewPlaceholder}>{t('SCREENS.COMPANIES.SELECT_TEMPLATES')}</div>}
      </div>
    </div>
  );
};

export default AssetsTab;
