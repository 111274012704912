import React, {
  useEffect,
  useMemo, useState,
} from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import Table from 'components/Table';
import { AgreementsForm } from 'components/forms';
import { CustomColumnsView } from 'components/blocks';
import { ConfirmModal } from 'components/modals';
import { useScreen } from 'hooks';
import { AGREEMENTS } from 'consts/columns';
import {
  useAgreementsApi,
  useDictionariesApi,
} from 'hooks/api';
import toastRef from 'helpers/toast';
import MenuItem from '@setproduct-ui/core/Menu/MenuItem';

export default ({ screenRef }) => {
  // hooks
  const [selectedVersionId, setSelectedVersionId] = useState(0);
  const {
    agreements,
    agreementsMeta,
    getAgreements,
    isPendingGetAgreements,
    deleteAgreement,
    deleteAgreementVersion,
    isPendingDeleteAgreement,
    isPendingDeleteAgreementVersion,
    postAgreement,
    postAgreementVersion,
    putAgreementVersion,
    isPutAgreementVersion,
    isPendingPostAgreementVersion,
    lastUpdatedGetAgreements,
  } = useAgreementsApi();
  const {
    timezones,
    currencies,
    getLegalEntitiesDictionary,
    getOwnLegalEntitiesDictionary,
    getCompaniesDictionary,
    getBankAccountsDictionary,
    getOwnBankAccountsDictionary,
  } = useDictionariesApi();
  const { t } = useTranslation();
  const onRowClickCallback = data => ({
    ...data,
    actualEffectiveFrom: data.actualEffectiveFrom === null ? null : moment(data.actualEffectiveFrom).toDate(),
    actualEffectiveTill: data.actualEffectiveTill === null ? null : moment(data.actualEffectiveTill).toDate(),
    agreementDate: data.agreementDate === null ? null : moment(data.agreementDate).toDate(),
  });
  const {
    openModal,
    editableRecord,
    sendQuery,
    onCloseModal,
    limit,
    offset,
    setOffset,
    setLimit,
    onSortChanged,
    onFilterChanged,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    openConfirm,
    isFormPristine,
    formValuesRef,
    isOpenConfirm,
    closeConfirm,
    onConfirmClose,
    isOpenModal,
    onRowClicked,
  } = useScreen({
    screenRef,
    getFunc: getAgreements,
    onRowClickCallback,
  });

  const timezonesOptions = useMemo(() => timezones
    .filter((v, i, a) => a.findIndex(I => (I.areaName === v.areaName)) === i)
    .map(I => ({
      value: I.areaName,
      label: I.label,
    })), [timezones]);
  // functions
  const onSubmit = (values, isNewVersion = false) => {
    (editableRecord ? isNewVersion ? postAgreementVersion : putAgreementVersion : postAgreement)({
      body: {
        ...values,
      },
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
      errorCallback: ({ error, key } = {}) => {
        toastRef?.current?.showMessage({ message: t(key || error), intent: 'danger' });
        // onCloseModal({ isSave: true });
      },
    });
  };
  const onDeleteAgreementVersionClick = () => {
    openConfirm('delete-agreement-version');
  };
  const onDeleteAgreementClick = () => {
    openConfirm('delete-agreement');
  };
  const onDeleteAgreementConfirm = () => {
    closeConfirm();
    deleteAgreement({
      id: editableRecord.id,
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
    });
  };
  const onDeleteAgreementVersionConfirm = () => {
    if (selectedVersionId) {
      closeConfirm();
      deleteAgreementVersion({
        id: selectedVersionId,
        successCallback: () => {
          sendQuery();
          onCloseModal({ isSave: true });
        },
      });
    }
  };
  // effects
  useEffect(() => {
    // todo времено, лучше сделать отдельный запрос на получение всех записей
    getLegalEntitiesDictionary();
    getOwnLegalEntitiesDictionary();
    getCompaniesDictionary();
    getBankAccountsDictionary();
    getOwnBankAccountsDictionary();
  }, []);

  return (
    <div className="agreements-layout">
      <Table
        limit={limit}
        total={agreementsMeta.size}
        page={offset}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        filteredColumns={filteredColumns}
        tableHeader="TABS.AGREEMENTS"
        openForm={() => openModal('add')}
        openCustomColumns={openCustomViewDrawer}
        buttonText="CONTROLS.AGREEMENTS.ADD"
        columnDefs={AGREEMENTS}
        rowData={agreements}
        onRowClicked={onRowClicked}
        sendQuery={sendQuery}
        isPending={isPendingGetAgreements || !lastUpdatedGetAgreements}
        tableRef={tableRef}
      />
      {
        isOpenCustomViewDrawer && (
          <CustomColumnsView
            initialValues={filteredColumns}
            columnsNames={AGREEMENTS}
            onSubmit={onSaveCustomView}
            onClose={closeCustomViewDrawer}
            isOpen={isOpenCustomViewDrawer}
          />
        )
      }
      {
        isOpenModal && (
          <Modal
            mode={isOpenModal}
            closeModal={onCloseModal}
            title="SCREENS.AGREEMENTS.AGREEMENT"
            editableRecord={{
              id: editableRecord?.id,
              createdAt: editableRecord?.agreementCreatedAt,
              updatedAt: editableRecord?.agreementUpdatedAt,
            }}
            contextMenuItems={editableRecord && (
              <MenuItem
                type="dense"
                view="raised"
                color="danger"
                icon="trash"
                text={t('CONTROLS.AGREEMENTS.DELETE_THIS_AGREEMENT')}
                shouldDismissPopover={false}
                onClick={onDeleteAgreementClick}
              />
            )}
          >
            <AgreementsForm
              onSubmit={onSubmit}
              onCancel={onCloseModal}
              timezonesOptions={timezonesOptions}
              currenciesOptions={currencies}
              initialValues={editableRecord}
              mode={isOpenModal}
              changeMode={openModal}
              onDeleteAgreementVersion={onDeleteAgreementVersionClick}
              isFormPristine={isFormPristine}
              setSelectedVersionId={setSelectedVersionId}
              isPending={
                isPendingDeleteAgreement
                || isPutAgreementVersion
                || isPendingPostAgreementVersion
                || isPendingDeleteAgreementVersion
              }
              formValuesRef={formValuesRef}
              showBtnServicesEnabled
            />
          </Modal>
        )
      }
      {
        isOpenConfirm && (
          <ConfirmModal
            isDelete={
              isOpenConfirm === 'delete-agreement-version'
              || isOpenConfirm === 'delete-agreement'
            }
            closeModal={closeConfirm}
            onConfirm={
              isOpenConfirm === 'delete-agreement-version'
                ? onDeleteAgreementVersionConfirm
                : isOpenConfirm === 'delete-agreement'
                  ? onDeleteAgreementConfirm
                  : onConfirmClose
            }
          />
        )
      }
    </div>
  );
};
