import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useLegalEntitiesApi, useDictionariesApi } from 'hooks/api';
import { useScreen } from 'hooks';
import Table from 'components/Table';
import ConfirmModal from 'components/modals/ConfirmModal';
import { CustomColumnsView } from 'components/blocks';
import { LEGAL_ENTITIES } from 'consts/columns';
import Modal from 'components/Modal';
import { LegalEntityForm } from 'components/forms';

import './style.scss';

const LegalEntitiesScreen = ({ screenRef }) => {
  const history = useHistory();

  const isOwnLegalEntities = history.location.pathname === '/own-legal-entities';

  const {
    getLegalEntities,
    getOwnLegalEntities,
    postLegalEntity,
    putLegalEntity,
    deleteLegalEntity,
    legalEntities,
    legalEntitiesMeta,
    ownLegalEntities,
    ownLegalEntitiesMeta,
    isPendingGetLegalEntities,
    isPendingGetOwnLegalEntities,
    isPendingPostLegalEntity,
    isPendingPutLegalEntity,
    isPendingDeleteLegalEntity,
    lastUpdatedGetLegalEntities,
    lastUpdatedGetOwnLegalEntities,
  } = useLegalEntitiesApi();
  const {
    companiesOptions,
    getCompaniesDictionary,
    getPortalsDictionary,
    getCountriesDictionary,
  } = useDictionariesApi();
  const {
    openModal,
    editableRecord,
    sendQuery,
    onCloseModal,
    limit,
    offset,
    setOffset,
    setLimit,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    onDeleteClick,
    isFormPristine,
    formValuesRef,
    isOpenConfirm,
    closeConfirm,
    onDeleteConfirm,
    onConfirmClose,
    isOpenModal,
    onRowClicked,
    onSortChanged,
    onFilterChanged,
  } = useScreen({
    screenRef,
    getFunc: isOwnLegalEntities ? getOwnLegalEntities : getLegalEntities,
    deleteFunc: deleteLegalEntity,
    onRowClickCallback: data => ({
      ...data,
      enabled: !data.enabled,
    }),
  });

  const columnDefs = useMemo(() => (isOwnLegalEntities
    ? LEGAL_ENTITIES.filter(col => col.field !== 'companyId')
    : LEGAL_ENTITIES), [isOwnLegalEntities]);

  const onSubmit = (body) => {
    (editableRecord ? putLegalEntity : postLegalEntity)({
      body,
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
    });
  };

  useEffect(() => {
    getCompaniesDictionary();
    getPortalsDictionary();
    getCountriesDictionary();
  }, []);

  return (
    <div className="legal-entities-layout">
      <Table
        tableHeader={`TABS.${isOwnLegalEntities ? 'OWN_' : ''}LEGAL_ENTITIES`}
        columnDefs={columnDefs}
        rowData={isOwnLegalEntities ? ownLegalEntities : legalEntities}
        onRowClicked={onRowClicked}
        showFilters={false}
        openCustomColumns={openCustomViewDrawer}
        openForm={() => openModal('add')}
        buttonText="CONTROLS.LEGAL_ENTITIES.ADD"
        limit={limit}
        total={(isOwnLegalEntities ? ownLegalEntitiesMeta : legalEntitiesMeta).size}
        page={offset}
        filteredColumns={filteredColumns}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        sendQuery={sendQuery}
        isPending={isOwnLegalEntities
          ? (isPendingGetOwnLegalEntities || !lastUpdatedGetOwnLegalEntities)
          : (isPendingGetLegalEntities || !lastUpdatedGetLegalEntities)}
        tableRef={tableRef}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
      />
      {
        isOpenCustomViewDrawer && (
          <CustomColumnsView
            initialValues={filteredColumns}
            columnsNames={columnDefs}
            onSubmit={onSaveCustomView}
            onClose={closeCustomViewDrawer}
            isOpen={isOpenCustomViewDrawer}
          />
        )
      }
      {
        isOpenModal && (
          <Modal
            mode={isOpenModal}
            closeModal={onCloseModal}
            title="SCREENS.LEGAL_ENTITIES.LEGAL_ENTITY"
            editableRecord={editableRecord}
          >
            <LegalEntityForm
              onSubmit={onSubmit}
              onCancel={onCloseModal}
              initialValues={editableRecord}
              mode={isOpenModal}
              changeMode={openModal}
              onDelete={onDeleteClick}
              isFormPristine={isFormPristine}
              partnersOptions={companiesOptions}
              isPending={
                isPendingDeleteLegalEntity || isPendingPostLegalEntity || isPendingPutLegalEntity
              }
              formValuesRef={formValuesRef}
              isOwnLegalEntities={isOwnLegalEntities}
            />
          </Modal>
        )
      }
      {
        isOpenConfirm && (
          <ConfirmModal
            isDelete={isOpenConfirm === 'delete'}
            closeModal={closeConfirm}
            onConfirm={
              isOpenConfirm === 'delete' ? onDeleteConfirm : onConfirmClose
            }
          />
        )
      }
    </div>
  );
};

export default LegalEntitiesScreen;
