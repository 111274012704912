import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  memo,
} from 'react';
import { Formik, Form } from 'formik';
import { Icon } from '@blueprintjs/core';
import cx from 'classnames';
import { useToggle, useKey, useLocalStorage } from 'react-use';
import { sortBy } from 'lodash';

import { TREE_ROW_SCHEMA } from 'consts/validationSchemas';
import { useModalLogic } from 'hooks';
import { useDictionariesApi, useServicesApi } from 'hooks/api';
import OnChangeComponent from 'components/forms/OnChangeComponent';
import OnFormChange from 'components/forms/OnFormChange';
import { ConfirmModal } from 'components/modals';
import Spinner from '@setproduct-ui/core/Spinner';
import Color from '@setproduct-ui/styles/color.module.css';

import ResourceCell from './ResourceCell';
import PriceTypeCell from './PriceTypeCell';
import PriceCell from './PriceCell';
import RuleCell from './RuleCell';
import MultiAddDrawer from './MultiAddDrawer';
import BreakdownDrawer from './BreakdownDrawer';
import styles from './Row.module.scss';

import { ReactComponent as UndoIcon } from '../icons/arrow-rotate-left.svg';
import { ReactComponent as FloppyDiskIcon } from '../icons/floppy-disk.svg';
import { ReactComponent as EditIcon } from '../icons/pen-to-square.svg';
import { ReactComponent as DeleteIcon } from '../icons/trash-xmark.svg';
import { ReactComponent as TriangleIcon } from '../icons/triangle-exclamaition.svg';

const Row = memo(({
  data: {
    attributesBlacklist,
    parentInfoRules,
    definitionsFilters,
    siblingValues,
    parentServiceDomainId,
    displayLinksFor,
    searchValue,
    searchIsActive,
    collapsed,
    levelOfSearch,
    ...data
  },
  onDeleteRow,
  onSubmit,
  isPending,
  addChild,
  activeRow,
  activeRowRef,
  onChangeActiveRow,
  isPendingEdit,
  isPendingDelete,
  selectedRow,
  setSelectedRow,
  serviceType,
  serviceCurrency,
  getNodesListAndMakeTree,
  readOnly,
  onChevronClick,
  onSearchChange,
  flatTreeData,
  rowIndex,
  listRef,
}) => {
  // console.log(`NODE: ${data.debugName}`);
  // console.log('DATA ', data);
  // console.log('ATTRIUBTES BLACK LIST ', attributesBlacklist);
  // console.log('PARENT RESOURCE ID ', parentResourceId);
  // console.count(`RERENDER ${data.debugName}`);
  const rowRef = useRef();
  const resourceCellRef = useRef();
  const priceCellRef = useRef();
  const billingCellRef = useRef();
  const quantityCellRef = useRef();
  const [attributesOptions, setAttributesOptions] = useState([]);
  const [resourceCellOptions, setResourceCellOptions] = useState([]);
  const [isModalOpen, { openModal, closeModal }] = useModalLogic(false);
  const [isDrawerOpen, toggleIsDrawerOpen] = useToggle(false);
  const [isBreakdownDrawerOpen, toggleIsBreakdownDrawerOpen] = useToggle(false);
  const [initialValues, setInitialValues] = useState({});
  const [savedInitialValues, setSavedInitialValues, removeSavedInitialValues] = useLocalStorage('savedInitialValues');

  const formRef = useRef();
  const {
    resourcesOptions,
    definitionsList,
    getFilteredItems,
    serviceDomainsOptions,
    isPendingGetFilteredItems,
  } = useDictionariesApi();
  const {
    resourceAttributes,
    resourceAttributesDictionary,
    billingRules,
    quantityRules,
    billingRulesDictionary,
    quantityRulesDictionary,
  } = useServicesApi();

  const thisRowIsActive = activeRow === data.id;

  const CancelIcon = useMemo(() => {
    if (data.id === 'newRow' || (data.id !== 'newRow' && thisRowIsActive)) {
      return UndoIcon;
    }
    return DeleteIcon;
  }, [data.id, thisRowIsActive]);
  const SaveIcon = useMemo(() => (thisRowIsActive ? FloppyDiskIcon : EditIcon), [thisRowIsActive]);
  const rowErrors = useMemo(() => {
    if (activeRow !== data.id) {
      let errorObj = null;

      if ((data.priceType === null || data.priceType === undefined)
        && (data.childrenMatchAttributeId === null || data.childrenMatchAttributeId === undefined)) {
        errorObj = {
          color: 'danger',
          text: 'Price type not set',
        };
      }
      if (data.childrenMatchAttributeId && !data.childrenSize) {
        if (errorObj) {
          errorObj.text += '\nNo child records. Please add at least one.';
        } else {
          errorObj = {
            color: 'warning',
            text: 'No child records. Please add at least one.',
          };
        }
      }

      return errorObj;
    }
    return null;
  }, [activeRow, data.id, data.priceType, data.childrenMatchAttributeId]);

  const validationSchema = useMemo(() => TREE_ROW_SCHEMA({
    isFreeInput: data.dictionaryId === null,
    serviceType,
  }), [data.dictionaryId, serviceType]);

  const onChangeFormValues = (values, dirty) => {
    if (thisRowIsActive && dirty) {
      setSavedInitialValues({
        ...values,
        rowIndex,
      });
    }
  };
  const onChevronClickModify = (e) => {
    e.stopPropagation();
    onChevronClick(data.id);
  };
  const onAddChild = () => {
    if (collapsed) {
      onChevronClickModify();
    }
    addChild(data);
  };
  const onCancelClick = (e) => {
    e.stopPropagation();
    if (isPending || readOnly) return;

    if (thisRowIsActive) {
      removeSavedInitialValues();
      if (data.id === 'newRow') {
        onDeleteRow(data.id);
      } else {
        setInitialValues({
          ...data,
          matchAttributeValues: Array.isArray(data.matchAttributeValues)
            ? data.matchAttributeValues?.[0] || 'null'
            : (data.dictionaryId || data.level < 3) ? null : '',
          priceTypeCell: data.childrenMatchAttributeId
            ? data.childrenMatchAttributeId
            : Number.isInteger(data.priceType) ? `${data.priceType}/price` : undefined,
        });
        formRef.current?.resetForm();
        onChangeActiveRow('');
      }
    } else if (!activeRow) {
      openModal(true);
    }
  };
  const onDeleteConfirm = () => {
    onDeleteRow(data.id, closeModal);
  };
  const onRowDoubleClick = () => {
    if (!isPending && !thisRowIsActive && !readOnly) {
      onChangeActiveRow(data.id, formRef, rowRef);

      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      } else if (document.selection) {
        document.selection.empty();
      }
    }
  };
  const onRowClick = () => {
    if (!isPending && !thisRowIsActive && !readOnly) {
      setSelectedRow(selectedRow.treeKey === data.treeKey ? {} : { treeKey: data.treeKey, level: data.level });
    }
  };
  const onSaveClick = (e) => {
    e?.stopPropagation();

    if (isPending || readOnly) return;

    if (thisRowIsActive) {
      if (!savedInitialValues) {
        return;
      }

      if (data.childrenSize) {
        const { values } = formRef?.current || {};

        if ((values?.childrenMatchAttributeId && (values?.childrenMatchAttributeId !== data.childrenMatchAttributeId))
        || (values?.priceType !== undefined && values?.priceType !== null)) {
          toggleIsBreakdownDrawerOpen();
          return;
        }
      }

      let firstRequiredField = '';

      formRef.current.handleSubmit();
      Object.entries(formRef.current.errors).forEach(([key, value]) => {
        if (!firstRequiredField) {
          firstRequiredField = key;
        }

        if (Array.isArray(value)) {
          value.forEach((item) => {
            Object.keys(item).forEach((itemKey) => {
              formRef.current.setFieldTouched(`${key}.${itemKey}`, true);
            });
          });
        } else {
          formRef.current.setFieldTouched(key, true);
        }
      });

      // switch (firstRequiredField) {
      //   case 'matchAttributeValues':
      //     resourceCellRef.current.scrollIntoView({ behavior: 'smooth', inline: 'center' });
      //     break;
      //   case 'price':
      //   case 'scaleType':
      //     priceCellRef.current.scrollIntoView({ behavior: 'smooth', inline: 'center' });
      //     break;
      //   case 'billingRule':
      //     console.log('BILLING CELL REF ', billingCellRef);
      //     billingCellRef.current.scrollIntoView({ behavior: 'smooth', inline: 'center' });
      //     break;
      //   case 'quantityRule':
      //     quantityCellRef.current.scrollIntoView({ behavior: 'smooth', inline: 'center' });
      //     break;
      //   default: break;
      // }
    } else if (!activeRow) {
      onChangeActiveRow(data.id, formRef, rowRef);
    }
  };
  const onSubmitModify = (values) => {
    onSubmit(values, () => {
      if (isBreakdownDrawerOpen) {
        toggleIsBreakdownDrawerOpen(false);
        getNodesListAndMakeTree();
      }
      removeSavedInitialValues();
    });
  };
  const onChangeMatchAttribute = ({ form, value }) => {
    if (data.level === 2) {
      setAttributesOptions(
        sortBy(
          resourceAttributes.filter(attribute => String(attribute.resourceId) === String(value)),
          ['label'],
        ),
      );

      if (form.dirty) {
        form.setFieldValue('priceTypeCell', undefined);
        form.setFieldValue('billingRule', undefined);
        form.setFieldValue('quantityRule', undefined);
        form.setFieldValue('scaleCounterGroupBy', undefined);
      }
    }
  };
  const onChangePriceTypeCell = ({ form, value }) => {
    if (form.dirty) {
      if (value?.includes?.('/price')) {
        form.setFieldValue('childrenMatchAttributeId', null);
        form.setFieldValue('priceType', +value.split('/')[0]);
        listRef.current.recomputeRowHeights();

        switch (value) {
          case '1/price':
            form.setFieldValue('price', undefined);
            break;
          case '2/price':
            form.setFieldValue(
              'price',
              [
                {
                  tier: '',
                  price: '',
                },
                {
                  tier: null,
                  price: '',
                },
              ],
            );
            break;
          default:
            form.setFieldValue('price', undefined);
            break;
        }
      } else {
        form.setFieldValue('priceType', undefined);
        form.setFieldValue('price', undefined);
        form.setFieldValue('childrenMatchAttributeId', value);
        if (data.level > 2) {
          form.setFieldValue('billingRule', undefined);
          form.setFieldValue('quantityRule', undefined);
        }
      }
    }
  };
  const onChangePriceCell = ({ form, value, values }) => {
    if (Array.isArray(value) && form.dirty && values.priceTypeCell === '2/price') {
      listRef.current.recomputeRowHeights();
    }
  };

  useEffect(() => {
    if (data.level === 1) {
      setAttributesOptions([
        {
          value: 4,
          label: 'Resource',
        },
      ]);
    } else if (data.level >= 3) {
      setAttributesOptions(
        sortBy(
          resourceAttributes.filter((attribute) => {
            const isMatchingResourceId = String(attribute.resourceId) === String(parentInfoRules?.resourceId);
            const isNotMatchingAttributeId = String(attribute.value) !== String(data.matchAttributeId);
            const isNotInBlacklist = !attributesBlacklist?.includes(String(attribute.value));
            const isCurrentValue = String(attribute.value) === String(data.childrenMatchAttributeId);

            return (isMatchingResourceId && isNotMatchingAttributeId && isNotInBlacklist) || isCurrentValue;
          }),
          ['label'],
        ),
      );
    }
  }, [resourceAttributes, attributesBlacklist?.length]);
  useEffect(() => {
    // если мы выбираем монетарные паки
    if (activeRow === data.id && data.matchAttributeId === 500101) {
      getFilteredItems({
        dictionaryId: data.dictionaryId,
        body: {
          ...definitionsFilters,
          filters: [...(definitionsFilters?.filters || [])],
          definitionFilters: [
            {
              field: 'currency',
              type: 'in',
              values: [serviceCurrency],
            },
          ],
        },
        successCallback: (res) => {
          setResourceCellOptions(definitionsList[data.dictionaryId]?.reduce((acc, item) => {
            if (res?.data?.some(key => key === item.value)) {
              acc.push(item);
            }
            return acc;
          }, []));
        },
      });
    } else {
      switch (data.level) {
        case 1:
          switch (serviceType) {
            case 1:
              setResourceCellOptions(serviceDomainsOptions);
              break;
            case 6:
              setResourceCellOptions(serviceDomainsOptions?.filter(I => I.value !== 50 && I.value !== 40));
              break;
            case 7:
              setResourceCellOptions(serviceDomainsOptions?.filter(I => I.value === 40));
              break;
            default:
              setResourceCellOptions(serviceDomainsOptions?.filter(I => I.value !== 40));
              break;
          }
          break;
        case 2:
          if (readOnly) {
            setResourceCellOptions(resourcesOptions);
          } else {
            setResourceCellOptions(resourcesOptions.filter(resource => String(resource.serviceDomainId) === String(parentServiceDomainId)));
          }
          break;
        case 3:
          setResourceCellOptions(definitionsList[data.dictionaryId]);
          break;
        default:
          if (data.dictionaryId) {
            if (activeRow === data.id) {
              getFilteredItems({
                dictionaryId: data.dictionaryId,
                body: definitionsFilters,
                successCallback: (res) => {
                  setResourceCellOptions(definitionsList[data.dictionaryId]?.reduce((acc, item) => {
                    if (res?.data?.some(key => key === item.value)) {
                      acc.push(item);
                    }
                    return acc;
                  }, []));
                },
              });
            } else {
              setResourceCellOptions(definitionsList[data.dictionaryId]);
            }
          }
          break;
      }
    }
  }, [activeRow, data.id]);
  useEffect(() => {
    if (savedInitialValues?.id === data.id) {
      activeRowRef.current = rowRef.current;
      setInitialValues(savedInitialValues);
    } else {
      setInitialValues({
        ...data,
        matchAttributeValues: Array.isArray(data.matchAttributeValues)
          ? data.matchAttributeValues?.[0] || 'null'
          : (data.dictionaryId || data.level < 3) ? null : '',
        priceTypeCell: data.childrenMatchAttributeId
          ? data.childrenMatchAttributeId
          : Number.isInteger(data.priceType) ? `${data.priceType}/price` : undefined,
      });
    }
  }, []);

  useKey('Enter', () => {
    if (thisRowIsActive) {
      onSaveClick();
    }
  }, {}, [thisRowIsActive]);
  // console.log('**************************************');

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitModify}
        innerRef={formRef}
        enableReinitialize
        validateOnMount
      >
        {({
          values,
          errors,
          touched,
          dirty,
        }) => (
          <Form
            className={cx(styles.row, {
              [styles.row_active]: thisRowIsActive,
              [styles.row_selected]: selectedRow.treeKey === data.treeKey,
              [styles.row_withActiveSearch]: !!searchValue,
              [styles.row_readOnly]: readOnly,
            })}
            onClick={onRowClick}
            onDoubleClick={onRowDoubleClick}
            ref={rowRef}
          >
            {/* Active indicator column cell */}
            <div />
            {/* Chevron column cell */}
            <div className={styles.chevronCell}>
              {!!data.childrenSize && (
                <Icon
                  icon="chevron-down"
                  size={16}
                  className={cx(styles.chevron, {
                    [styles.chevron_reversed]: collapsed,
                  })}
                  onClick={onChevronClickModify}
                />
              )}
            </div>
            {/* Errors column cell */}
            <div>
              {rowErrors && (
                <TriangleIcon
                  className={cx(Color[rowErrors.color], styles.rowError)}
                  title={rowErrors.text}
                />
              )}
            </div>
            {/* Resource column cell */}
            <ResourceCell
              level={data.level}
              id={data.id}
              treeKey={data.treeKey}
              options={resourceCellOptions}
              freeInput={data.dictionaryId === null}
              withControls={!!values.childrenMatchAttributeId && data.id !== 'newRow' && !readOnly}
              controlsDisabled={thisRowIsActive}
              withSearch={!!data.childrenSize}
              onAdd={onAddChild}
              onMultiAdd={toggleIsDrawerOpen}
              disabled={!thisRowIsActive || data.childrenSize}
              isEdit={thisRowIsActive}
              addChildrenDisabled={!!activeRow}
              cellRef={resourceCellRef}
              touched={touched}
              errors={errors}
              searchValue={searchValue}
              onSearchChange={onSearchChange}
              selectedRow={selectedRow}
              title={thisRowIsActive && data.childrenSize
                ? 'Can\'t change node resource that has children'
                : undefined}
              siblingValues={siblingValues}
              isLoading={isPendingGetFilteredItems && thisRowIsActive}
              displayLinksFor={displayLinksFor}
              searchIsActive={searchIsActive}
              levelOfSearch={levelOfSearch}
            />
            {/* Price type column cell */}
            <PriceTypeCell
              dependsOptions={attributesOptions}
              disabled={data.level === 1 || !thisRowIsActive}
              isEdit={thisRowIsActive}
              dependsOnDisabled={data.level === 2 && !values.matchAttributeValues}
              importFromFileDisabled={!!Object.keys(errors)?.filter(key => key !== 'priceTypeCell')?.length}
              title={thisRowIsActive && data.childrenSize
                ? 'Cannot change the attribute for a branching node that has children'
                : undefined}
              serviceType={serviceType}
              data={values}
              serviceCurrency={serviceCurrency}
              parentInfoRules={parentInfoRules}
              values={values}
              level={data.level}
            />
            {/* Price column cell */}
            <PriceCell
              priceTypeCellValue={values.priceTypeCell}
              disabled={!thisRowIsActive}
              isEdit={thisRowIsActive}
              errors={errors}
              touched={touched}
              cellRef={priceCellRef}
              counterGroupOptions={attributesOptions}
              counterGroupDisabled={data.level === 2 && !values.matchAttributeValues}
              serviceType={serviceType}
            />
            {/* Billing rule column cell */}
            <RuleCell
              name="billingRule"
              level={data.level}
              parentResourceId={parentInfoRules?.resourceId}
              values={values}
              rules={billingRules}
              disabled={!thisRowIsActive || (data.level === 2 && !values.matchAttributeValues)}
              isEdit={thisRowIsActive}
              valueByDefault={parentInfoRules?.billingRule}
              dictionary={billingRulesDictionary}
              cellRef={billingCellRef}
              priceType={values.priceType}
              serviceType={serviceType}
            />
            {/* Quantity rule column cell */}
            <RuleCell
              name="quantityRule"
              level={data.level}
              parentResourceId={parentInfoRules?.resourceId}
              values={values}
              rules={quantityRules}
              disabled={!thisRowIsActive || (data.level === 2 && !values.matchAttributeValues)}
              isEdit={thisRowIsActive}
              valueByDefault={parentInfoRules?.quantityRule}
              dictionary={quantityRulesDictionary}
              cellRef={quantityCellRef}
              priceType={values.priceType}
              serviceType={serviceType}
            />
            {/* Actions column cell */}
            {!readOnly && (
              <div
                className={cx(styles.buttonsWrapper, {
                  [styles.buttonsWrapper_alwaysVisible]: data.id === 'newRow',
                  [styles.buttonsWrapper_alwaysHidden]: readOnly,
                })}
              >
                {isPendingDelete && thisRowIsActive
                  ? <Spinner size={20} color="danger" />
                  : (
                    <CancelIcon
                      className={cx(
                        styles.iconButton,
                        {
                          [styles.iconButton_disabled]: isPending || (!!activeRow && !thisRowIsActive),
                          [Color.danger]: CancelIcon === DeleteIcon,
                        },
                      )}
                      onClick={onCancelClick}
                    />
                  )}
                {isPendingEdit && thisRowIsActive
                  ? <Spinner size={20} color="primary" />
                  : (
                    <SaveIcon
                      className={cx(
                        styles.iconButton,
                        Color.primary,
                        {
                          [styles.iconButton_disabled]: isPending || (!!activeRow && !thisRowIsActive)
                          || ((!dirty && !savedInitialValues) && thisRowIsActive),
                        },
                      )}
                      onClick={onSaveClick}
                      title={(!dirty && !savedInitialValues) && thisRowIsActive ? 'Nothing to save' : undefined}
                    />
                  )}
              </div>
            )}
            <OnChangeComponent field="matchAttributeValues" onChange={onChangeMatchAttribute} />
            <OnChangeComponent field="priceTypeCell" onChange={onChangePriceTypeCell} />
            <OnChangeComponent field="price" onChange={onChangePriceCell} />
            <OnFormChange callback={onChangeFormValues} />
          </Form>
        )}
      </Formik>
      {isModalOpen && (
        <ConfirmModal
          onConfirm={onDeleteConfirm}
          isPending={isPendingDelete}
          closeModal={closeModal}
          isDelete
        />
      )}
      <MultiAddDrawer
        isOpen={isDrawerOpen}
        toggleOpen={toggleIsDrawerOpen}
        resourceAttributesDictionary={resourceAttributesDictionary}
        data={data}
        resourceCellOptions={resourceCellOptions}
        serviceDomainsOptions={serviceDomainsOptions}
        resourcesOptions={resourcesOptions}
        definitionsList={definitionsList}
        definitionsFilters={definitionsFilters}
        getFilteredItems={getFilteredItems}
        getNodesListAndMakeTree={getNodesListAndMakeTree}
        flatTreeData={flatTreeData}
      />
      <BreakdownDrawer
        isOpen={isBreakdownDrawerOpen}
        toggleOpen={toggleIsBreakdownDrawerOpen}
        data={data}
        resourceCellOptions={resourceCellOptions}
        resourceAttributesDictionary={resourceAttributesDictionary}
        getFilteredItems={getFilteredItems}
        definitionsFilters={definitionsFilters}
        definitionsList={definitionsList}
        values={formRef.current?.values}
        getNodesListAndMakeTree={getNodesListAndMakeTree}
        submitForm={formRef.current?.handleSubmit}
      />
    </>
  );
});

export default Row;
