import React, { useMemo } from 'react';
import { Field } from 'formik';

import styles from './RuleCell.module.scss';

import Dropdown from '../Dropdown';

const RuleCell = ({
  name,
  rules,
  parentResourceId,
  values,
  disabled,
  isEdit,
  level,
  valueByDefault,
  dictionary,
  cellRef,
  priceType,
  serviceType,
}) => {
  const fieldStyle = {
    color: level === 2 ? 'var(--indigo70)' : undefined,
    width: 180,
  };
  const wrapperStyle = {
    cursor: isEdit ? undefined : 'default',
  };

  const options = useMemo(() => {
    const filteredRules = rules?.reduce((acc, item) => {
      if (String(item.resourceId) === String(level === 2 ? values.matchAttributeValues : parentResourceId)) {
        acc.push({
          value: item.id,
          label: item.name,
        });
      }
      return acc;
    }, []);

    if (level > 2) {
      return [
        {
          label: `Default (${dictionary?.[valueByDefault]?.name})`,
          value: null,
          style: {
            color: 'var(--grey50)',
          },
        },
        ...filteredRules,
      ];
    }
    return filteredRules;
  }, [rules, parentResourceId, level, values.matchAttributeValues]);

  if (serviceType === 3 || serviceType === 6) {
    return null;
  }
  if ((priceType === null || priceType === undefined) && level !== 2) {
    return <div className={styles.placeholder} />;
  }
  if (Number.isInteger(priceType) && priceType < 1) {
    return <div className={styles.placeholder}>—</div>;
  }

  return (
    <div ref={cellRef}>
      <Field
        name={name}
        component={Dropdown}
        textInputStyles={fieldStyle}
        options={options}
        placeholder={valueByDefault ? `Default (${dictionary?.[valueByDefault]?.name})` : 'PLACEHOLDERS.SELECT'}
        disabled={disabled}
        wrapperStyle={wrapperStyle}
      />
    </div>
  );
};

export default RuleCell;
