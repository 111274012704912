import moment from 'moment/moment';
import React, { useMemo, useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@blueprintjs/core';

import OnChangeComponent from 'components/forms/OnChangeComponent';
import { useDictionariesApi } from 'hooks/api';
import Callout from '@setproduct-ui/core/Callout';
import {
  TextField,
  DropdownField, DatePickerField,
} from 'components/fields';
import { ASSIGN_SCHEMA } from 'consts/validationSchemas';
import { FormButtonsGroup } from 'components/blocks';
import { useContentShadow } from 'hooks';
import IsFormChanged from 'components/forms/IsFormChanged';

import styles from './AssignForm.module.scss';

const AssignForm = ({
  initialValues = {},
  onSubmit,
  onCancel,
  onDelete,
  mode,
  isPending,
  isFormPristine,
  formValuesRef,
  companiesOptions,
}) => {
  const { t } = useTranslation();
  const fieldStyle = ({ width, marginTop, overlay } = {}) => ({
    container: {
      width,
    },
    label: {
      marginTop,
    },
    overlay,
  });

  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);
  const contentRef = useRef(null);
  const [filteredAgreementsOptions, setFilteredAgreementsOptions] = useState([]);
  const {
    showTopShadow,
    showBottomShadow,
  } = useContentShadow(contentRef);
  const {
    agreementsOptions,
  } = useDictionariesApi();
  const isAssign = useMemo(() => mode === 'assign', [mode]);
  const isReAssign = useMemo(() => mode === 'reassign', [mode]);

  const clearedInitialValues = useMemo(() => {
    if (isAssign) {
      return {
        didSenderId: initialValues.id,
        senderId: initialValues.senderId,
      };
    }
    if (isReAssign) {
      return {
        didSenderId: initialValues.id,
        senderId: initialValues.senderId,
        id: initialValues.actualVersionId,
      };
    }
    return {};
  }, [initialValues, isAssign, isReAssign]);

  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }
    return isEmpty(values);
  };
  const onChangeCompany = ({ form, value }) => {
    if (form.dirty) {
      form.setFieldValue('actualAgreementId', undefined);
    }
    setFilteredAgreementsOptions(agreementsOptions?.filter(I => I.companyId === value));
  };
  const onSubmitHandler = ({
    actualAgreementId,
    actualEffectiveFrom,
    actualEffectiveTill,
    actualCompanyId,
    id,
  }) => {
    if (isAssign) {
      onSubmit({
        agreementId: actualAgreementId,
        companyId: actualCompanyId,
        didSenderId: clearedInitialValues?.didSenderId,
        actualEffectiveFrom,
        actualEffectiveTill,
      });
    }
    if (isReAssign) {
      onSubmit({
        id,
        agreementId: actualAgreementId,
        companyId: actualCompanyId,
        didSenderId: clearedInitialValues?.didSenderId,
        actualEffectiveFrom,
        actualEffectiveTill,
      });
    }
  };
  const getTillMinDate = from =>
    moment(from).clone().add(1, 'day').toDate();

  return (
    <Formik
      enableReinitialize
      initialValues={clearedInitialValues}
      onSubmit={onSubmitHandler}
      validationSchema={ASSIGN_SCHEMA}
      validateOnMount
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
        values,
      }) => (
        <Form className={styles.form}>
          {showTopShadow && <div className={styles.shadow} />}
          <div className={styles.content} ref={contentRef}>
            {isReAssign && (
              <Callout
                type="def"
                view="smooth"
                color="warning"
                title="Warning"
                className={styles.callout}
                icon={<Icon icon="warning-sign" size={24} />}
              >
                {t('INSTANCES.CALLER_IDS.REASSIGN_MESSAGE')}
              </Callout>
            )}
            <Field
              name="senderId"
              label="INSTANCES.NUMBER"
              component={TextField}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              disabled
              isRequired
              full
            />
            <Field
              name="actualCompanyId"
              label="INSTANCES.COMPANY"
              component={DropdownField}
              options={companiesOptions}
              styles={fieldStyle({
                marginTop: 16,
              })}
              placeholder="PLACEHOLDERS.SELECT"
              isRequired
            />
            <Field
              name="actualAgreementId"
              label="INSTANCES.AGREEMENT"
              component={DropdownField}
              options={filteredAgreementsOptions}
              styles={fieldStyle({
                marginTop: 16,
              })}
              placeholder="PLACEHOLDERS.SELECT"
              isRequired
            />
            <Field
              name="actualEffectiveFrom"
              label="INSTANCES.CALLER_IDS.START_DATE"
              placeholder="PLACEHOLDERS.SELECT_DATE"
              component={DatePickerField}
              format="DD.MM.YYYY"
              styles={fieldStyle({
                marginTop: 16,
              })}
              minDate={initialValues.effectiveFrom}
              initialMonth={initialValues.effectiveFrom}
              maxDate={initialValues.effectiveTill}
              isRequired
            />
            <Field
              name="actualEffectiveTill"
              label="INSTANCES.CALLER_IDS.EXPIRATION_DATE"
              placeholder="PLACEHOLDERS.SELECT_DATE"
              component={DatePickerField}
              format="DD.MM.YYYY"
              styles={fieldStyle({
                marginTop: 16,
              })}
              minDate={getTillMinDate(values.actualEffectiveFrom)}
              initialMonth={getTillMinDate(values.actualEffectiveFrom)}
              maxDate={initialValues.effectiveTill}
              disabled={values.actualEffectiveFrom === undefined}
              withIndefinitely
              isRequired
            />
          </div>
          <FormButtonsGroup
            onDelete={onDelete}
            onCancel={onCancel}
            setFieldTouched={setFieldTouched}
            errors={errors}
            onApply={handleSubmit}
            mode={mode}
            isPending={isPending}
            showDelete={!isAssign && !isReAssign}
            showShadow={showBottomShadow}
          />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
          <OnChangeComponent field="actualCompanyId" onChange={onChangeCompany} />
        </Form>
      )}
    </Formik>
  );
};

export default AssignForm;
