import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import ScrollableTabs from 'components/ScrollableTabs';
import DropdownField from 'components/fields/DropdownField';
import Chips from '@setproduct-ui/core/Chips';
import { useDictionariesApi } from 'hooks/api';

import MenuDropdown from './MenuDropdown';
import OverviewTab from './OverviewTab';
import FinanceTab from './FinanceTab';
import AssetsTab from './AssetsTab';
import styles from './CompanyViewTab.module.scss';

const CompanyViewTab = ({
  id,
  createdAt,
  updatedAt,
  isReseller,
  isModerated,
  ownResellersAllowed,
  openCompanyOnEdit,
  updateCompanyData,
  isActive,
  isPending,
  ...restCompanyData
}) => {
  const TABS = [
    {
      id: 'overview',
      title: 'SCREENS.COMPANIES.OVERVIEW',
      withoutCross: true,
    },
    {
      id: 'finance',
      title: 'SCREENS.COMPANIES.FINANCE',
      withoutCross: true,
    },
    {
      id: 'assets',
      title: 'SCREENS.COMPANIES.ASSETS',
      withoutCross: true,
    },
  ];
  const PERIOD_OPTIONS = [
    {
      value: 1,
      label: 'Day',
    },
    {
      value: 30,
      label: 'Month',
    },
    {
      value: 360,
      label: 'Year',
    },
  ];
  const dropdownStyles = {
    container: { width: 270 },
  };

  const { t } = useTranslation();
  const {
    legalEntitiesOptions,
  } = useDictionariesApi();
  const [selectedLegalEntity, setSelectedLegalEntity] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(30);
  const [activeTab, setActiveTab] = useState(0);

  const filteredLEOptions = useMemo(() => {
    const options = legalEntitiesOptions.filter(I => I.companyId === id);

    setSelectedLegalEntity(options?.[0]?.value);

    return options;
  }, [id, legalEntitiesOptions]);

  const toggleCompanyStatus = () => {
    updateCompanyData({
      id,
      isReseller,
      isModerated,
      ownResellersAllowed,
      isActive: !isActive,
      ...restCompanyData,
    });
  };
  const renderPanel = (tab) => {
    if (tab.id === 'overview') {
      return (
        <OverviewTab
          companyId={id}
          selectedPeriod={selectedPeriod}
        />
      );
    }
    if (tab.id === 'finance') {
      return (
        <FinanceTab
          companyId={id}
          legalEntityId={selectedLegalEntity}
          isActiveTab={activeTab === 1}
        />
      );
    }
    if (tab.id === 'assets') {
      return (
        <AssetsTab
          companyId={id}
          isActiveTab={activeTab === 2}
        />
      );
    }

    return null;
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.chipsWrapper}>
          {isActive ? <Chips tag="INSTANCES.ENABLED" color="success" view="outlined" /> : <Chips tag="INSTANCES.BLOCKED" color="danger" view="outlined" />}
          {isReseller && <Chips tag="INSTANCES.COMPANIES.IS_RESELLER" view="outlined" />}
          {ownResellersAllowed && <Chips tag="INSTANCES.COMPANIES.RESELLERS_ALLOWED" view="outlined" />}
          {isModerated && <Chips tag="INSTANCES.COMPANIES.MODERATION" view="outlined" icon="warning-sign" />}
          <MenuDropdown
            options={[
              {
                label: 'CONTROLS.EDIT',
                icon: 'edit',
                iconColor: 'var(--blue40)',
                onClick: openCompanyOnEdit,
              },
              {
                label: `CONTROLS.COMPANIES.${isActive ? '' : 'UN'}BLOCK`,
                icon: isActive ? 'remove' : 'tick-circle',
                iconColor: `var(--${isActive ? 'red' : 'green'}40)`,
                onClick: toggleCompanyStatus,
              },
            ]}
            loading={isPending}
          />
        </div>
        {`${t('INSTANCES.ID')} - ${id} · ${t('INSTANCES.CREATED')} - ${moment(createdAt).format('MMM DD, YYYY HH:mm')} · ${t('INSTANCES.UPDATED')} - ${moment(updatedAt).format('MMM DD, YYYY HH:mm')}`}
      </div>
      <ScrollableTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabsList={TABS}
        styles={{ wrapper: { flex: 1 } }}
        renderPanel={renderPanel}
        elementInTabsPanel={(
          <>
            {activeTab === 0 && (
              <DropdownField
                value={selectedPeriod}
                onChange={setSelectedPeriod}
                options={PERIOD_OPTIONS}
                styles={dropdownStyles}
                dense
              />
            )}
            {activeTab === 1 && (
              <div className={styles.row}>
                Legal entity:
                <DropdownField
                  value={selectedLegalEntity}
                  onChange={setSelectedLegalEntity}
                  options={filteredLEOptions}
                  styles={dropdownStyles}
                  dense
                />
              </div>
            )}
          </>
        )}
        alwaysShowTabsPanelElement
      />
    </div>
  );
};

export default CompanyViewTab;
