import endpoints from 'consts/endpoints';

export default ({
  limit = 100,
  offset = 0,
  successCallback,
  saveToRedux = true,
  ...queries
} = {}) => ({
  url: endpoints.getInvoicesUrl(),
  queryKey: 'getInvoices',
  body: {
    limit,
    offset: offset ? ((offset - 1) * limit) : offset,
    sort: '-periodStart',
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
    successCallback,
  },
  transform: (response) => {
    const {
      data,
      meta,
    } = response;

    return {
      invoices: data,
      invoicesMeta: meta,
    };
  },
  update: {
    invoices: (oldValue, newValue) => (saveToRedux ? newValue : oldValue),
    invoicesMeta: (oldValue, newValue) => (saveToRedux ? newValue : oldValue),
  },
});
