import React, { useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import { isEqual, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  DropdownField, TextAreaField,
} from 'components/fields';
import { FormButtonsGroup } from 'components/blocks';
import IsFormChanged from 'components/forms/IsFormChanged';
import { NOTIFICATION_CHANNELS_IDS } from 'consts/channels';

const NotificationsForm = ({
  changeMode,
  mode,
  onSubmit,
  initialValues = {},
  onCancel,
  isPending,
  isFormPristine,
  formValuesRef,
  // sectionsOptions,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);

  const fieldStyle = ({
    width,
    marginTop,
    marginRight,
    bottom,
    top,
  } = {}) => ({
    container: {
      width,
      marginRight,
    },
    label: {
      marginTop,
    },
    overlay: {
      bottom,
      top,
    },
  });

  const isView = useMemo(() => mode === 'view', [mode]);

  const channelsOptions = useMemo(
    () =>
      NOTIFICATION_CHANNELS_IDS.filter(I => I.value === 6),
    [NOTIFICATION_CHANNELS_IDS],
  );

  const formattedValues = useMemo(() => {
    const parser = new DOMParser();
    const template = parser
      .parseFromString(initialValues.template, 'text/html')
      ?.body
      ?.innerText
      ?.trim();
    const receivers = initialValues?.receivers
      ? initialValues.receivers.join(', ')
      : '';
    return {
      ...initialValues,
      defaultReceivers: t('PLACEHOLDERS.ACCOUNTS_EMAILS'),
      template,
      receivers,
    };
  }, [initialValues]);

  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activeTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }

    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default: return true;
    }
  };

  const changeToEdit = () => changeMode('edit');

  const validateEmails = (value) => {
    let error;
    const emailList = value.split(',');
    if (value) {
      emailList.forEach((I) => {
        const regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!regex.test(I.trim())) {
          error = t('ERRORS.INVALID_EMAIL_CONTAIN');
        }
      });
    }
    return error;
  };
  const onSubmitModify = (values) => {
    onSubmit({
      ...values,
      template: initialValues.template,
    });
  };

  return (
    <Formik
      initialValues={formattedValues}
      onSubmit={onSubmitModify}
      validateOnMount
      validateOnChange
      enableReinitialize
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
      }) => (
        <Form className="service-enabled-form">
          <div className="service-enabled-form__content">
            <Field
              name="channelType"
              component={DropdownField}
              label="INSTANCES.NOTIFICATIONS_CHANNELS_SETTINGS.TYPE"
              options={channelsOptions}
              placeholder="PLACEHOLDERS.SELECT"
              disabled
            />
            <Field
              name="template"
              label="INSTANCES.NOTIFICATIONS_SETTINGS.TEXT"
              component={TextAreaField}
              styles={fieldStyle({ marginTop: 24 })}
              minRows={1}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              disabled
            />
            <Field
              name="event"
              label="INSTANCES.NOTIFICATIONS_SETTINGS.EVENT"
              component={TextAreaField}
              styles={fieldStyle({ marginTop: 24 })}
              placeholder="PLACEHOLDERS.ENTER_VALUE"
              disabled
            />
            <Field
              name="defaultReceivers"
              label="INSTANCES.NOTIFICATIONS_SETTINGS.DEFAULT_EMAILS"
              component={TextAreaField}
              styles={fieldStyle({ marginTop: 24 })}
              minRows={1}
              placeholder="PLACEHOLDERS.ACCOUNTS_EMAILS"
              disabled
            />
            <Field
              name="receivers"
              label="INSTANCES.NOTIFICATIONS_SETTINGS.ADDITIONAL_EMAILS"
              component={TextAreaField}
              styles={fieldStyle({ marginTop: 24 })}
              minRows={1}
              placeholder="PLACEHOLDERS.ENTER_EMAILS_SEPARATED"
              disabled={isView}
              validate={validateEmails}
            />
            {/* <Field */}
            {/*  name="chapterUrl" */}
            {/*  label="INSTANCES.NOTIFICATIONS_SETTINGS.SECTION" */}
            {/*  component={DropdownField} */}
            {/*  options={sectionsOptions} */}
            {/*  styles={fieldStyle({ */}
            {/*    marginTop: 24, */}
            {/*    bottom: '100%', */}
            {/*    top: 'unset', */}
            {/*  })} */}
            {/*  placeholder="PLACEHOLDERS.ENTER_VALUE" */}
            {/*  disabled={isView} */}
            {/* /> */}
          </div>
          <FormButtonsGroup
            onCancel={onCancel}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            errors={errors}
            setFieldTouched={setFieldTouched}
            isPending={isPending}
            showDelete={false}
          />
          <IsFormChanged isFormPristine={isFormPristine} checkFunction={checkFunction} />
        </Form>
      )}
    </Formik>
  );
};

export default NotificationsForm;
